import { ConvertTimeToLocal } from "../../../../utilities/CommonFunctions";
import LineChart from "./LineChart";
import { TrendsDetailsProps } from "./TrendsDetails";
import { SleTimeSeriesData } from "../sle-time-series/SleSlice";

interface CardsDesignProps {
    trend: TrendsDetailsProps | SleTimeSeriesData;
    // trend: TrendsDetailsProps;

    index: number;
    selectedDate: {
        startDate: Date;
        endDate: Date;
    };
    loadingIcon: boolean;
    trendsMinMaxValue: {
        [key: string]: {
            minValue: number;
            mean: number;
            maxValue: number;
            lastData: number;
            lastDataDate: string;
        };
    };
    setTrendsMinMaxValue: React.Dispatch<React.SetStateAction<{
        [key: string]: {
            minValue: number;
            mean: number;
            maxValue: number;
            lastData: number;
            lastDataDate: string;
        };
    }>>;
    currentAggregateValue: string
    filteredValues: string[]

}

const CardsDesign: React.FC<CardsDesignProps> = ({ trend, index, selectedDate, loadingIcon, trendsMinMaxValue, setTrendsMinMaxValue, currentAggregateValue, filteredValues }) => {

    const trendMinMax = trendsMinMaxValue?.[trend?.trendName];

    const minValue = trendMinMax ? trendMinMax?.minValue : trend?.min
    const maxValue = trendMinMax ? trendMinMax?.maxValue : trend?.max
    const meanValue = trendMinMax ? trendMinMax?.mean : trend?.medean
    const lastData = trendMinMax ? trendMinMax?.lastData : trend?.value
    const lastDataDate = trendMinMax ? trendMinMax?.lastDataDate : trend?.date

    const unitOfMeasure = 'short_UnitOfMeasure' in trend
        ? trend.short_UnitOfMeasure || (trend.unitOfMeasure === 'None' ? '' : trend.unitOfMeasure)
        : 'shortUnitOfMeasure' in trend ? trend.shortUnitOfMeasure || (trend.unitOfMeasure === 'None' ? '' : trend.unitOfMeasure) : '';
    return (
        <>
            <div className='card'>
                <div className='card-header'>
                    <div className='title'>{trend?.trendName}</div>
                </div>

                <div className='card-body'>
                    <div className='left-container'>
                        <h1 className='psi'>
                            {+Number(lastData)?.toFixed(3) || 0} {unitOfMeasure ?? ''}
                        </h1>
                        <div className='cont'>{lastDataDate ? ConvertTimeToLocal(lastDataDate) : '00/00/0000 00:00:00'}</div>
                        <div>
                            &nbsp;Min: {`${+Number(minValue)?.toFixed(3) || 0} ${unitOfMeasure ?? ''}`}&nbsp; |&nbsp; Mean:{' '}
                            {`${+Number(meanValue)?.toFixed(3) || 0} ${unitOfMeasure ?? ''}`} &nbsp;|&nbsp; Max: {`${+Number(maxValue).toFixed(3) || 0} ${unitOfMeasure ?? ''}`}
                            &nbsp;
                        </div>

                        <div className='thresholdcontainer margin-top'>
                            <span className='threshold'>↓ {(typeof trend?.maxThreshold === 'number') ? (trend?.maxThreshold ? trend?.maxThreshold : '-') : (trend?.maxThreshold === "∞" || trend?.maxThreshold?.toLowerCase() === "infinity" || trend?.maxThreshold?.toLowerCase() === "infinite" ? '-' : `${+Number(trend?.maxThreshold)?.toFixed(1)}%`)} </span>Max threshold
                        </div>
                        <div className='thresholdcontainer'>
                            <span className='threshold'>↑ {(typeof trend?.maxThreshold === 'number') ? (trend?.minThreshold ? trend?.minThreshold : '-') : (trend?.minThreshold === "∞" || trend?.minThreshold?.toLowerCase() === "infinite" || trend?.maxThreshold?.toLowerCase() === "infinity" ? '-' : `${+Number(trend?.minThreshold)?.toFixed(1)}%`)} </span>Min threshold
                        </div>
                    </div>
                    <div className='rigth-container trends-right'>
                        <LineChart
                            chartName={trend?.trendName}
                            unitOfMeasure={unitOfMeasure}
                            chartData={trend?.dataPoints}
                            index={index}
                            selectedDate={selectedDate}
                            loadingIcon={loadingIcon}
                            setTrendsMinMaxValue={setTrendsMinMaxValue}
                            key={trend?.trendName}
                            currentAggregateValue={currentAggregateValue}
                            filteredValues={filteredValues}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardsDesign