import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { clearServerCacheAction, downloadAssetsStatusCSV, getLookups, scheduleServiceStatusAction, updateLookupsById } from './supportService';
import { ConfigurationItem, Schedule } from './supportInterface';

interface UserState {
    lookups?: ConfigurationItem[];
    scheduleServiceStatus?: Schedule[];
    lookupsLoading?: boolean;
    downloadAssetCsvLoading?: boolean
    clearServerCacheLoading?: boolean;
    scheduleServiceStatusLoading?: boolean;
    lookupsError?: string;
    loading?: boolean;
    error?: string;


}

const initialState: UserState = {
    lookups: [],
    scheduleServiceStatus: [],
    lookupsLoading: false,
    downloadAssetCsvLoading: false,
    clearServerCacheLoading: false,
    scheduleServiceStatusLoading: false,
    lookupsError: '',
    loading: false,
    error: '',

};

export const fetchDownloadAssetsStatusCSV = createAsyncThunk(
    'support/fetchDownloadAssetsStatusCSV',
    async (_, { rejectWithValue }) => {
        try {
            const response = await downloadAssetsStatusCSV();
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message || 'Failed to download CSV');
        }
    }
);


export const fetchLookups = createAsyncThunk(
    'support/fetchLookups',
    async () => {
        const response = await getLookups();
        return response;
    },
);

export const updateLookups = createAsyncThunk(
    'support/updateLookups',
    async (payload: { id: string, payload: any }) => {
        const response = await updateLookupsById(payload.id, payload.payload,
        );
        return response;
    },
);

export const clearServerCache = createAsyncThunk(
    'support/clearServerCache',
    async () => {
        const response = await clearServerCacheAction();
        return response;
    },
);

export const scheduleSeviceStatus = createAsyncThunk(
    'support/scheduleServiceStatus',
    async () => {
        const response = await scheduleServiceStatusAction();
        return response;
    },
);

const supportSlice = createSlice({
    name: 'support',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDownloadAssetsStatusCSV.pending, (state) => {
            state.loading = true;
            state.downloadAssetCsvLoading = true;
            state.error = '';
        });
        builder.addCase(fetchDownloadAssetsStatusCSV.fulfilled, (state) => {
            state.loading = false;
            state.downloadAssetCsvLoading = false;
        });
        builder.addCase(fetchDownloadAssetsStatusCSV.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || '';
            state.downloadAssetCsvLoading = false;
        });
        builder.addCase(fetchLookups.pending, (state) => {
            state.lookupsLoading = true;
            state.lookupsError = '';
        });
        builder.addCase(fetchLookups.fulfilled, (state, action: any) => {
            state.lookupsLoading = false;
            state.lookups = action.payload.data;
        });
        builder.addCase(fetchLookups.rejected, (state, action) => {
            state.lookupsLoading = false;
            state.lookupsError = action.error.message || '';
        });
        builder.addCase(updateLookups.pending, (state) => {
            state.loading = true;
            state.error = '';
        });
        builder.addCase(updateLookups.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(updateLookups.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || '';
        });
        builder.addCase(clearServerCache.pending, (state) => {
            state.loading = true;
            state.clearServerCacheLoading = true;
            state.error = '';
        });
        builder.addCase(clearServerCache.fulfilled, (state) => {
            state.loading = false;
            state.clearServerCacheLoading = false;
        });
        builder.addCase(clearServerCache.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || '';
            state.clearServerCacheLoading = false;
        });
        builder.addCase(scheduleSeviceStatus.pending, (state) => {
            state.loading = true;
            state.scheduleServiceStatusLoading = true;
            state.error = '';
        });
        builder.addCase(scheduleSeviceStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.scheduleServiceStatusLoading = false;
            state.scheduleServiceStatus = action.payload.data;
        });
        builder.addCase(scheduleSeviceStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || '';
            state.scheduleServiceStatusLoading = false;
        });
    },
});

export default supportSlice.reducer;


