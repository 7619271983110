import React, { memo, useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import annotation_plus from './../../../../images/expand-icon.svg';
import download from '../../../../../src/images/download-icon.svg';
import NoData from '../../../dashboard/components/no-data/NoData';
import Loader from '../../../common/page-loader/ComponentLoader';
import SpeedChart from './charts/SpeedChart';
import SpeedModal from './modals/SpeedModal';
import { SpeedDetailsProps } from './interfaces/speed.interface';
import { HZbandsData, RPMbandsData } from './constants/speed.contants';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { getChannelDataByKey, getTimeSeriesDataByKey } from '../../wellControlroomSlice';
import { TimeseriesData } from '../../interfaces/timeSeries.interface';
import { AssetTimeseriesData } from '../../interfaces/timeSeriesChannelIdsData.interface';
import championx from '../../../../../src/images/championxLogo.png';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import moment from 'moment-timezone';

const SpeedDetails: React.FC<SpeedDetailsProps> = ({ heading }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const speedHzChartdiv1 = useRef<HTMLDivElement | null>(null);
  const speedRpmChartdiv2 = useRef<HTMLDivElement | null>(null);
  const speedHzChartdiv3 = useRef<HTMLDivElement | null>(null);
  const speedRpmChartdiv4 = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const root = useRef<any>(null);
  const root2 = useRef<any>(null);
  const root3 = useRef<any>(null);
  const root4 = useRef<any>(null);

  const [data, setData] = useState<{
    max: TimeseriesData | AssetTimeseriesData;
    min: TimeseriesData | AssetTimeseriesData;
    pID: TimeseriesData | AssetTimeseriesData;
    speed: TimeseriesData | AssetTimeseriesData;
  }>({
    max: {} as TimeseriesData | AssetTimeseriesData,
    min: {} as TimeseriesData | AssetTimeseriesData,
    pID: {} as TimeseriesData | AssetTimeseriesData,
    speed: {} as TimeseriesData | AssetTimeseriesData,
  });

  const hElement = document.getElementById('main-root');
  const channelIDsObj = useAppSelector((state) => state.wellControlroomCharts.channelIDsObj);
  const loading = useAppSelector((state) => state.wellControlroomCharts.loading);
  const currentUser = useAppSelector((state) => state?.user?.currentUser);

  const userPrefrenceData = useAppSelector((state) => state.controlroom.userPrefrence);
  const speedObject = userPrefrenceData.find(item => item?.title.toLowerCase() === "Speed".toLowerCase());
  const assetinfo = useAppSelector((state) => state.assetInfo.assetDetails);

  const speedChannelData = {
    max: useAppSelector(getChannelDataByKey(channelIDsObj.max || '')),
    min: useAppSelector(getChannelDataByKey(channelIDsObj.min || '')),
    pID: useAppSelector(getChannelDataByKey(channelIDsObj.pID || '')),
    speed: useAppSelector(getChannelDataByKey(channelIDsObj.speed || '')),
  }

  const speedTimeSeriesData = {
    max: useAppSelector(getTimeSeriesDataByKey(speedObject?.mappings?.sle?.max || '')),
    min: useAppSelector(getTimeSeriesDataByKey(speedObject?.mappings?.sle?.min || '')),
    pID: useAppSelector(getTimeSeriesDataByKey(speedObject?.mappings?.sle?.pID || '')),
    speed: useAppSelector(getTimeSeriesDataByKey(speedObject?.mappings?.sle?.speed || '')),
  }

  useEffect(() => {
    setData(assetinfo?.isSleAsset ? speedTimeSeriesData : speedChannelData);
  }, [JSON.stringify(speedChannelData), JSON.stringify(speedTimeSeriesData), JSON.stringify(assetinfo), JSON.stringify(userPrefrenceData)])

  const openModal = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setModalIsOpen(true);
    hElement?.classList.add('modal-open');
  };

  const handleClose = () => {
    setModalIsOpen(false);
    hElement && hElement?.classList.remove('modal-open');
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const printWidgetAsPDF = () => {
    if (root.current) {
      const exporting = am5plugins_exporting.Exporting.new(root.current, {
        menu: am5plugins_exporting.ExportingMenu.new(root.current, {}),
      });
      const exporting2 = am5plugins_exporting.Exporting.new(root2.current, {
        menu: am5plugins_exporting.ExportingMenu.new(root2.current, {}),
      });
      Promise.all([exporting.getPdfmake(),
      exporting.export('png'),
      exporting2.export('png')]).then((res) => {
        const pdfMake = res[0];
        const str = moment().format('MMM-DD-YYYY hh:mm:ss A');
        const doc = {
          pageSize: 'A4',
          pageOrientation: 'portrait',
          pageMargins: [30, 30, 30, 30],
          footer: {
            columns: [
              {
                text: `Generated By: ${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`,
                alignment: 'left',
                width: '50%',
                bold: true,
                margin: [10, 10],
                fontSize: 9,
                color: 'black',
                decoration: 'underline',
              },
              {
                text: 'Generated on : ' + str,
                alignment: 'right',
                width: '50%',
                bold: true,
                margin: [10, 10],
                fontSize: 9,
                color: 'black',
                decoration: 'underline',
              },
            ],
          },
          content: [] as any[],
          styles: {
            tblheader: {
              fontSize: 9.5,
              color: 'white',
              fillColor: '#01485e',
              alignment: 'center',
            },
          },
        };

        const rect = {
          type: 'rect',
          x: -32,
          y: -20, //could be negative to account for padding
          w: 450,
          h: 25,
          linearGradient: ['#01485e', '#01485e'],
        };
        const rectHead = {
          type: 'rect',
          x: -32,
          y: -20, //could be negative to account for padding
          w: 150,
          h: 25,
          linearGradient: ['#484848', '#484848'],
        };
        doc.content.push({
          columns: [
            {
              stack: [
                { canvas: [rect] },
                {
                  columns: [
                    {
                      width: rect.w / 2,
                      noWrap: false, //clip at with by wrapping to next line and...
                      maxHeight: rect.h, //don't show the wrapped text
                      image: championx,
                      fit: [55, 55],
                      margin: [5.5, 7.5, 3],
                      background: 'white',
                    },
                    {
                      text: 'Landing Page',
                      width: rect.w / 2,
                      alignment: 'left',
                      bold: true,
                      margin: [35, 5],
                      fontSize: 13,
                      color: 'white',
                    },
                  ],
                  relativePosition: {
                    x: rect.x,
                    y: -rect.h,
                  },
                },
              ],
            },
            {
              stack: [
                { canvas: [rectHead] },
                {
                  columns: [
                    {
                      width: rectHead.w,
                      noWrap: false, //clip at with by wrapping to next line and...
                      maxHeight: rect.h, //don't show the wrapped text
                      text: 'LOOKOUT',
                      color: 'white',
                      bold: true,
                      alignment: 'right',
                      'font-size': 10,
                    },
                  ],
                  relativePosition: {
                    x: -80,
                    y: -20,
                  },
                },
              ],
            },
          ],
        });
        doc.content.push({
          text: ' ',
          width: '100%',
        });

        doc.content.push(
          {
            text: 'Speed',
            width: '*',
            alignment: 'center',
            fontSize: 18,
            margin: [0, 0, 0, 5],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 175,
                y1: 0,
                x2: 362,
                y2: 0,
                lineWidth: 1,
              },
            ],
            margin: [0, 0, 0, 15],
          },
        );

        doc.content.push({
          image: res[1],
          alignment: 'center',
          width: 250,
          height: 180,
          margin: [0, 0, 0, 20],
        });
        doc.content.push({
          image: res[2],
          alignment: 'center',
          width: 250,
          height: 180,
          margin: [0, 0, 0, 20],
        });

        const fileName = 'Lookout-speed_' + str + '.pdf';

        pdfMake.createPdf(doc).download(fileName);
      }).finally(() => {
        setIsDropdownOpen(false);

      });
    }
  };


  return (
    <>
      <ToastContainer position='top-right' autoClose={3000} />
      <div className='grid-item-text h-full graph-card well-graph-card'>
        <div className='table-header-section pie-section'>
          <div className='title'>{heading}</div>
          {speedObject?.expandable && <div className='header-icon'>
            <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
              <img src={annotation_plus} alt='Expand' className='img-border' />
            </button>
          </div>}
          {speedObject?.actions.show && <div className='mr-4 dropdown-container' ref={dropdownRef}>
            <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <span className='dot'></span>
              <span className='dot'></span>
              <span className='dot'></span>
            </button>
            {isDropdownOpen && (
              <div className='dropdown-options'>
                <button>Actions</button>
                <button className={`btn btn-default cancelSelectorName`} onClick={printWidgetAsPDF}>
                  <img src={download} alt='icon' className='btn-icon' />
                  Download PDF
                </button>
                <button className={`btn btn-default cancelSelectorName`}>
                  <img src={download} alt='icon' className='btn-icon' />
                  Download CSV
                </button>
              </div>
            )}
          </div>}
        </div>
        <hr />
        <div id="speedChart-container" className='flex flex-wrap items-center justify-center wellChartSection speedChart-container-dashboard nodata'>

          {loading ? (
            <div className='flex items-center justify-center w-100'>
              <Loader />
            </div>
          ) :
            data?.min || data?.max || data?.pID || data?.speed
              ? (
                <>
                  {/* <div id={`speedchartdiv`} ref={speedHzchartdiv} className='chart-wrapper'></div> */}
                  <SpeedChart chartID={'speedHzChartdiv1'}
                    chartRef={speedHzChartdiv1} classValue={'w-[50%]'}
                    data={data} chartName={'HZ'}
                    minValue={50}
                    maxValue={350}
                    startAngle={-220} endAngle={40}
                    root={root}
                    innerCircleEndvalue={350} bandsData={HZbandsData} spacingValue={4} />
                  <SpeedChart chartID={'speedRpmChartdiv2'}
                    chartRef={speedRpmChartdiv2} classValue={'w-[50%]'}
                    data={data} chartName={'RPM'}
                    minValue={50} maxValue={5040} startAngle={-230} endAngle={40}
                    root={root2}
                    innerCircleEndvalue={5040} bandsData={RPMbandsData} spacingValue={50} />
                </>
              ) : (
                <NoData heading='No data found' />
              )}

        </div>
      </div>
      <SpeedModal
        isOpen={modalIsOpen}
        onRequestClose={handleClose}
        heading={'Speed'}
        loading={loading}
        modelWidth={'70rem'}
        modelClass={'wellChartModal'}
        innerComponent={
          <>
            {loading ? (
              <Loader />
            ) :
              data?.min || data?.max || data?.pID || data?.speed
                ? (
                  <>
                    {/* <div id={`speedchartdiv`} ref={speedHzchartdiv} className='chart-wrapper'></div> */}
                    <SpeedChart chartID={'speedHzChartdiv3'}
                      chartRef={speedHzChartdiv3} classValue={'w-[50%]'}
                      data={data} chartName={'HZ'}
                      minValue={50}
                      maxValue={350}
                      startAngle={-220} endAngle={40}
                      root={root3}
                      innerCircleEndvalue={350} bandsData={HZbandsData} spacingValue={4} />
                    <SpeedChart chartID={'speedRpmChartdiv4'}
                      chartRef={speedRpmChartdiv4} classValue={'w-[50%]'}
                      data={data} chartName={'RPM'}
                      minValue={50} maxValue={5040} startAngle={-230} endAngle={40}
                      root={root4}
                      innerCircleEndvalue={5040} bandsData={RPMbandsData} spacingValue={50} />
                  </>
                ) : (
                  <NoData heading='No data found' />
                )}
          </>
        }
      />
    </>
  );
};

export default memo(SpeedDetails);  