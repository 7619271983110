import React from 'react';
import ErrorIcon from '../../../images/error-icon.png'

interface LookupsInfoModalProps {
    isModalOpen: boolean;
    onCloseModal: () => void;
    onClickOk: () => void;
    recordName: string
}

const LookupsInfoModal: React.FC<LookupsInfoModalProps> = ({ isModalOpen, onCloseModal, onClickOk, recordName }) => {
    return (
        <>
            {isModalOpen && (
                <div className='modal-overlay delete-modal-page document-delete'>
                    <div className='modal'>
                        <div className='flex flex-row flex-grow profile-block'>
                            <div className='notify-card'>
                                <div className='header-delete'>
                                    <div className='feature-img'>
                                        <img src={ErrorIcon} alt='' />
                                    </div>
                                    <p className='title'>
                                        Update Lookup
                                    </p>
                                    <p className='normal-text'>
                                        {`Are you sure you want to update ${recordName} ?`}
                                    </p>
                                </div>
                                <div className='delete-modal__footer'>
                                    <button onClick={onCloseModal} className='btn btn-dark-orange' >
                                        Cancel
                                    </button>
                                    <button onClick={onClickOk} className='btn btn-primary'>
                                        OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default LookupsInfoModal;
