import React, { useEffect, useRef, useState } from 'react';
import './schduledSpeedChangeFrequency.scss'
import arrowsquare from '../../../../images/arrow-square-right.svg';
import { Options } from '../../data/Options';
import SimpleSpeedChangeFrequency from './components/schduled-speed-change-frequency/SimpleSpeedChangeFrequency';
import SteppedSpeedChangeFrequency from './components/stepped-speed-change-frequency/SteppedSpeedChangeFrequency';
import { fetchFreqSetPointAddress } from './ScheduleSpeedChangeSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { resetReadCurrentFrequencyErrorStatus } from '../setpoints/SetPointsSlice';

interface ScheduledSpeedChangeFrequencyProps {
  selectedOptions: Options;
  onClose: () => void;
}

const ScheduledSpeedChangeFrequency: React.FC<ScheduledSpeedChangeFrequencyProps> = ({ selectedOptions, onClose }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const selectedAssetRef = useRef<string | undefined>()
  const tabs = [
    { label: 'Simple (once)', component: SimpleSpeedChangeFrequency },
    { label: 'Stepped (multiple)', component: SteppedSpeedChangeFrequency },
  ];
  const dispatch = useAppDispatch()
  const setPointData = useAppSelector((state) => state.setPoint)

  useEffect(() => {


    if (selectedAssetRef.current !== selectedOptions.wellName) {
      selectedAssetRef.current = selectedOptions.wellName
      dispatch(fetchFreqSetPointAddress(selectedOptions?.wellName))
    }
  }, [selectedOptions.wellName]);

  useEffect(() => {
    if (setPointData.readCurrentFreqErrorStatus || setPointData.readCurrentFreqReturnErrorStatus) {
      dispatch(resetReadCurrentFrequencyErrorStatus())
    }
  }, [setPointData.readCurrentFreqErrorStatus, setPointData.readCurrentFreqReturnErrorStatus])
  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
  };

  const renderTabComponent = (Component: React.ElementType, props: any) => {
    return <Component {...props} />;
  };


  return (
    <>
      <div className='scheduleSpeed modal right-modal Schduled-speed'>
        <div className='border-bottom text-left pd-20'>
          <button onClick={onClose}>
            <img src={arrowsquare} alt='close' />
          </button>
        </div>
        <div className='heading border-bottom pd-20'>Scheduled speed change frequency</div>

        <div>
          <div className='ui-button-toolbar'>
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`ui-button-toolbar-button basis-1/2 ${index === activeTabIndex ? 'ui-button-toolbar-button-active semi-bold' : 'semi-bold'
                  }`}
                onClick={() => handleTabClick(index)}
              >
                {tab.label}
              </div>
            ))}
          </div>
          <div className='tab-content'>{renderTabComponent(tabs[activeTabIndex].component, { selectedOptions })}</div>
        </div>

      </div>
    </>
  );
};

export default ScheduledSpeedChangeFrequency;
