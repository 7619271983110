import React, { useEffect } from 'react';
import Close from '../../../../../images/x-close.svg';
import { WellByStatus } from '../../../model/WellByStatus';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import CustomModal from '../ExpandModel';
import { useAppSelector } from '../../../../../hooks/storeHooks';
import { WellByStatusDetails } from '../../../model/WellByStatusDetails';
import NoData from '../../no-data/NoData';
import Loader from '../../../../common/page-loader/ComponentLoader';
import { ReactComponent as SortIconDefault } from '../../../../../images/Chevron-default-icon.svg';
import { ReactComponent as SortIconUp } from '../../../../../images/Chevron-up-icon.svg';
import { ReactComponent as SortIconDown } from '../../../../../images/Chevron-down-icon.svg';

interface WellByStatusModelProps {
  isOpen: boolean;
  onRequestClose: () => void;
  heading: string;
  subHeading: string;
  count: number | undefined;
  wellByStatus?: WellByStatus[];
}

type SortableKeys = keyof WellByStatusDetails;

const WellByStatusModel: React.FC<WellByStatusModelProps> = ({
  isOpen,
  onRequestClose,
  heading,
  subHeading,
  count,
  wellByStatus,
}) => {
  const loading = useAppSelector((state) => state.dashboard.wellByStatusLoading);
  const wellByStatusDetails = useAppSelector((state) => state.dashboard.wellByStatusDetails);


  const [sortConfig, setSortConfig] = React.useState<{ key: SortableKeys; direction: 'asc' | 'desc' } | null>({
    key: 'operator',
    direction: 'asc',
  });

  const initializeChart = (chartId: string, data: WellByStatus[]): am5.Root => {
    const root = am5.Root.new(chartId);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.horizontalLayout,
        innerRadius: am5.percent(60),
      }),
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'value',
        categoryField: 'status',
        legendLabelText: '[#F7F9F9]{category}[/]',
        legendValueText: '[#F7F9F9]{value}[/]',
      }),
    );
    series.slices.template.setAll({
      stroke: am5.color('#FFFFFF'),
      strokeWidth: 1,
    });
    series.data.setAll(data);

    const dataItemsArray = Array.from(series.dataItems.values());
    dataItemsArray.forEach((dataItem) => {
      const status = dataItem.get('category') as string;
      let sliceColor = '';
      if (status === 'Running') {
        sliceColor = '#003747';
      } else if (status === 'Not Running') {
        sliceColor = '#60BFDA';
      } else if (status === 'Shutdown') {
        sliceColor = '#A6DBEA';
      } else if (status === 'Offline') {
        sliceColor = '#DCF1F7';
      } else if (status === 'Comm Issues') {
        sliceColor = '#00789E';
      } else {
        sliceColor = '#4A5463';
      }
      dataItem.set('fill', am5.color(sliceColor));
    });

    series.labels.template.set('forceHidden', true);
    series.ticks.template.set('forceHidden', true);

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerY: am5.percent(50),
        y: am5.percent(50),
        layout: root.verticalLayout,
      }),
    );

    legend.valueLabels.template.setAll({ textAlign: 'right' });
    legend.labels.template.setAll({
      maxWidth: 140,
      width: 80,
      oversizedBehavior: 'wrap',
    });

    // For making the legend circular
    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
      width: 10,
      height: 10,
    });

    legend.markerRectangles.template.setAll({
      y: 4,
    });

    legend.data.setAll(series.dataItems);
    return root;
  };

  useEffect(() => {
    let root: am5.Root;
    if (isOpen && wellByStatus && count && !loading) {
      setTimeout(() => {
        root = initializeChart('wellbystatuschartModaldiv', wellByStatus);
      }, 0);
    }
    return () => {
      if (root) {
        root.dispose();
      }
    };
  }, [isOpen, wellByStatus, count, heading, loading]);


  const requestSort = (key: SortableKeys) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig?.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };


  const sortedWellByStatusDetails = React.useMemo(() => {
    const sortableItems = wellByStatusDetails ? [...wellByStatusDetails] : [];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
        if (typeof aValue === 'undefined' || typeof bValue === 'undefined') {
          return 0;
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [wellByStatusDetails, sortConfig]);

  return (
    <CustomModal width="900px" isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className='modal-header'>
        <div className='flex'>
          <div className='grow'>
            <h3>{heading}</h3>
          </div>
          <div className='flex-none'>
            <button onClick={onRequestClose}>
              <img src={Close} alt='Expand' />
            </button>
          </div>
        </div>
      </div>
      <div className='modal-content'>
        {loading ? (
          <div className='flex items-center justify-center loader-margin'>
            <Loader />
          </div>
        ) : count ? (
          <div className='chart-wrap'>
            <div className='pie-container-dashboard'>
              <div className='total-count'>
                <p>{subHeading}</p>
                <h2>{count}</h2>
              </div>
              <div id='wellbystatuschartModaldiv' className='chart-wrapper'></div>
            </div>
            <div className='table-section'>
              <div className='widget-modal'>
                <table className='well-by-status-table'>
                  <thead>
                    <tr className='table-header-flex'>
                      <th onClick={() => requestSort('operator')} className='well-by-status'>
                        Well by status{' '}
                        {sortConfig?.key === 'operator' ? (
                          sortConfig.direction === 'asc' ? (
                            <SortIconUp alt='sort-asc' className='sort-img' />
                          ) : (
                            <SortIconDown alt='sort-dsc' className='sort-img' />
                          )) : (
                          <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                        )}
                      </th>
                      <th onClick={() => requestSort('running')} className='running'>
                        Running {sortConfig?.key === 'running' ? (
                          sortConfig.direction === 'asc' ? (
                            <SortIconUp alt='sort-asc' className='sort-img' />
                          ) : (
                            <SortIconDown alt='sort-dsc' className='sort-img' />
                          )) : (
                          <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                        )}
                      </th>
                      <th onClick={() => requestSort('disabled')} className='disabled cl'>
                        Disabled {sortConfig?.key === 'disabled' ? (
                          sortConfig.direction === 'asc' ? (
                            <SortIconUp alt='sort-asc' className='sort-img' />
                          ) : (
                            <SortIconDown alt='sort-dsc' className='sort-img' />
                          )) : (
                          <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                        )}
                      </th>
                      <th onClick={() => requestSort('shutdown')} className='shutdown'>
                        Shutdown {sortConfig?.key === 'shutdown' ? (
                          sortConfig.direction === 'asc' ? (
                            <SortIconUp alt='sort-asc' className='sort-img' />
                          ) : (
                            <SortIconDown alt='sort-dsc' className='sort-img' />
                          )) : (
                          <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                        )}
                      </th>
                      <th onClick={() => requestSort('commIssues')} className='comm-issues'>
                        Comm Issues {sortConfig?.key === 'commIssues' ? (
                          sortConfig.direction === 'asc' ? (
                            <SortIconUp alt='sort-asc' className='sort-img' />
                          ) : (
                            <SortIconDown alt='sort-dsc' className='sort-img' />
                          )) : (
                          <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                        )}
                      </th>
                      <th onClick={() => requestSort('offline')} className='offline'>
                        Offline {sortConfig?.key === 'offline' ? (
                          sortConfig.direction === 'asc' ? (
                            <SortIconUp alt='sort-asc' className='sort-img' />
                          ) : (
                            <SortIconDown alt='sort-dsc' className='sort-img' />
                          )) : (
                          <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedWellByStatusDetails?.map((wellByStatusDetail, index) => (
                      <tr key={index}>
                        <td className='well-by-status'>{wellByStatusDetail.operator}</td>
                        <td className='running'>{wellByStatusDetail.running}</td>
                        <td className='disabled'>{wellByStatusDetail.disabled}</td>
                        <td className='shutdown'>{wellByStatusDetail.shutdown}</td>
                        <td className='comm-issues'>{wellByStatusDetail.commIssues}</td>
                        <td className='offline'>{wellByStatusDetail.offline}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <NoData heading='No data found' />
        )}
      </div>
    </CustomModal>
  );
};

export default WellByStatusModel;
