import React, { useState, useEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import calendarIcon from '../../../../../images/calendar-icon.svg';
import thinArrow from '../../../../../images/thinArrow.svg';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { formateDate } from '../../../../common/DateRangeSelector/DefaultRanges';
import './WellPerformanceTrends.scss';
import NoData from '../../no-data/NoData';
import Loader from '../../../../common/page-loader/ComponentLoader';
import WellPerormanceTrendDateRangeSelector from './well-performance-trend-date-range/WellPerormanceTrendDateRangeSelector';

interface WellPerformanceTrendsChartProps {
  setIsDropdownOpen: any;
  dropdownRef: any;
  chartName: any;
  index: any;
  selectedItem: any;
  setSelecteditem: any;
  selectedDateRange: any;
  setSelectedDateRange: any;
  showZindex: any;
  data: any;
  loading: any;
  timeSeriesData: any;
}

const WellPerformanceTrendsChart: React.FC<WellPerformanceTrendsChartProps> = ({
  setIsDropdownOpen,
  dropdownRef,
  chartName,
  index,
  selectedItem,
  setSelecteditem,
  selectedDateRange,
  setSelectedDateRange,
  showZindex,
  data,
  loading,
  timeSeriesData
}) => {
  const chartId = `chart-${chartName}-${index}-${Math.random()}`;
  const [showCalendar, setShowCalendar] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const selectOptions = ['Motor current', 'Frequency', 'Motor temperature', 'Intake temperature', 'Intake pressure'];
  const toggleDropdwon = () => {
    setIsSelectOpen(!isSelectOpen);
  };
  const seriesLowerRef = useRef<am5xy.LineSeries | null>(null);
  const seriesHighRef = useRef<am5xy.LineSeries | null>(null);
  const handleItemClick = (item: string) => {
    setSelecteditem(item);
    setIsSelectOpen(!isSelectOpen);
  };

  function setToStartOfDay(date: any) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  }
  function setToEndOfDay(date: any) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
  }
  // Ensure selectedDateRange.startDate is at the start of the day
  const startDate = setToStartOfDay(selectedDateRange.startDate);
  // Ensure selectedDateRange.endDate is at the end of the day
  const endDate = setToEndOfDay(selectedDateRange.endDate);

  const updateTooltip = () => {
    if (seriesLowerRef.current && seriesHighRef.current) {
      seriesLowerRef.current.data.setAll(timeSeriesData); // Update series data
      seriesHighRef.current.data.setAll(timeSeriesData); // Update series data
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if(timeSeriesData.length > 0){
        updateTooltip();
      }
    }, 100);
  }, [timeSeriesData])

  useEffect(() => {
    if (data?.length > 0 && !loading) {
      const root = am5.Root.new(chartId);
      root.setThemes([am5themes_Animated.new(root)]);

      // Create a main container to hold both the chart and the legend
      const mainContainer = root.container.children.push(
        am5.Container.new(root, {
          layout: root.verticalLayout,
          width: am5.percent(100),
          height: am5.percent(100),
        })
      );

      // Create the chart container
      const chartContainer = mainContainer.children.push(
        am5.Container.new(root, {
          width: am5.percent(100),
          height: am5.percent(100), // Adjust height to leave space for the legend
        })
      );

      const chart = chartContainer.children.push(
        am5xy.XYChart.new(root, {
          focusable: true,
          panX: false,
          panY: false,
          wheelX: "none",
          wheelY: "none",
          pinchZoomX: false,
          pinchZoomY: false,
          // wheelY: 'zoomX',
          layout: root.verticalLayout,
          maxTooltipDistance: 0,
        }),
      );

      const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: { timeUnit: 'day', count: 1 },
          groupData: false,
          extraMax: 0,
          extraMin: 0,
          min: startDate.getTime(),
          max: endDate.getTime(),
          strictMinMax: true,
          renderer: am5xy.AxisRendererX.new(root, {
            minGridDistance: 100,
          }),
        }),
      );

      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          // max: adjustedMaxValue,
          renderer: am5xy.AxisRendererY.new(root, {}),
        }),
      );

      xAxis.get('renderer').labels.template.set('paddingLeft', 20);
      xAxis.get('renderer').labels.template.set('paddingTop', 20);

      const series = chart.series.push(
        am5xy.CandlestickSeries.new(root, {
          name: 'Series',
          xAxis: xAxis,
          yAxis: yAxis,
          openValueYField: 'open',
          highValueYField: 'high',
          lowValueYField: 'low',
          valueYField: 'close',
          valueXField: 'date',
        }),
      );

      series.columns.template.setAll({
        width: am5.percent(10),
      });

      series.columns.template.states.create('riseFromOpen', {
        fill: am5.color(0x60bfda),
        stroke: am5.color(0x60bfda),
      });
      series.columns.template.states.create('dropFromOpen', {
        fill: am5.color(0x60bfda),
        stroke: am5.color(0x60bfda),
      });

      series.columns.template.setAll({
        tooltipText: "Date: {valueX}\nQuartile1: {openValueY}\nLower Fence: {lowValueY}\nUpper Fence: {highValueY}\nQuartile3: {valueY},\nMedian: {median}"
      });

      series.columns.template.adapters.add('tooltipText', function (text: any, target: any) {
        const dataItem = target.dataItem;
        if (dataItem) {
          const date = new Date(dataItem.get('valueX'));
          const formattedDate = date.toLocaleDateString(undefined, {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          });
          return text.replace('{valueX}', formattedDate);
        }
        return text;
      });


      // mediana series
      const medianaSeries = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          stroke: am5.color("#001023"),
          xAxis: xAxis,
          yAxis: yAxis,

          valueYField: "median",
          valueXField: "date",
          noRisers: true,
          stepWidth: am5.percent(10)
        })
      );
      medianaSeries.strokes.template.setAll({
        strokeWidth: 3
      });

      const topSeries = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          stroke: am5.color(0x60bfda),
          xAxis: xAxis,
          yAxis: yAxis,
          //  startLocation : 0.2,
          // endLocation :0.8,
          valueYField: "high",
          valueXField: "date",
          noRisers: true,
          stepWidth: am5.percent(5)
        })
      );
      topSeries.strokes.template.setAll({
        strokeWidth: 2,

      });
      const bottomSeries = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          stroke: am5.color(0x60bfda),
          xAxis: xAxis,
          yAxis: yAxis,
          //  startLocation : 0.2,
          //   endLocation :0.8,
          valueYField: "low",
          valueXField: "date",
          noRisers: true,
          stepWidth: am5.percent(5)
        })
      );
      bottomSeries.strokes.template.setAll({
        strokeWidth: 2,

      });


      const outlierSeries = chart.series.push(am5xy.LineSeries.new(root, {
        name: "Outlier High",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "outlierHigh",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          // labelText: "Outlier High {valueY}"
          labelText: "Outlier High : {highOutlierAssets.length}"
        }),
        fill: am5.color(0x001023),
        stroke: am5.color(0x4a5463),
      }));

      outlierSeries.strokes.template.set("forceHidden", true)

      // Actual bullet
      outlierSeries.bullets.push(function () {
        const bulletCircle = am5.Circle.new(root, {
          radius: 3,
          fill: am5.color('#F4FBFC'),
        });
        return am5.Bullet.new(root, {
          sprite: bulletCircle
        })
      })

      //outliers 2 
      const outlierSeries2 = chart.series.push(am5xy.LineSeries.new(root, {
        name: "Outlier Low",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "outlierLow",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          // labelText: "Outlier Low {valueY}"
          labelText: "Outlier Low: {lowOutlierAssets.length}"
        }),
        fill: am5.color(0x001023),
        stroke: am5.color(0x4a5463),
      }));

      outlierSeries2.strokes.template.set("forceHidden", true)

      // Actual bullet
      outlierSeries2.bullets.push(function () {
        const bulletCircle = am5.Circle.new(root, {
          radius: 3,
          fill: am5.color('#F4FBFC'),
        });
        return am5.Bullet.new(root, {
          sprite: bulletCircle
        })
      })

      series.data.setAll(data);
      medianaSeries.data.setAll(data);
      topSeries.data.setAll(data);
      bottomSeries.data.setAll(data);
      outlierSeries.data.setAll(data);
      outlierSeries2.data.setAll(data);
      seriesLowerRef.current = outlierSeries2; // Store series in ref
      seriesHighRef.current = outlierSeries; // Store series in ref

      chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          behavior: 'none',
          xAxis: xAxis,
        }),
      );

      const tooltipX: any = am5.Tooltip.new(root, {
        themeTags: ['axis'],
        layer: 0, // Set the layer for the entire tooltip, including the label
      });

      // tooltipX.get('background').set('layer', 0);

      const tooltipY: any = am5.Tooltip.new(root, {
        themeTags: ['axis'],
        layer: 0, // Set the layer for the entire tooltip, including the label
      });

      // tooltipY.get('background').set('layer', 0);

      xAxis.set('tooltip', tooltipX);
      yAxis.set('tooltip', tooltipY);

      xAxis.get('renderer').labels.template.setAll({
        fill: am5.color('#fff'),  // Set label color
        paddingRight: 40          // Add 10px padding to the right (adjust as needed)
      });
      yAxis.get('renderer').labels.template.set('fill', am5.color('#fff'));

      series.appear(1000);
      chart.appear(1000, 100);

      return () => {
        root.dispose();
      };
    }
  }, [data, loading]);

  useEffect(() => {
    if (showCalendar) {
      showZindex();
    }
  }, [showCalendar]);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSlideOutClick = (e: any) => {
    if (e.target.id === 'range-calendar-input' || e?.target?.className?.includes(" well-performance-trends-options-id")) return;
    if (showCalendar) {
      setShowCalendar(!showCalendar);
    }
    if (isSelectOpen) {
      setIsSelectOpen(false);
    }
  };
  const ref = useDetectClickOutside({ onTriggered: handleSlideOutClick });

  return (
    <>
      <div>
        <div className='well-performance-trends__header-container well-performance-trends-options-id'>
          <div className='well-performance-trends__dropdown well-performance-trends-options-id' id='trendsModalFilterRef' >
            <div className='well-performance-trends__dropdown-input-container well-performance-trends-options-id' id='trendsModalFilterRef' onClick={toggleDropdwon}>
              <input type='text' className='well-performance-trends__dropdown-input well-performance-trends-options-id' value={selectedItem} readOnly />
              <img
                src={thinArrow}
                alt={isSelectOpen ? 'Up Arrow' : 'Down Arrow'}
                className={`mr-3 ${isSelectOpen ? 'rotate-180' : ''}`}
              />
            </div>
            {isSelectOpen && (
              <ul className='well-performance-trends__dropdown-list'>
                {selectOptions.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleItemClick(item)}
                    className='well-performance-trends__dropdown-item'
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className='well-performance-trends__input-group'>
            <img src={calendarIcon} alt='Range Calendar' />
            <input
              id='range-calendar-input'
              className='input-date'
              value={`${formateDate(selectedDateRange.startDate)} - ${formateDate(selectedDateRange.endDate)}`}
              onClick={() => setShowCalendar(!showCalendar)}
            />
          </div>
        </div>
        <div ref={ref}>
          {showCalendar && (
            <WellPerormanceTrendDateRangeSelector setShowCalendar={setShowCalendar} setSelectedDateRange={setSelectedDateRange} selectedDateRange={selectedDateRange} />
          )}
        </div>
        <div id="wellPerformanceTrend-container" className='wellPerformanceChartSection'>
          {loading ? (
            // <div className='well-performance-modal-loader'>
            <Loader />
            // </div>
          ) : chartId && data?.length > 0 ? (
            <div id={chartId} style={{ height: '100%', width: '100%' }}></div>
          ) : (
            <NoData heading='No data found' />
          )}
        </div>
      </div>
    </>
  );
};

export default WellPerformanceTrendsChart;
