import { useEffect } from "react";
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
// import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useAppSelector } from "../../../../../hooks/storeHooks";
import { graph1Data } from "./graphs";
import Loader from "../../../../common/page-loader/ComponentLoader";

const CapacityCurveGraph1 = () => {
    const mainReportData = useAppSelector((state) => state.analysisTab.mainReportData);
    const capacityCurveData = graph1Data(mainReportData?.graph1Data || []);
    const loading = useAppSelector((state) => state.analysisTab.mainReportDataLoading);

    useEffect(() => {
        let root: any;
        if (!loading) {
            root = am5.Root.new('CapacityGraph1Chartdiv');
            initializeChart(root)
        }

        return () => {
            root?.dispose();
        };
    }, [capacityCurveData]);

    const initializeChart = (root: am5.Root) => {
        // root.setThemes([am5themes_Animated.new(root)]);

            const chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    wheelX: 'none',
                    wheelY: 'none',
                    pinchZoomX: false,
                    pinchZoomY: false,
                    paddingTop: 100,
                }),
            );
    
            const xRenderer = am5xy.AxisRendererX.new(root, {
                minorGridEnabled: true,
                minGridDistance: 100,
            });
            xRenderer.labels.template.set('fill', am5.color('#fff'));
            const xAxis = chart.xAxes.push(
                am5xy.ValueAxis.new(root, {
                    autoZoom: false,
                    strictMinMax: true,
                    // min: 500,
                    // max: 5000,
                    renderer: xRenderer,
                    tooltip: am5.Tooltip.new(root, {}),
                }),
            );
            const xAxisLabel = am5.Label.new(root, {
                text: mainReportData?.graph1DataCapacityAxisTitle || "Capacity (bpd)",
                fill: am5.color("#fff"),
                // rotation: -90,
                centerX: am5.p100,
                centerY: am5.p100,
                x: 500,
                y: 70,
                // fontSize: 10,
            });
    
            xAxis.children.push(xAxisLabel);

            xAxis.get('renderer').labels.template.set('paddingLeft', 20);
            xAxis.get('renderer').labels.template.set('paddingTop', 20);
    
            const yRenderer = am5xy.AxisRendererY.new(root, {
                minorGridEnabled: true,
                minGridDistance: 50,
            });
            yRenderer.labels.template.set('fill', am5.color('#fff'));
            const yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    // min: 1500,
                    renderer: yRenderer,
                }),
            );
            const yAxisLabel = am5.Label.new(root, {
                text: mainReportData?.graph1DataHeadAxisTitle || "Head (ft)",
                fill: am5.color("#fff"),
                rotation: -90,
                centerX: am5.p100,
                centerY: am5.p100,
                x: am5.p50,
                y: am5.p50,
                paddingBottom: 50,
            });
    
            yAxis.children.push(yAxisLabel);
    
            const yAxis1Renderer = am5xy.AxisRendererY.new(root, {
                minorGridEnabled: false,
                opposite: true,
            });
            yAxis1Renderer.labels.template.set('fill', am5.color('#fff'));
            const yAxis1 = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    syncWithAxis: yAxis,
                    // min: 0,
                    // max: 1260,
                    strictMinMax: true,
                    renderer: yAxis1Renderer,
                    paddingLeft: -40,
                }),
            );
    
            const yAxis1Label = am5.Label.new(root, {
                text: "Efficiency (%)",
                fill: am5.color("#fff"),
                // rotation: -90,
                centerX: am5.p50,
                centerY: am5.p50,
                x: 80,
                y: -30,
                fontSize: 12,
                paddingLeft: -160,
            });
            yAxis1.children.push(yAxis1Label);
    
            //
    
            const yAxis2Renderer = am5xy.AxisRendererY.new(root, {
                minorGridEnabled: false,
                opposite: true,
            });
            yAxis2Renderer.labels.template.set('fill', am5.color('#fff'));
            const yAxis2 = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    syncWithAxis: yAxis,
                    // min: 0,
                    // max: 70,
                    strictMinMax: true,
                    renderer: yAxis2Renderer,
                    x: am5.p0,
                    y: am5.p0,
                    // marginRight: -70,
                }),
            );
    
            const yAxis2Label = am5.Label.new(root, {
                text: "Power (HP)",
                fill: am5.color("#fff"),
                // rotation: 90,
                centerX: am5.p50,
                centerY: am5.p50,
                x: 0,
                y: -30,
                fontSize: 12,
            });
            yAxis2.children.push(yAxis2Label);
    
            chart.zoomOutButton.set("forceHidden", true);
    
    
            const seriesHead = am5xy.SmoothedXYLineSeries.new(root, {
                name: 'Head',
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: 'Y',
                valueXField: 'X',
                stacked: true,
                stroke: am5.color("#4294FF"),
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: 'horizontal',
                    labelText: '{valueY}',
                }),
                fill: am5.color("#4294FF"),
            });
            seriesHead.data.setAll(capacityCurveData.head);
            seriesHead.data.processor = am5.DataProcessor.new(root, {
                numericFields: ['X', 'Y'],
            });
    
            const seriesPower = am5xy.SmoothedXYLineSeries.new(root, {
                name: 'Power',
                xAxis: xAxis,
                yAxis: yAxis1,
                valueYField: 'Y',
                valueXField: 'X',
                stroke: am5.color("#F97066"),
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: 'horizontal',
                    labelText: '{valueY}',
                }),
                fill: am5.color("#F97066"),
            });
            seriesPower.data.setAll(capacityCurveData.power);
            seriesPower.data.processor = am5.DataProcessor.new(root, {
                numericFields: ['X', 'Y'],
            });
    
            const seriesEfficiency = am5xy.SmoothedXYLineSeries.new(root, {
                name: 'Efficiency',
                xAxis: xAxis,
                yAxis: yAxis2,
                valueYField: 'Y',
                valueXField: 'X',
                stroke: am5.color("#32D583"),
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: 'horizontal',
                    labelText: '{valueY}',
                }),
                fill: am5.color("#32D583"),
            });
            seriesEfficiency.data.setAll(capacityCurveData.efficiency);
            seriesEfficiency.data.processor = am5.DataProcessor.new(root, {
                numericFields: ['X', 'Y'],
            });
    
            const seriesMin = am5xy.SmoothedXYLineSeries.new(root, {
                name: 'Min',
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: 'Y',
                valueXField: 'X',
                stroke: am5.color("#91A4AA"),
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: 'horizontal',
                    labelText: '{valueY}',
                }),
                fill: am5.color("#91A4AA"),
            });
            seriesMin.data.setAll(capacityCurveData.min);
            seriesMin.strokes.template.setAll({
                strokeDasharray: [4, 4],
            });
            seriesMin.data.processor = am5.DataProcessor.new(root, {
                numericFields: ['X', 'Y'],
            });
    
            const seriesBep = am5xy.SmoothedXYLineSeries.new(root, {
                name: 'Bep',
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: 'Y',
                valueXField: 'X',
                stroke: am5.color("#32D583"),
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: 'horizontal',
                    labelText: '{valueY}',
                }),
                fill: am5.color("#32D583"),
            });
            seriesBep.data.setAll(capacityCurveData.bep);
            seriesBep.strokes.template.setAll({
                strokeDasharray: [4, 4],
            });
            seriesBep.data.processor = am5.DataProcessor.new(root, {
                numericFields: ['X', 'Y'],
            });
    
            const seriesMax = am5xy.SmoothedXYLineSeries.new(root, {
                name: 'Max',
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: 'Y',
                valueXField: 'X',
                stroke: am5.color("#91A4AA"),
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: 'horizontal',
                    labelText: '{valueY}',
                }),
                fill: am5.color("#91A4AA"),
            });
            const deepCopy = JSON.parse(JSON.stringify(capacityCurveData.max));
            seriesMax.data.setAll(deepCopy);
            seriesMax.strokes.template.setAll({
                strokeDasharray: [4, 4],
            });
            seriesMax.data.processor = am5.DataProcessor.new(root, {
                numericFields: ['X', 'Y'],
            });
    
            chart.series.push(seriesHead);
            chart.series.push(seriesPower);
            chart.series.push(seriesEfficiency);
            chart.series.push(seriesMin);
            chart.series.push(seriesBep);
            chart.series.push(seriesMax);
    
            const legend = am5.Legend.new(root, {
                layout: am5.GridLayout.new(root, {
                    maxColumns: 3,
                    fixedWidthGrid: false,
                }),
                marginTop: 30,
                useDefaultMarker: true,
            });
      
          legend.markerRectangles.template.setAll({
            cornerRadiusTL: 10,
            cornerRadiusTR: 10,
            cornerRadiusBL: 10,
            cornerRadiusBR: 10,
            width: 12,
            height: 12,
          });
    
            // Set legend labels color
            legend.labels.template.setAll({
                fill: am5.color(0xffffff), // Adjust color as needed
            });
    
            // Customize or simplify the legend markers
            // legend.markers.template.setAll({
            //     width: 20, // Adjust width as needed
            //     height: 20, // Adjust height as needed
            // });
    
            // Add the legend to the chart
            chart.bottomAxesContainer.children.push(legend);
    
            
            // Link the legend to the series
            legend.data.setAll([seriesHead, seriesPower, seriesEfficiency]);
    
            chart.appear(0,500)
    }

    return (
        <>
            {loading ?
                <div className='flex items-center justify-content loader-margin'>
                    <Loader />
                </div>
                : <div id="CapacityGraph1Chartdiv" style={{ width: '100%', height: '600px' }}></div>
            }
        </>
    )

};

export default CapacityCurveGraph1;
