
import { apiInstance, http } from "../../../../../config/HttpCommon";
import { SpeedApiURL } from "../constants/speed.contants";

const getXspocSpeedData = async (
  assetName: string,
 /*  assetId: number,
  startDate: string,
  endDate: string, */
  channelIds: string,
  ) => {
    try {
      const params = {
        ...(assetName ? { AssetName: assetName } : {}),
       /*  ...(assetId ? { AssetId: assetId } : {}),
        ...(startDate ? { StartDate: startDate } : {}),
        ...(endDate ? { EndDate: endDate } : {}), */
        ...(channelIds ? { ChannelIds: channelIds } : {}),
      }
      const response = await http.get(
        SpeedApiURL.getXspocSpeedData,
        {params}
      );
      return response.data;
    } catch (error) {
        console.error("Error while fetching data:", error);
        throw error;
    }
};

const getSleSpeedData = async (
  assetName: string,
 /*  assetId: number,
  startDate: string,
  endDate: string, */
  variables: string,
  ) => {
    try {
      const params = {
        ...(assetName ? { AssetName: assetName } : {}),
       /*  ...(assetId ? { AssetId: assetId } : {}),
        ...(startDate ? { StartDate: startDate } : {}),
        ...(endDate ? { EndDate: endDate } : {}), */
        ...(variables ? { Variables: variables } : {}),
      }
      const response = await apiInstance.get(
        SpeedApiURL.getSleSpeedData,
        {params}
      );
      return response.data;
    } catch (error) {
        console.error("Error while fetching data:", error);
        throw error;
    }
};

const SpeedServices = {
  getXspocSpeedData,
  getSleSpeedData,
};
  
export default SpeedServices;
  
