import React, { memo, useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { AmpsChartProps, DataPoint } from '../interfaces/amps.interfaces';

const AmpsChart: React.FC<AmpsChartProps> = ({
  chartID, 
  chartRef,
  data,
  root,
}) => {
   
    useEffect(() => {  
        setTimeout(() => {

           if (chartRef.current) {
                chartRef.current.innerHTML = ''; // Clear the previous chart's HTML
            } 
            if (!chartRef.current) return;

            if (chartRef.current) {
                 // Dispose of previous root if it exists
                if (root.current) {
                    root.current.dispose();
                    root.current = null;
                }

                // Create a container for the chart and legend
                root.current = am5.Root.new(chartRef.current);
                root.current.setThemes([am5themes_Animated.new(root.current)]);

                // Create a main container to hold both the chart and the legend
                const mainContainer = root.current.container.children.push(
                    am5.Container.new(root.current, {
                        layout: root.current.verticalLayout,
                        width: am5.percent(100),
                        height: am5.percent(100),
                    })
                );

                // Create the chart container
                const chartContainer = mainContainer.children.push(
                    am5.Container.new(root.current, {
                        width: am5.percent(100),
                        height: am5.percent(85), // Adjust height to leave space for the legend
                    })
                );


                const chart = chartContainer.children.push(
                    am5xy.XYChart.new(root.current, {
                        focusable: true,
                        panX: false,
                        panY: false,
                        wheelY: 'none',
                        wheelX: 'none',
                        pinchZoomX: false,
                        pinchZoomY: false,
                        paddingBottom: 0,
                    }),
                );

                // Additionally, you can also disable zoomOutButton
                chart.zoomOutButton.set("forceHidden", true);

                const xAxis = chart.xAxes.push(
                    am5xy.DateAxis.new(root.current, {
                        maxDeviation: 0.1,
                        groupData: false,
                        extraMax: 0,
                        extraMin: 0,
                        //min: startDate.getTime(),
                        //max: endDate.getTime(), 
                        baseInterval: { timeUnit: 'day', count: 1 },
                        renderer: am5xy.AxisRendererX.new(root.current, {
                            minGridDistance: 50,
                            minorGridEnabled: true,
                            stroke: am5.color('#ffffff'), //#487aa6
                        }),
                    /*    dateFormats: {
                            day: 'MM/dd',
                            week: 'MM/dd',
                            month: 'MMM',
                            year: 'yyyy',
                        },
                        periodChangeDateFormats: {
                            day: 'MM/dd',
                            week: 'MM/dd',
                            month: 'MMM',
                            year: 'yyyy',
                        }, */
                        start: 0,
                        end: 1,
                    }),
                );
                xAxis.get('renderer').labels.template.set('fill', am5.color('#ffffff'));
                xAxis.get('renderer').labels.template.set('paddingTop', 15);

                const yRenderer = am5xy.AxisRendererY.new(root.current, {
                    opposite: false,
                    stroke: am5.color('#ffffff'),
                });
                yRenderer.labels.template.set('fill', am5.color('#fff'));
                const yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root.current, {
                        extraMax: 0,
                        extraMin: 0,
                        /* min: 0,
                        max: 1000,
                        strictMinMax: true,  */
                        maxDeviation: 0,
                        renderer: yRenderer,
                    }),
                );

                yAxis.children.unshift(
                    am5.Label.new(root.current, {
                    fill: am5.color(0xF7F9F9),
                    rotation: -90,
                    text: "Amps (A)",
                    y: am5.p50,
                    centerX: am5.p50
                    })
                );

                const cursor = chart.set(
                    'cursor',
                    am5xy.XYCursor.new(root.current, {
                        xAxis: xAxis,
                        yAxis: yAxis,
                        behavior: 'none',
                        showTooltipOn: 'hover',
                    }),
                );
                cursor.lineY.set('visible', false);
                cursor.lineX.setAll({
                    visible: true,
                    stroke: am5.color('#fff'), //#ADD8E6
                    strokeWidth: 2,
                });

                cursor.lineY.setAll({
                    strokeDasharray: [2, 2],
                });
                const createSeries = (data: DataPoint[], strokeColor: string, fillColor: string, name: string) => {
                    const series = chart.series.push(
                        am5xy.LineSeries.new(root.current, {
                            name: name,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            valueYField: 'value',
                            valueXField: 'date',
                            tooltip: am5.Tooltip.new(root.current, {
                                pointerOrientation: 'horizontal',
                                labelText: `{name}: {valueY} BBL`,
                            }),
                        }),
                    );

                    series.fills.template.set("fillGradient", am5.LinearGradient.new(root.current, {
                        stops: [{
                        opacity: 1
                        }, {
                        opacity: 0
                        }],
                        rotation: 90
                    }));
                    
                    series.fills.template.setAll({
                        visible: true,
                        fillOpacity: 1
                    });

                    series.set('stroke', am5.color(strokeColor));
                    series.set('fill', am5.color(strokeColor));
                    series.bullets.push(() => {
                        const circle = am5.Circle.new(root.current, {
                            radius: 0,
                            fill: am5.color('#0000'),
                            stroke: am5.color('#fff'),
                            strokeWidth: 5,
                            visible: true,
                        });

                        circle.states.create('hover', {
                            radius: 7,
                        });

                        return am5.Bullet.new(root.current, {
                            locationX: 0.5,
                            sprite: circle,
                        });
                    });

                    series.data.setAll(data);

                    cursor.events.on('cursormoved', cursorMoved);

                    let previousBulletSprites: any = [];
                    function cursorMoved() {
                        for (let i = 0; i < previousBulletSprites?.length; i++) {
                            previousBulletSprites[i].unhover();
                        }
                        previousBulletSprites = [];
                        chart.series.each(function (series: any) {
                            const dataItem = series?.get('tooltip')?.dataItem;
                            if (dataItem) {
                                const bulletSprite = dataItem?.bullets?.length && dataItem?.bullets[0]?.get('sprite');
                                bulletSprite && bulletSprite.hover();
                                previousBulletSprites.push(bulletSprite);
                            }
                        });
                    }
                }

                createSeries(data.underload, '#009CA1', '#009CA1', 'Underload');
                createSeries(data.overload, '#66FCFF', '#66FCFF', 'Overload');


                const resizeObserver = new ResizeObserver(() => {
                    chart.appear(0, 0);
                });

                resizeObserver.observe(chartRef.current);

                // Create the legend container below the chart
                const legendContainer = mainContainer.children.push(
                    am5.Container.new(root.current, {
                        width: am5.percent(100),
                        height: am5.percent(15), // Adjust height to match the space left by the chart
                        layout: root.current.verticalLayout,
                    })
                );

                const legend = legendContainer.children.push(
                    am5.Legend.new(root.current, {
                        layout: am5.GridLayout.new(root.current, {
                            maxColumns: 3,
                            fixedWidthGrid: true,
                        }),
                        centerX: am5.percent(10),
                        x: am5.percent(10),
                        setStateOnChildren: false,
                        paddingTop: 5,
                        useDefaultMarker: true,
                    })
                );

                legend.markerRectangles.template.setAll({
                    cornerRadiusTL: 10,
                    cornerRadiusTR: 10,
                    cornerRadiusBL: 10,
                    cornerRadiusBR: 10,
                    width: 10,
                    height: 10,
                });

                legend.labels.template.set('fill', am5.color(0xffffff));
                legend.data.setAll(chart.series.values);
                legend.itemContainers.template.states.create('hover', {});

                return () => {
                    root.current.dispose();
                    resizeObserver.disconnect();
                };
            }
        }, 300);
    }, [data]);


  return (
    <>
      <div ref={chartRef} id={chartID} style={{ height: '100%', width: '100%' }}></div> 
    </>
  )
}

export default memo(AmpsChart);