import { ConvertTimeToLocal } from "../../../../../utilities/CommonFunctions";
import { TrendsDetailsProps } from "../TrendsDetails";
import { SleTimeSeriesData } from "../../sle-time-series/SleSlice";
import LineChart from "./LineChart";
// import Loader from "../../../../common/page-loader/ComponentLoader";

interface CardsDesignProps {
    trend: TrendsDetailsProps | SleTimeSeriesData;
    // trend: TrendsDetailsProps;

    index: number;
    loadingIcon: boolean;
    selectedCalendarDate: {
        startDate: Date,
        endDate: Date
    };
    filteredValues: string[]
    trendsMinMaxValue: {
        [key: string]: {
            minValue: number;
            mean: number;
            maxValue: number;
            lastData: number;
            lastDataDate: string;
        };
    };
    setTrendsMinMaxValue: React.Dispatch<React.SetStateAction<{
        [key: string]: {
            minValue: number;
            mean: number;
            maxValue: number;
            lastData: number;
            lastDataDate: string;
        };
    }>>;
    currentAggregateValue: string
}



const CardsDesign: React.FC<CardsDesignProps> = ({ trend, index, loadingIcon, selectedCalendarDate, filteredValues, trendsMinMaxValue, setTrendsMinMaxValue, currentAggregateValue }) => {
    const trendMinMax = trendsMinMaxValue?.[trend?.trendName];
    const minValue = trendMinMax ? trendMinMax?.minValue : trend?.min
    const maxValue = trendMinMax ? trendMinMax?.maxValue : trend?.max
    const meanValue = trendMinMax ? trendMinMax?.mean : trend?.median
    const lastData = trendMinMax ? trendMinMax?.lastData : trend?.value
    const lastDataDate = trendMinMax ? trendMinMax?.lastDataDate : trend?.date

    const unitOfMeasure = 'short_UnitOfMeasure' in trend
        ? trend.short_UnitOfMeasure || (trend.unitOfMeasure === 'None' ? '' : trend.unitOfMeasure)
        : 'shortUnitOfMeasure' in trend ? trend.shortUnitOfMeasure || (trend.unitOfMeasure === 'None' ? '' : trend.unitOfMeasure) : '';
    return (
        <>
            <div className='card'>
                <div className='card-header'>
                    <div className='title'>{trend?.trendName}</div>
                </div>

                <div className='card-body'>
                    <div className='left-container'>
                        <h1 className='psi'>
                            {+Number(lastData)?.toFixed(3) || 0} {unitOfMeasure ?? ''}
                        </h1>
                        <div className='cont'>{lastDataDate ? ConvertTimeToLocal(lastDataDate) : '00/00/0000 00:00:00'}</div>
                        <div>
                            &nbsp;Min: {`${+Number(minValue)?.toFixed(3) || 0} ${unitOfMeasure ?? ''}`}&nbsp; |&nbsp; Mean:{' '}
                            {`${+Number(meanValue)?.toFixed(3) || 0} ${unitOfMeasure ?? ''}`} &nbsp;|&nbsp; Max: {`${+Number(maxValue).toFixed(3) || 0} ${unitOfMeasure ?? ''}`}
                            &nbsp;
                        </div>

                        <div className='thresholdcontainer margin-top'>
                            <span className='threshold'>↓ {(typeof trend?.maxThreshold === 'number') ? (trend?.maxThreshold ? trend?.maxThreshold : '-') : (trend?.maxThreshold === "∞" || trend?.maxThreshold?.toLowerCase() === "infinite" ? '-' : `${+Number(trend?.maxThreshold)?.toFixed(1)}%`)} </span>Max threshold
                        </div>
                        <div className='thresholdcontainer'>
                            <span className='threshold'>↑ {(typeof trend?.maxThreshold === 'number') ? (trend?.minThreshold ? trend?.minThreshold : '-') : (trend?.minThreshold === "∞" || trend?.minThreshold?.toLowerCase() === "infinite" ? '-' : `${+Number(trend?.minThreshold)?.toFixed(1)}%`)} </span>Min threshold
                        </div>
                    </div>
                    <div className='rigth-container trends-right'>
                        <>
                            <LineChart
                                chartName={trend?.trendName}
                                unitOfMeasure={unitOfMeasure}
                                chartData={trend?.dataPoints}
                                index={index}
                                selectedCalendarDate={selectedCalendarDate}
                                loadingIcon={loadingIcon}
                                key={trend?.trendName}
                                filteredValues={filteredValues}
                                setTrendsMinMaxValue={setTrendsMinMaxValue}
                                currentAggregateValue={currentAggregateValue}
                            />
                        </>

                    </div>
                </div>
            </div>
        </>
    );
};

export default CardsDesign