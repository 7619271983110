import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import annotation_plus from './../../../../images/expand-icon.svg';
import download from '../../../../../src/images/download-icon.svg';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoData from '../../../dashboard/components/no-data/NoData';
import { IntakeDetailsProps, IntakeDischargeData } from './interfaces/intake.interfaces';
import IntakeChart from './charts/IntakeChart';
import IntakeModal from './modals/IntakeModal';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { fetchTimeSeriesChannelData, fetchTimeSeriesDataVariables, getChannelDataByKey, getChartLoadingStateByName, getTimeSeriesDataByKey } from '../../wellControlroomSlice';
import championx from '../../../../../src/images/championxLogo.png';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import moment from 'moment-timezone';

const CHART_NAME = "intake-discharge";

const IntakeDischargeDetails: React.FC<IntakeDetailsProps> = ({ heading }) => {
    const dispatch = useAppDispatch();
    const selectedWell = sessionStorage.getItem('selectedWellName') && JSON.parse(sessionStorage.getItem('selectedWellName') || '');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const cardChartRef = useRef<HTMLDivElement | null>(null);
    const modalChartRef = useRef<HTMLDivElement | null>(null);
    const [data, setData] = useState<IntakeDischargeData>({ intake: [], discharge: [] });
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
        endDate: new Date(),
        key: 'selection',
    });
    const channelIDsObj = useAppSelector((state) => state.wellControlroomCharts.channelIDsObj);
    const currentUser = useAppSelector((state) => state?.user?.currentUser);

    const { userPrefrence, assetInfo, loading, masterVariables } = useAppSelector((state) => ({
        userPrefrence: state.controlroom.userPrefrence?.find(item => item.key === CHART_NAME),
        assetInfo: state.assetInfo.assetDetails,
        loading: state.wellControlroomCharts.loading,
        masterVariables: state.wellControlroomCharts.masterVariables,
    }));
    const chartLoading = useAppSelector(getChartLoadingStateByName(CHART_NAME));
    const hElement = document.getElementById('main-root');
    const root = useRef<any>(null);
    const root2 = useRef<any>(null);

    const intakeDischargeChannelData = {
        intake: useAppSelector(getChannelDataByKey(channelIDsObj.intake || '')),
        discharge: useAppSelector(getChannelDataByKey(channelIDsObj.discharge || '')),
    };

    const intakeDischargeTimeSeriesData = {
        intake: useAppSelector(getTimeSeriesDataByKey(userPrefrence?.mappings?.sle?.intake || '')),
        discharge: useAppSelector(getTimeSeriesDataByKey(userPrefrence?.mappings?.sle?.discharge || '')),
    };

    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        if (assetInfo?.isSleAsset) {
            if (!userPrefrence?.mappings?.sle?.intake && !userPrefrence?.mappings?.sle?.discharge) return;
            dispatch(fetchTimeSeriesDataVariables({
                assetName: selectedWell && selectedWell.assetName,
                startDate: selectedDateRange.startDate.toISOString(),
                endDate: selectedDateRange.endDate.toISOString(),
                variables: [userPrefrence?.mappings?.sle?.intake || "", userPrefrence?.mappings?.sle?.discharge || ""],
                chartName: CHART_NAME,
            }));
        } else {
            if (!channelIDsObj.intake && !channelIDsObj.discharge) return;
            dispatch(fetchTimeSeriesChannelData({
                assetName: selectedWell && selectedWell.assetName,
                startDate: selectedDateRange.startDate.toISOString(),
                endDate: selectedDateRange.endDate.toISOString(),
                channelIds: [channelIDsObj.intake, channelIDsObj.discharge],
                chartName: CHART_NAME,
            }));
        }
    }, [selectedDateRange.startDate, selectedDateRange.endDate]);

    useEffect(() => {
        if (userPrefrence?.mappings?.sle && assetInfo?.isSleAsset) {
            setData({
                intake: intakeDischargeTimeSeriesData?.intake?.dataPoints.map(item => ({ date: new Date(item.date).getTime(), value: Number(item.value) })) || [],
                discharge: intakeDischargeTimeSeriesData?.discharge?.dataPoints.map(item => ({ date: new Date(item.date).getTime(), value: Number(item.value) })) || [],
            });
        } else if (userPrefrence?.mappings?.xspoc && !assetInfo?.isSleAsset) {
            setData({
                intake: intakeDischargeChannelData.intake?.dataPoints.map((item: any) => ({
                    date: new Date(item.date).getTime(),
                    value: Number(item.value),
                })),
                discharge: intakeDischargeChannelData.discharge?.dataPoints.map((item: any) => ({
                    date: new Date(item.date).getTime(),
                    value: Number(item.value),
                })),
            });
        }
    }, [dispatch, userPrefrence?.key, selectedWell?.assetName, selectedDateRange?.startDate, selectedDateRange?.endDate, JSON.stringify(masterVariables), JSON.stringify(intakeDischargeChannelData), JSON.stringify(intakeDischargeTimeSeriesData)]);

    const openModal = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setModalIsOpen(true);
        hElement && hElement?.classList.add('modal-open');
    };

    const handleClose = () => {
        setModalIsOpen(false);
        hElement && hElement?.classList.remove('modal-open');
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const printWidgetAsPDF = () => {
        if (root.current) {
          const exporting = am5plugins_exporting.Exporting.new(root.current, {
            menu: am5plugins_exporting.ExportingMenu.new(root.current, {}),
          });
          Promise.all([exporting.getPdfmake(),
          exporting.export('png')]).then((res) => {
            const pdfMake = res[0];
            const str = moment().format('MMM-DD-YYYY hh:mm:ss A');
            const doc = {
              pageSize: 'A4',
              pageOrientation: 'portrait',
              pageMargins: [30, 30, 30, 30],
              footer: {
                columns: [
                  {
                    text: `Generated By: ${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`,
                    alignment: 'left',
                    width: '50%',
                    bold: true,
                    margin: [10, 10],
                    fontSize: 9,
                    color: 'black',
                    decoration: 'underline',
                  },
                  {
                    text: 'Generated on : ' + str,
                    alignment: 'right',
                    width: '50%',
                    bold: true,
                    margin: [10, 10],
                    fontSize: 9,
                    color: 'black',
                    decoration: 'underline',
                  },
                ],
              },
              content: [] as any[],
              styles: {
                tblheader: {
                  fontSize: 9.5,
                  color: 'white',
                  fillColor: '#01485e',
                  alignment: 'center',
                },
              },
            };
    
            const rect = {
              type: 'rect',
              x: -32,
              y: -20, //could be negative to account for padding
              w: 450,
              h: 25,
              linearGradient: ['#01485e', '#01485e'],
            };
            const rectHead = {
              type: 'rect',
              x: -32,
              y: -20, //could be negative to account for padding
              w: 150,
              h: 25,
              linearGradient: ['#484848', '#484848'],
            };
            doc.content.push({
              columns: [
                {
                  stack: [
                    { canvas: [rect] },
                    {
                      columns: [
                        {
                          width: rect.w / 2,
                          noWrap: false, //clip at with by wrapping to next line and...
                          maxHeight: rect.h, //don't show the wrapped text
                          image: championx,
                          fit: [55, 55],
                          margin: [5.5, 7.5, 3],
                          background: 'white',
                        },
                        {
                          text: 'Landing Page',
                          width: rect.w / 2,
                          alignment: 'left',
                          bold: true,
                          margin: [35, 5],
                          fontSize: 13,
                          color: 'white',
                        },
                      ],
                      relativePosition: {
                        x: rect.x,
                        y: -rect.h,
                      },
                    },
                  ],
                },
                {
                  stack: [
                    { canvas: [rectHead] },
                    {
                      columns: [
                        {
                          width: rectHead.w,
                          noWrap: false, //clip at with by wrapping to next line and...
                          maxHeight: rect.h, //don't show the wrapped text
                          text: 'LOOKOUT',
                          color: 'white',
                          bold: true,
                          alignment: 'right',
                          'font-size': 10,
                        },
                      ],
                      relativePosition: {
                        x: -80,
                        y: -20,
                      },
                    },
                  ],
                },
              ],
            });
            doc.content.push({
              text: ' ',
              width: '100%',
            });
    
            doc.content.push(
              {
                text: 'Intake/Discharge',
                width: '*',
                alignment: 'center',
                fontSize: 18,
                margin: [0, 0, 0, 5],
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 175,
                    y1: 0,
                    x2: 362,
                    y2: 0,
                    lineWidth: 1,
                  },
                ],
                margin: [0, 0, 0, 15],
              },
            );
    
            doc.content.push({
              image: res[1],
              alignment: 'center',
              width: 400,
              height: 180,
              margin: [0, 0, 0, 20],
            });
    
            const fileName = 'Lookout-intake-discharge_' + str + '.pdf';
    
            pdfMake.createPdf(doc).download(fileName);
          }).finally(() => {
            setIsDropdownOpen(false);
    
          });
        }
      };

    return (
        <>
            <ToastContainer position='top-right' autoClose={3000} />
            <div className='grid-item-text h-full graph-card well-graph-card'>
                <div className='table-header-section pie-section'>
                    <div className='title'>{heading}</div>
                    {userPrefrence?.expandable && <div className='header-icon'>
                        <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
                            <img src={annotation_plus} alt='Expand' className='img-border' />
                        </button>
                    </div>}
                    {userPrefrence?.actions.show && <div className='mr-4 dropdown-container' ref={dropdownRef}>
                        <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                            <span className='dot'></span>
                            <span className='dot'></span>
                            <span className='dot'></span>
                        </button>
                        {isDropdownOpen && (
                            <div className='dropdown-options'>
                                <button>Actions</button>
                                <button className={`btn btn-default cancelSelectorName`} onClick={printWidgetAsPDF}>
                                    <img src={download} alt='icon' className='btn-icon' />
                                    Download PDF
                                </button>
                                <button className={`btn btn-default cancelSelectorName`}>
                                    <img src={download} alt='icon' className='btn-icon' />
                                    Download CSV
                                </button>
                            </div>
                        )}
                    </div>}
                </div>
                <hr />
                <div id="intakeDischarge-container" className='h-full wellChartSection intakeDischargeChartSection'>
                    {loading || chartLoading ? (
                        // <div className='well-production-loader'>
                        <Loader />
                        // </div> 
                    ) : data?.intake?.length > 0 || data?.discharge?.length > 0 ? (
                        <>
                            <IntakeChart chartID={'intakeChartdiv'} chartRef={cardChartRef} data={data} root={root} />
                        </>
                    ) : (
                        <NoData heading='No data found' />
                    )}
                </div>
            </div>
            <IntakeModal
                isOpen={modalIsOpen}
                onRequestClose={handleClose}
                heading={'Intake / Discharge'}
                loading={loading}
                modelWidth={'75rem'}
                modelClass={'wellChartModal'}
                selectedDateRange={selectedDateRange}
                setSelectedDateRange={setSelectedDateRange}
                innerComponent={
                    <>
                        {loading || chartLoading ? (
                            <Loader />
                        ) : data?.intake?.length > 0 || data?.discharge?.length > 0 ? (
                            <IntakeChart chartID={'intakeChartdiv'} chartRef={modalChartRef} data={data} root={root2} />
                        ) : (
                            <NoData heading='No data found' />
                        )
                        }
                    </>
                }
            />
        </>
    );
};

export default IntakeDischargeDetails;