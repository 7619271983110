import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import annotation_plus from './../../../../images/expand-icon.svg';
import download from '../../../../../src/images/download-icon.svg';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoData from '../../../dashboard/components/no-data/NoData';
import TempModal from './modals/TempModal';
import { TemperatureDetailsProps } from './interfaces/temperature.interface';
import TempChart from './charts/TempChart';
import { bandsData } from './constants/temp.constants';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { getChannelDataByKey, getTimeSeriesDataByKey } from '../../wellControlroomSlice';
import { AssetTimeseriesData } from '../../interfaces/timeSeriesChannelIdsData.interface';
import { TimeseriesData } from '../../interfaces/timeSeries.interface';
import championx from '../../../../../src/images/championxLogo.png';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import moment from 'moment-timezone';

const TemperatureDetails: React.FC<TemperatureDetailsProps> = ({ heading }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const motorChartdiv1 = useRef<HTMLDivElement | null>(null);
  const intakeChartdiv2 = useRef<HTMLDivElement | null>(null);
  const ambientChartdiv3 = useRef<HTMLDivElement | null>(null);
  const motorChartdiv4 = useRef<HTMLDivElement | null>(null);
  const intakeChartdiv5 = useRef<HTMLDivElement | null>(null);
  const ambientChartdiv6 = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const root = useRef<any>(null);
  const root2 = useRef<any>(null);
  const root3 = useRef<any>(null);
  const root4 = useRef<any>(null);
  const root5 = useRef<any>(null);
  const root6 = useRef<any>(null);

  const [data, setData] = useState<
    {
      ambient: TimeseriesData | AssetTimeseriesData
      intakeTemp: TimeseriesData | AssetTimeseriesData
      motor: TimeseriesData | AssetTimeseriesData
    }
  >({
    ambient: {} as TimeseriesData | AssetTimeseriesData,
    intakeTemp: {} as TimeseriesData | AssetTimeseriesData,
    motor: {} as TimeseriesData | AssetTimeseriesData,
  });
  const hElement = document.getElementById('main-root');

  const userPrefrenceData = useAppSelector((state) => state.controlroom.userPrefrence);
  const assetInfoData = useAppSelector((state) => state.assetInfo.assetDetails);
  const tempObject = userPrefrenceData.find(item => item?.title.toLowerCase() === "Temperature".toLowerCase());
  const loading = useAppSelector((state) => state.wellControlroomCharts.loading);
  const channelIDsObj = useAppSelector((state) => state.wellControlroomCharts.channelIDsObj);
  const currentUser = useAppSelector((state) => state?.user?.currentUser);

  const temperatureChannelData = {
    ambient: useAppSelector(getChannelDataByKey(channelIDsObj.ambient || '')),
    intakeTemp: useAppSelector(getChannelDataByKey(channelIDsObj.intakeTemp || '')),
    motor: useAppSelector(getChannelDataByKey(channelIDsObj.motor || '')),
  }

  console.log('temperatureChannelData', temperatureChannelData);
  console.log(`channelIDsObj.ambient`, channelIDsObj);



  const temperatureTimeSeriesData = {
    ambient: useAppSelector(getTimeSeriesDataByKey(channelIDsObj.ambient || '')),
    intakeTemp: useAppSelector(getTimeSeriesDataByKey(channelIDsObj.intakeTemp || '')),
    motor: useAppSelector(getTimeSeriesDataByKey(channelIDsObj.motor || '')),
  }

  useEffect(() => {
    if (assetInfoData?.isSleAsset === true) {
      setData(temperatureTimeSeriesData)
    } else {
      setData(temperatureChannelData);
    }
  }, [JSON.stringify(temperatureChannelData), JSON.stringify(temperatureTimeSeriesData), JSON.stringify(assetInfoData), JSON.stringify(userPrefrenceData)]);

  const openModal = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setModalIsOpen(true);
    hElement && hElement?.classList.add('modal-open');
  };

  const handleClose = () => {
    setModalIsOpen(false);
    hElement && hElement?.classList.remove('modal-open');
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const printWidgetAsPDF = () => {
    if (root.current) {
      const exporting = am5plugins_exporting.Exporting.new(root.current, {
        menu: am5plugins_exporting.ExportingMenu.new(root.current, {}),
      });
      const exporting2 = am5plugins_exporting.Exporting.new(root2.current, {
        menu: am5plugins_exporting.ExportingMenu.new(root2.current, {}),
      });
      const exporting3 = am5plugins_exporting.Exporting.new(root3.current, {
        menu: am5plugins_exporting.ExportingMenu.new(root3.current, {}),
      });
      Promise.all([exporting.getPdfmake(),
      exporting.export('png'),
      exporting2.export('png'),
      exporting3.export('png')]).then((res) => {
        const pdfMake = res[0];
        const str = moment().format('MMM-DD-YYYY hh:mm:ss A');
        const doc = {
          pageSize: 'A4',
          pageOrientation: 'portrait',
          pageMargins: [30, 30, 30, 30],
          footer: {
            columns: [
              {
                text: `Generated By: ${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`,
                alignment: 'left',
                width: '50%',
                bold: true,
                margin: [10, 10],
                fontSize: 9,
                color: 'black',
                decoration: 'underline',
              },
              {
                text: 'Generated on : ' + str,
                alignment: 'right',
                width: '50%',
                bold: true,
                margin: [10, 10],
                fontSize: 9,
                color: 'black',
                decoration: 'underline',
              },
            ],
          },
          content: [] as any[],
          styles: {
            tblheader: {
              fontSize: 9.5,
              color: 'white',
              fillColor: '#01485e',
              alignment: 'center',
            },
          },
        };

        const rect = {
          type: 'rect',
          x: -32,
          y: -20, //could be negative to account for padding
          w: 450,
          h: 25,
          linearGradient: ['#01485e', '#01485e'],
        };
        const rectHead = {
          type: 'rect',
          x: -32,
          y: -20, //could be negative to account for padding
          w: 150,
          h: 25,
          linearGradient: ['#484848', '#484848'],
        };
        doc.content.push({
          columns: [
            {
              stack: [
                { canvas: [rect] },
                {
                  columns: [
                    {
                      width: rect.w / 2,
                      noWrap: false, //clip at with by wrapping to next line and...
                      maxHeight: rect.h, //don't show the wrapped text
                      image: championx,
                      fit: [55, 55],
                      margin: [5.5, 7.5, 3],
                      background: 'white',
                    },
                    {
                      text: 'Landing Page',
                      width: rect.w / 2,
                      alignment: 'left',
                      bold: true,
                      margin: [35, 5],
                      fontSize: 13,
                      color: 'white',
                    },
                  ],
                  relativePosition: {
                    x: rect.x,
                    y: -rect.h,
                  },
                },
              ],
            },
            {
              stack: [
                { canvas: [rectHead] },
                {
                  columns: [
                    {
                      width: rectHead.w,
                      noWrap: false, //clip at with by wrapping to next line and...
                      maxHeight: rect.h, //don't show the wrapped text
                      text: 'LOOKOUT',
                      color: 'white',
                      bold: true,
                      alignment: 'right',
                      'font-size': 10,
                    },
                  ],
                  relativePosition: {
                    x: -80,
                    y: -20,
                  },
                },
              ],
            },
          ],
        });
        doc.content.push({
          text: ' ',
          width: '100%',
        });

        doc.content.push(
          {
            text: 'Temperature',
            width: '*',
            alignment: 'center',
            fontSize: 18,
            margin: [0, 0, 0, 5],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 175,
                y1: 0,
                x2: 362,
                y2: 0,
                lineWidth: 1,
              },
            ],
            margin: [0, 0, 0, 15],
          },
        );

        doc.content.push({
          image: res[1],
          alignment: 'center',
          width: 250,
          height: 180,
          margin: [0, 0, 0, 20],
        });
        doc.content.push({
          image: res[2],
          alignment: 'center',
          width: 250,
          height: 180,
          margin: [0, 0, 0, 20],
        });
        doc.content.push({
          image: res[3],
          alignment: 'center',
          width: 250,
          height: 180,
          margin: [0, 0, 0, 20],
        });

        const fileName = 'Lookout-temperature_' + str + '.pdf';

        pdfMake.createPdf(doc).download(fileName);
      }).finally(() => {
        setIsDropdownOpen(false);

      });
    }
  };

  return (
    <>
      <ToastContainer position='top-right' autoClose={3000} />
      <div className='grid-item-text h-full graph-card well-graph-card'>
        <div className='table-header-section pie-section'>
          <div className='title'>{heading}</div>
          {tempObject?.expandable && <div className='header-icon'>
            <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
              <img src={annotation_plus} alt='Expand' className='img-border' />
            </button>
          </div>}
          {tempObject?.actions.show && <div className='mr-4 dropdown-container' ref={dropdownRef}>
            <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <span className='dot'></span>
              <span className='dot'></span>
              <span className='dot'></span>
            </button>
            {isDropdownOpen && (
              <div className='dropdown-options'>
                <button>Actions</button>
                <button className={`btn btn-default cancelSelectorName`} onClick={printWidgetAsPDF}>
                  <img src={download} alt='icon' className='btn-icon' />
                  Download PDF
                </button>
                <button className={`btn btn-default cancelSelectorName`}>
                  <img src={download} alt='icon' className='btn-icon' />
                  Download CSV
                </button>
              </div>
            )}
          </div>}
        </div>
        <hr />
        <div id="temperature-container" className='flex flex-wrap items-center justify-center wellChartSection tempChart-container-dashboard nodata'>

          {loading ? (
            <div className='flex items-center justify-center w-100'>
              <Loader />
            </div>
          ) : (data?.ambient || data?.intakeTemp || data?.motor) ? (
            <>
              {/* <div id={`speedchartdiv`} ref={speedHzchartdiv} className='chart-wrapper'></div> */}
              <TempChart chartID={'motorChartdiv1'} chartRef={motorChartdiv1} classValue={'w-[33.33%]'} minValue={50} maxValue={350} clockHandValue={177} clockHandName={'F'} data={data.motor} chartName={'Motor'} bandsData={bandsData} spacingValue={4} legendPos={90} root={root} />
              <TempChart chartID={'intakeChartdiv2'} chartRef={intakeChartdiv2} classValue={'w-[33.33%]'} minValue={50} maxValue={350} clockHandValue={220} clockHandName={"F"} data={data.intakeTemp} chartName={'Intake'} bandsData={bandsData} spacingValue={4} legendPos={90} root={root2} />
              <TempChart chartID={'ambientChartdiv3'} chartRef={ambientChartdiv3} classValue={'w-[33.33%]'} minValue={50} maxValue={350} clockHandValue={220} clockHandName={"F"} data={data.ambient} chartName={'Ambient'} bandsData={bandsData} spacingValue={4} legendPos={90} root={root3} />
            </>
          ) : (
            <NoData heading='No data found' />
          )}

        </div>
      </div>
      <TempModal
        isOpen={modalIsOpen}
        onRequestClose={handleClose}
        heading={'Temperature'}
        loading={loading}
        modelWidth={'80rem'}
        modelClass={'wellChartModal'}
        innerComponent={
          <>
            {loading ? (
              <Loader />
            ) : (data?.ambient || data?.intakeTemp || data?.motor) ? (

              <div className='flex justify-center w-full'>
                <TempChart chartID={'motorChartdiv4'} chartRef={motorChartdiv4} classValue={'w-[33.33%] temp-chart'} minValue={50} maxValue={350} clockHandValue={177} clockHandName={'F'} data={data.motor} chartName={'Motor'} bandsData={bandsData} spacingValue={4} legendPos={100} root={root4} />
                <TempChart chartID={'intakeChartdi5'} chartRef={intakeChartdiv5} classValue={'w-[33.33%] temp-chart'} minValue={50} maxValue={350} clockHandValue={220} clockHandName={"F"} data={data.intakeTemp} chartName={'Intake'} bandsData={bandsData} spacingValue={4} legendPos={100} root={root5} />
                <TempChart chartID={'ambientChartdi6'} chartRef={ambientChartdiv6} classValue={'w-[33.33%] temp-chart'} minValue={50} maxValue={350} clockHandValue={220} clockHandName={"F"} data={data.ambient} chartName={'Ambient'} bandsData={bandsData} spacingValue={4} legendPos={100} root={root6} />
              </div>
            ) : (
              <NoData heading='No data found' />
            )}
          </>
        }
      />
    </>
  );
};

export default TemperatureDetails;