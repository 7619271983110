import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { slugify } from '../utilities/Slugify';

interface Tab {
  label: string;
  component: React.ComponentType;
}

interface TabsProps {
  initialTab: {
    tab: string;
  };
  tabs: Tab[];
  value: string | undefined;
  dataSource?: string;
}

const TabWithUrlComponent: React.FC<TabsProps> = ({ initialTab, tabs, value, dataSource }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const storedTabIndex = sessionStorage.getItem('activeTabIndex');
  const defaultTabIndex = 0;
  const [activeTabIndex, setActiveTabIndex] = useState(
    storedTabIndex !== null
      ? parseInt(storedTabIndex, 10)
      : tabs.findIndex((tab) => tab.label === initialTab.tab) || defaultTabIndex,
  );

  const handleTabClick = (index: number) => {
    sessionStorage.setItem('activeTabIndex', index.toString());
    setActiveTabIndex(index);
  };

  useEffect(() => {
    const currentTabFromQuery = new URLSearchParams(location.search).get('tab');
    if (currentTabFromQuery) {
      const tabIndex = tabs.findIndex((tab) => slugify(tab.label) === currentTabFromQuery);
      if (tabIndex !== -1 && storedTabIndex !== null) {
        if (Number(storedTabIndex) !== Number(storedTabIndex)) {
          setActiveTabIndex(tabIndex);
        }
      }
    }
  }, [location.search, tabs]);

  useEffect(() => {
    const validIndex = activeTabIndex >= 0 && activeTabIndex < tabs?.length ? activeTabIndex : defaultTabIndex;
    navigate(`${location.pathname}?tab=${slugify(tabs[validIndex].label)}`);
  }, [activeTabIndex, navigate, location.pathname, tabs, defaultTabIndex]);

  return (
    <>
      <div className='justify-between  ui-button-toolbar '>
        <div className='flex flex-grow'>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`ui-button-toolbar-button ${
                index === activeTabIndex ? 'ui-button-toolbar-button-active semi-bold' : 'semi-bold'
              }`}
              onClick={() => handleTabClick(index)}
            >
              {tab.label}
            </div>
          ))}
        </div>

        {dataSource && (
          <div className='pr-[20px] text-[#f7f9f9]'>
            Data Source : <span className='text-[#60bfda]'>{dataSource ?? '-'}</span>
          </div>
        )}
      </div>
      <div className='tab-content'>
        {React.createElement(tabs[activeTabIndex]?.component || tabs[defaultTabIndex].component, {
          value,
        } as React.Attributes)}
      </div>
    </>
  );
};

export default TabWithUrlComponent;
