import { apiInstance, http } from "../../../../../config/HttpCommon"; 
import { TempApiURL } from "../constants/temp.constants";


const getXspocTemperatureData = async (
  assetName: string,
  /*  assetId: number,
   startDate: string,
   endDate: string, */
   channelIds: string,
) => {
  try {
    const params = {
      ...(assetName ? { AssetName: assetName } : {}),
       /*  ...(assetId ? { AssetId: assetId } : {}),
        ...(startDate ? { StartDate: startDate } : {}),
        ...(endDate ? { EndDate: endDate } : {}), */
        ...(channelIds ? { ChannelIds: channelIds } : {}),
    }
    const response = await http.get(
      TempApiURL.getXspocTempData,
      {params}
    );
    return response.data;
  } catch (error) {
      console.error("Error while fetching data:", error);
      throw error;
  }
};

const getSleTemperatureData = async (
  assetName: string,
 /*  assetId: number,
  startDate: string,
  endDate: string, */
  variables: string,
  ) => {
    try {
      const params = {
        ...(assetName ? { AssetName: assetName } : {}),
       /*  ...(assetId ? { AssetId: assetId } : {}),
        ...(startDate ? { StartDate: startDate } : {}),
        ...(endDate ? { EndDate: endDate } : {}), */
        ...(variables ? { Variables: variables } : {}),
      }
      const response = await apiInstance.get(
        TempApiURL.getSleTempData,
        {params}
      );
      return response.data;
    } catch (error) {
        console.error("Error while fetching data:", error);
        throw error;
    }
};


const TempServices = {
  getXspocTemperatureData,
  getSleTemperatureData,
};
  
export default TempServices;
  