import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TimeseriesData } from "../../interfaces/timeSeries.interface";
import { getAssetTimeseriesChannelsData, getTimeseriesData } from "../../services/wellControlroom.service";
import { AssetTimeseriesData } from "../../interfaces/timeSeriesChannelIdsData.interface";

interface wellControlroomCharts {
    loading: boolean;
    error: string | null;
    casingtubingTimeSeriesData: TimeseriesData[];
    casingtubingChannelData: AssetTimeseriesData[];
}

const initialState: wellControlroomCharts = {
    loading: false,
    error: null,
    casingtubingTimeSeriesData: [],
    casingtubingChannelData: []
}

export const fetchTimeSeriesData = createAsyncThunk(
    'events/fetchTimeSeriesDataCT',
    async ({ assetName, startDate, endDate, variables }: {
        assetName: string;
        startDate: string;
        endDate: string;
        variables: string;
    }) => {

        const response = await getTimeseriesData(assetName, startDate, endDate, variables);
        return response;
    }
);

export const fetchTimeSeriesChannelData = createAsyncThunk(
    'events/fetchTimeSeriesChannelDataCT',
    async ({ assetName, startDate, endDate, variables }: {
        assetName: string;
        startDate: string;
        endDate: string;
        variables: string;
    }) => {
        const response = await getAssetTimeseriesChannelsData(assetName, startDate, endDate, variables);
        return response;
    }
);


const casingTubingSlice = createSlice({
    name: 'casingTubing',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTimeSeriesData.fulfilled, (state, action) => {
            state.casingtubingTimeSeriesData = action.payload.data;
            state.loading = false;
        });
        builder.addCase(fetchTimeSeriesData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchTimeSeriesData.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        });
        builder.addCase(fetchTimeSeriesChannelData.fulfilled, (state, action) => {
            state.casingtubingChannelData = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchTimeSeriesChannelData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchTimeSeriesChannelData.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        });
    }
});

export default casingTubingSlice.reducer;
export const casingTubingActions = casingTubingSlice.actions;
