export const TempApiURL: any = {
    getXspocTempData: "GetAssetTimeseriesChannelsData",
    getSleTempData: "v1/timeseries",
};

// Create axis ranges bands
export const bandsData = [{
    title: "Volatile",
    color: "#F97066",
    lowScore: 50,
    highScore: 75
  }, {
    title: "Foundational",
    color: "#FDB022",
    lowScore: 75,
    highScore: 100
  }, {
    title: "Developing",
    color: "#12B76A",
    lowScore: 100,
    highScore: 175
  }, {
    title: "Maturing",
    color: "#12B76A",
    lowScore: 175,
    highScore: 225
  }, {
    title: "Sustainable",
    color: "#FDB022",
    lowScore: 225,
    highScore: 300
  }, {
    title: "High Performing",
    color: "#F97066",
    lowScore: 300,
    highScore: 350
}];

  