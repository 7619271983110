import React, { memo, useState } from 'react';
import Close from '../../../../../images/x-close.svg';
import CustomModal from '../../../../dashboard/components/graph/ExpandModel';
import Loader from '../../../../common/page-loader/ComponentLoader';
import { CommonChartModalProps } from '../../../interfaces/modal.interface';
import DateRangeSelector from '../../../../common/DateRangeSelector/DateRangeSelector';
import { customStaticRanges, defineds, formateDate } from '../../../../common/DateRangeSelector/DefaultRanges';
import calendarIcon from '../../../../../images/calendar-icon.svg';


const CasingModal: React.FC<CommonChartModalProps> = ({
  isOpen,
  onRequestClose,
  heading,
  loading,
  modelWidth,
  modelClass,
  innerComponent,
  selectedDateRange,
  setSelectedDateRange,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);


  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose} width={modelWidth} classname={modelClass}>
      <div className='modal-header'>
        <div className='flex'>
          <div className='grow'>
            <h3>{heading}</h3>
          </div>
          <div className='flex-none'>
            <button onClick={onRequestClose}>
              <img src={Close} alt='Expand' />
            </button>
          </div>
        </div>
      </div>
      <div className='modal-content'>
        <div className='well-production-trends__modal-calendar-container date-range-picker'>
          {showCalendar && (
            <DateRangeSelector
              setShowCalendar={setShowCalendar}
              setSelectedDateRange={setSelectedDateRange}
              staticRanges={customStaticRanges}
              minDate={defineds?.last180Days}
              maxDate={defineds?.endOfToday}
              selectedDateRange={selectedDateRange}
            />
          )}
        </div>
        <div className='flex flex-wrap items-center justify-center wellChart-container-dashboard nodata'>

          {loading ? (
            <div className='flex items-center justify-center w-100'>
              <Loader />
            </div>
          ) : (
            <>
              <div className='flex justify-end w-full'>
                <div className='well-production-trends__modal-input-group'>
                  <img src={calendarIcon} alt='Range Calendar' />
                  <input
                    id='range-calendar-input'
                    className='input-date'
                    value={`${formateDate(selectedDateRange.startDate)} - ${formateDate(selectedDateRange.endDate)}`}
                    onClick={() => setShowCalendar(!showCalendar)}
                  />
                </div>

              </div>
              {innerComponent}
            </>
          )}
        </div>
      </div>
    </CustomModal>
  )
}

export default memo(CasingModal);