import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { fetchTrendsById, fetchTrendsChartFilterData, resetTrendsDataSet } from './TrendsSlice';
import { TrendsDetailsProps } from './TrendsDetails';
import { defaultStaticRanges, defineds, formateDate } from '../../../common/DateRangeSelector/DefaultRanges';
import calenderIcon from '../../../../images/calendar-icon.svg';
import filterIcon from '../../../../images/filter-icon.svg';
import downIcon from '../../../../images/chevron-down.svg';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { useDetectClickOutside } from 'react-detect-click-outside';
import DateRangeSelector from '../../../common/DateRangeSelector/DateRangeSelector';
import './trends.scss';
import Loader from '../../../common/page-loader/ComponentLoader';
import { AppUser } from '../../../user/model/AppUser';
import axios from 'axios';
import { clearAssetEventSchedule, fetchAssetListById } from '../../../dashboard/DashboardSlice';
import { updateAssetControlScanStatus } from '../../../asset/AssetControlSlice';
import { showToaster } from '../../../dashboard/components/asset-location/AssetList';
import CardsDesign from './CardsDesign';
import { GetAggregation } from '../../../../utilities/CommonFunctions';



const Trends = () => {
  const dispatch = useAppDispatch();
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const { trends, trendsDataSet, trendsFilterData, initialDataLoading, loading } = useAppSelector((state) => state.trends);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: startOfDay(addDays(new Date(), -6)),
    endDate: endOfDay(new Date()),
    key: 'selection',
  });
  const [trendsData, setTrendsData] = useState<TrendsDetailsProps[] | undefined>([]);
  const [filteredCheckedValues, setFilteredCheckedValues] = useState<string[]>([]);
  const [filteredVal, setFilteredVal] = useState<string[]>([]);
  const [prevFilterVal, setPrevFilterVal] = useState<string[]>([]);

  const [noTrendsData, setNoTrendsData] = useState<TrendsDetailsProps[]>([]);
  const assetControlData = useAppSelector((state) => state?.assetControl);
  const [trendsMinMaxValue, setTrendsMinMaxValue] = useState<{
    [key: string]: {
      minValue: number;
      mean: number;
      maxValue: number;
      lastData: number;
      lastDataDate: string;
    };
  }>({});
  const timeSeriesCancelTokenSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null);
  const aggregateValues = useAppSelector((state) => state?.assetDetail?.xspocAggregateValue)

  const currentAggregation = useRef('')

  useEffect(() => {
    if (!trendsFilterData) {
      dispatch(fetchTrendsChartFilterData())
        .unwrap()
        .then((response: any) => {
          const filterSelectedData = response?.map((item: any) => item.name);
          setFilteredVal(filterSelectedData);
          setFilteredCheckedValues(filterSelectedData);
          setPrevFilterVal(filterSelectedData);
        })
        .catch((error) => {
          setFilteredCheckedValues([]);
          setPrevFilterVal([]);
          console.error('Failed to fetch Filter data:', error);
        });
    } else {
      const filterSelectedData = trendsFilterData?.map((item: any) => item.name);
      setFilteredVal(filterSelectedData);
      setFilteredCheckedValues(filterSelectedData);
      setPrevFilterVal(filterSelectedData);
    }
  }, []);

  useEffect(() => {
    prepareNoData(prevFilterVal)
    setTrendsMinMaxValue({})
  }, [JSON.stringify(prevFilterVal)])

  useEffect(() => {
    if (trendsDataSet?.length)
      dispatch(resetTrendsDataSet())

    setTrendsMinMaxValue({})
  }, [selectedDateRange.startDate, selectedDateRange.endDate]);


  useEffect(() => {
    if (timeSeriesCancelTokenSource.current) {
      timeSeriesCancelTokenSource.current.cancel('canceled');
    }

    timeSeriesCancelTokenSource.current = axios.CancelToken.source();

    const wellName = selectedAsset?.selectedAssetName ?? '';

    if (wellName === '') return
    const startDate = new Date(selectedDateRange.startDate.getFullYear(), selectedDateRange.startDate.getMonth(), selectedDateRange.startDate.getDate(), 0, 0, 0, 0).toISOString();
    const endDate = new Date(selectedDateRange.endDate.getFullYear(), selectedDateRange.endDate.getMonth(), selectedDateRange.endDate.getDate(), 23, 59, 59, 999).toISOString();
    const aggregateValue = GetAggregation(new Date(startDate), new Date(endDate), aggregateValues)

    currentAggregation.current = aggregateValue
    dispatch(fetchTrendsById({ wellName: wellName, startDate: startDate, endDate: endDate, aggregate: aggregateValue, granularData: false, cancelToken: timeSeriesCancelTokenSource?.current?.token }))
    if (assetControlData?.assetControlScanStatus) {
      dispatch(updateAssetControlScanStatus(false));
    }
  }, [selectedDateRange.startDate, selectedDateRange.endDate, selectedAsset?.selectedAssetName, assetControlData?.assetControlScanStatus]);


  useEffect(() => {
    setTrendsData(trends || []);
  }, [trends])

  const loggedInUser = useAppSelector((state) => state?.user?.loggedInUser);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser | null = storedUserData ? JSON.parse(storedUserData) : null;
  const initialUserState = loggedInUser || storedUser || undefined;
  const cancelTokenSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null);


  const prepareNoData = (variableArray: string[]) => {
    const noDataArray = variableArray?.map((filteredItem) => (
      {
        "trendName": trendsFilterData.find((item: any) => item.name === filteredItem)?.name || '',
        "unitOfMeasure": trendsFilterData.find((item: any) => item.name === filteredItem)?.shortUnitOfMeasure || '',
        "value": "0",
        "date": null,
        "max": "0",
        "maxThreshold": "0",
        "minThreshold": "0",
        "min": "0",
        "medean": "0",
        "dataPoints": null
      }
    ))
    const sortedData = trendsFilterData?.map((item: any) => noDataArray?.find(trend => trend?.trendName === item?.name)).filter((trend: any) => trend !== undefined) as TrendsDetailsProps[]

    setNoTrendsData(sortedData)
  }


  useEffect(() => {
    const selectedAssetName = selectedAsset?.selectedAssetName ?? '';
    const groupName = selectedAsset?.selectedGroup ?? '';

    if (initialUserState?.id && groupName) {
      cancelTokenSource.current = axios.CancelToken.source();
      dispatch(clearAssetEventSchedule());
      setTimeout(() => {
        dispatch(
          fetchAssetListById({
            userId: initialUserState.id,
            groupName,
            startIndex: 0,
            endIndex: 1,
            searchQuery: selectedAssetName ? '?filter=' + encodeURIComponent(selectedAssetName) : '',
            cancelToken: cancelTokenSource?.current?.token,
          }),
        );
      });
    }

    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel('Component unmounted or route changed.');
      }
    };
  }, [dispatch, selectedAsset?.selectedAssetName, initialUserState?.id]);

  const handleSlideOutClick = (e: any) => {
    if (e.target.id === 'range-calendar-input') return;
    if (showCalendar) {
      setShowCalendar(!showCalendar);
    }
  };

  const isFilterChanged = prevFilterVal?.some((item) => !filteredCheckedValues?.includes(item)) || filteredCheckedValues?.some((item) => !prevFilterVal?.includes(item));

  const handleFilterOutClick = (e: any) => {
    if (e?.target?.id === 'delete-btn') return;
    if (showFilterPopup) {
      setShowFilterPopup(false);
      isFilterChanged && setFilteredCheckedValues([...prevFilterVal])
    }
  };

  const ref = useDetectClickOutside({ onTriggered: handleSlideOutClick });
  const filterRef = useDetectClickOutside({ onTriggered: handleFilterOutClick });

  const handleClearButton = () => {
    setFilteredCheckedValues([]);
  };
  const handleApplyButton = () => {
    if (filteredCheckedValues?.length === 0) {
      showToaster('Please select atleast one filter', 'error');
      return;
    }

    const updatedTrendsData = trends?.filter((trend) => filteredCheckedValues?.includes(trend?.trendName));

    setTrendsData(updatedTrendsData);
    setShowFilterPopup(false);
    setPrevFilterVal(filteredCheckedValues);
  };
  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const checkFilterAvl = filteredCheckedValues?.includes(value);
    if (checkFilterAvl) {
      const updatedFilteredCheckedValues = filteredCheckedValues?.filter((filteredValue) => filteredValue !== value);
      setFilteredCheckedValues(updatedFilteredCheckedValues);
    } else {
      setFilteredCheckedValues((prev) => [...prev, value]);
    }
  };

  const renderdateRange = () => {
    return (
      <div className='trends-container-date-range'>
        <div className='input-group'>
          <img src={calenderIcon} alt='calendar-icon' className='absolute top-3 left-3' />
          <input
            id='range-calendar-input'
            className='date-range-input'
            value={`${formateDate(selectedDateRange.startDate)} - ${formateDate(selectedDateRange.endDate)}`}
            onClick={() => setShowCalendar(!showCalendar)}
          />
        </div>
        <div ref={ref}>
          {showCalendar && (
            <DateRangeSelector
              setShowCalendar={setShowCalendar}
              setSelectedDateRange={setSelectedDateRange}
              staticRanges={defaultStaticRanges}
              minDate={defineds?.startOfLastYear}
              maxDate={defineds?.endOfToday}
              selectedDateRange={selectedDateRange}
            />
          )}
        </div>

        <div style={{ position: 'relative', zIndex: '8' }}>
          <div
            id='filter-btn'
            style={{
              border: showFilterPopup
                ? '1px solid var(--Dark-mode-400, #4A5463)'
                : '1px solid var(--Dark-mode-400, #4A5463)',
              background: showFilterPopup ? 'var(--Dark-mode-800, #001023)' : 'inherit',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.stopPropagation();
              setShowFilterPopup(!showFilterPopup);
            }}
          >
            <img src={filterIcon} alt='filterIcon' />
            Filter {`(${filteredCheckedValues?.length})`}
            <img src={downIcon} className={!showFilterPopup ? '' : 'rotate-180'} alt='upIcon' />
          </div>

          {showFilterPopup && (
            <div className='trends-filter-modal-container' ref={filterRef}>
              <div className='trends-filter-modal-header'>Filter asset telemetry</div>

              <div className='trends-filter-modal-body'>
                {filteredVal?.map((trend) => {
                  return (
                    <>
                      <div className='checkbox-main-container'>
                        <label className='checkbox-container'>
                          <input
                            type='checkbox'
                            id='showCheckbox'
                            checked={filteredCheckedValues?.includes(trend)}
                            onChange={handleCheckbox}
                            value={trend}
                          />
                          <span className='checkbox-checkmark'></span>
                          <span className='inline-block pl-[29px]'>{trend}&nbsp;</span>
                        </label>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className='trends-filter-modal-footer'>
                <button className={'footer-btn'} onClick={handleClearButton}>
                  Clear
                </button>
                <button className={'footer-btn'} onClick={handleApplyButton}>
                  Apply
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const findTrendsData = (trendName: any) => {
    const data = trendsData?.find((item) => {
      return item?.trendName?.toLowerCase() === trendName?.toLowerCase();
    });
    return data;
  };

  return (
    <>
      <div
        className={
          showCalendar || showFilterPopup ? 'trends-container trends-calender-filter-open' : 'trends-container'
        }
        style={{ position: 'relative' }}
      >
        {renderdateRange()}
        {loading ? (
          <div className='trend-loader flex items-center justify-center w-100'>
            <Loader />
          </div>
        ) : (
          <>
            {noTrendsData?.map((item, index) => {
              const isDataAvl = findTrendsData(item?.trendName);
              if (isDataAvl) {
                return (
                  <CardsDesign
                    key={`${isDataAvl?.date}-${index}`}
                    trend={isDataAvl}
                    index={index}
                    selectedDate={{ startDate: selectedDateRange.startDate, endDate: selectedDateRange.endDate }}
                    loadingIcon={initialDataLoading}
                    trendsMinMaxValue={trendsMinMaxValue}
                    setTrendsMinMaxValue={setTrendsMinMaxValue}
                    currentAggregateValue={currentAggregation.current}
                    filteredValues={prevFilterVal}
                  />
                );
              }
              return (
                <CardsDesign
                  key={`${item?.date}-${index}`}
                  trend={item}
                  index={index}
                  selectedDate={{ startDate: selectedDateRange.startDate, endDate: selectedDateRange.endDate }}
                  loadingIcon={initialDataLoading}
                  trendsMinMaxValue={trendsMinMaxValue}
                  setTrendsMinMaxValue={setTrendsMinMaxValue}
                  currentAggregateValue={currentAggregation.current}
                  filteredValues={prevFilterVal}
                />
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default Trends;
