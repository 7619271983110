import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TempServices from "../services/temp.services";


const initialState = {
    xspocTemperatureData: [],
    sleTemperatureData: [],
    loading: false,
    error: '',
}

export const fetchXspocTempData = createAsyncThunk('getXspocTempData', async ({ assetName, channelIds }: { assetName: string, channelIds: string }) => {
   return await TempServices.getXspocTemperatureData(assetName,channelIds);
})

export const fetchSleTempData = createAsyncThunk('fetchSleTempData', async ({ assetName, variables }: { assetName: string, variables: string }) => {
    return await TempServices.getSleTemperatureData(assetName,variables);
})
 

const TemperatureChartSlice = createSlice({
    name: 'TemperatureChartData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // fetch xspoc SpeedChart thunk cases
        builder.addCase(fetchXspocTempData.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchXspocTempData.fulfilled, (state, action) => {
            state.xspocTemperatureData = action.payload.data;
            state.loading = false;
        })
        builder.addCase(fetchXspocTempData.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        })
        builder.addCase(fetchSleTempData.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchSleTempData.fulfilled, (state, action) => {
            state.sleTemperatureData = action.payload.data;
            state.loading = false;
        })
        builder.addCase(fetchSleTempData.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        })
    }
});

export default TemperatureChartSlice.reducer;