import React from 'react';
import ErrorIcon from '../../../images/error-icon.png'
import successIcon from '../../../images/success.png'


interface AlertModelProps {
  isValidAction: boolean;
  isModalOk: () => void;
  setScheduleServiceStatuses: any;
}

const ActionAlertModel: React.FC<AlertModelProps> = ({ isValidAction, isModalOk, setScheduleServiceStatuses }) => {
  return (
    <>
      {isValidAction && (
        <div className='modal-overlay delete-modal-page document-delete'>
          <div className='modal'>
            <div className='flex flex-row flex-grow profile-block'>
              <div className='notify-card'>
                <div className='header-delete'>
                  <div className='feature-img w-[70px]'>
                    <img src={setScheduleServiceStatuses?.status ? successIcon : ErrorIcon} alt='' />
                  </div>
                  <p className='title'>
                    Schedule service status
                  </p>
                  <p className='text-lg mt-4'>
                    {
                      setScheduleServiceStatuses?.status ? `Running,  Schedule count : ${setScheduleServiceStatuses?.schedules?.length}` : 'Stopped'
                    }
                  </p>
                </div>
                <div className='delete-modal__footer'>
                  <button onClick={isModalOk} className='btn btn-primary'>
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ActionAlertModel;
