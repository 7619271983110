import { apiInstance } from "../../config/HttpCommon";

export const downloadAssetsStatusCSV = async () => {
    const response = await apiInstance.get(`/v1/support/download-assets-status-csv`, { responseType: 'blob' });
    return response.data;
}

export const getLookups = async () => {
    const response = await apiInstance.get(`/v1/lookups`);
    return response.data;
}

export const updateLookupsById = async (id: string, payload: any) => {
    const response = await apiInstance.put(`/v1/lookups/${id}`, payload);
    return response.data;
}

export const clearServerCacheAction = async () => {
    const response = await apiInstance.get(`/v1/support/clear-server-cache`);
    return response.data;
}

export const scheduleServiceStatusAction = async () => {
    const response = await apiInstance.get(`/v1/support/schedule-service-status`);
    return response.data;
}