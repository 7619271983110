import { apiInstance, http } from '../../../../config/HttpCommon';
import { SimpleSpeedChange } from './model/SimpleSpeedChange';
import { SteppedSpeedChange } from './model/SteppedSpeedChange';

export const getScheduleById = async (wellId: string, deviceId: string, pageNumber: number, pageSize: number, onlyStep = false) => {
  const response = await apiInstance.get(`/v1/schedule-speed-changes?WellId=${wellId}&DeviceId=${deviceId}&PageNumber=${pageNumber}&PageSize=${pageSize}&OnlyStep=${onlyStep}`);
  return response.data;
};

export const GetFreqSetPointAddress = async (wellName: string) => {
  const response = await http.get(`/GetFreqSetPointAddress/${encodeURIComponent(wellName)}`);
  return response.data;
}


export const cancelScheduleById = async (id: string) => {
  const response = await apiInstance.delete(`/v1/schedule-speed-changes/${id}`);
  return response.data;
};

export const createScheduleById = async (scheduleData: {
  wellId: string;
  groupName: string;
  deviceId: string;
  setpointFrequencyAddress: string;
  simpleSpeedChanges: SimpleSpeedChange[];
  steppedSpeedChanges: SteppedSpeedChange[];
}) => {
  try {
    const response = await apiInstance.post('/v1/schedule-speed-changes/', scheduleData);
    return response.data;
  } catch (error) {
    console.error('Error creating schedule data :', error);
    throw error;
  }
};

export const updateScheduleById = async (
  id: string,
  speedChangeData: {
    simpleSpeedChanges: SimpleSpeedChange[];
    steppedSpeedChanges: SteppedSpeedChange[];
  },
) => {
  const response = await apiInstance.put(`/v1/schedule-speed-changes/${id}`, speedChangeData);
  return response.data;
};
