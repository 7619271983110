// Modal.js
import React from 'react';
import './Modal.scss';
import Close from '../../../../../src/images/x-close.svg';
import EquipmentImage from '../../../../../src/images/equipment-image1.png';
import infocircleIcon from '../../../../../src/images/info-circle.svg';
import CustomModal from '../../../dashboard/components/graph/ExpandModel';
import CapacityCurveGraph2 from '../../../asset-details/components/analysis/graphs/CapacityCurveGraph2';

type ModalProps = {
    isOpen: boolean;
    onRequestClose: () => void;
    description: string;
    modelWidth?: string; 
  };

  const Modal: React.FC<ModalProps> = ({ isOpen, onRequestClose, description, modelWidth }) => {
  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose} width={modelWidth}>
        <div className='equipment-modal'>
        <div className='header-modal'>
      <div className='flex'>
        <div className='grow'>
          <h3 className='header-title'>{description}</h3>
        </div>
        <div className='flex-none'>
          <button onClick={onRequestClose}>
            <img src={Close} alt='Expand' />
          </button>
        </div>
      </div>
    </div>
    <div className='divider border-line'></div>
    <div className='modal-content equipment-modal'>
    <div className="content-left">
        <div className='equipment-image-section'>
        <div className='img-heading'>High-rise series pumps</div>
        <div className='divider border-line'></div>
        <img src={EquipmentImage} alt='EquipmentImage' />
        </div>
        <div className='diagram-description'>
        <img src={infocircleIcon} alt='info-icon' />
        <span>Diagram based on UNBRIDLED ESP Systems</span></div>
       </div>
    <div className="content-right">
        <div className='curv-graph'>
        <div className='curv-heading'>Variable speed curve</div>
        <CapacityCurveGraph2 />
        </div>
    </div>
    </div>
        </div>
  </CustomModal>
  );
};

export default Modal;
