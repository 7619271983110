import React, { useEffect, useState, useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import annotation_plus from './../../../../images/expand-icon.svg';
import download from '../../../../../src/images/download-icon.svg';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoData from '../../../dashboard/components/no-data/NoData';
//import ToggleSwitch from '../../../common/ToggleSwitch/ToggleSwitch';
import ProdChart from './charts/ProdChart';
import { ProductionDetailsProps, WellProductionData } from './interfaces/prod.interfaces';
import ProdModal from './modals/ProdModal';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { fetchProductionTrendsByWell, getChartLoadingStateByName } from '../../wellControlroomSlice';
import { AppUser } from '../../../user/model/AppUser';
import championx from '../../../../../src/images/championxLogo.png';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import moment from 'moment-timezone';

const CHART_NAME = 'production';

const ProductionDetails: React.FC<ProductionDetailsProps> = ({ heading }) => {
    const getLast7DaysDate = () => {
        const today = new Date();
        const last7Days = new Date(today);
        last7Days.setDate(today.getDate() - 6);
        return last7Days;
    };

    //const [isToggleActive, setIsToggleActive] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const productionTrends = useAppSelector((state) => state.wellControlroomCharts.productionTrends.wellProductionTrend);
    const loading = useAppSelector(getChartLoadingStateByName(CHART_NAME));
    const [data, setData] = useState<WellProductionData>(productionTrends);
    const userPrefrence = useAppSelector((state) => state.controlroom.userPrefrence)?.find(item => item.key === CHART_NAME);
    const currentUser = useAppSelector((state) => state?.user?.currentUser);
    //const [detailsData, setDetailsData] = useState<WellData[]>([]);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const cardChartRef = useRef<HTMLDivElement | null>(null);
    const modalChartRef = useRef<HTMLDivElement | null>(null);
    const root = useRef<any>(null);
    const root2 = useRef<any>(null);
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: getLast7DaysDate(),
        endDate: new Date(),
        key: 'selection',
    });
    //const storedUserData = localStorage.getItem('loggedInUser');
    //const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
    const hElement = document.getElementById('main-root');
    const dispatch = useAppDispatch();
    const storedUserData = localStorage.getItem('loggedInUser');
    const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
    const selectedWell = JSON.parse(sessionStorage.getItem('selectedWellName') || '{}');

    useEffect(() => {
        dispatch(fetchProductionTrendsByWell({
            userId: storedUser?.id,
            startDate: (selectedDateRange.startDate || new Date()).toISOString(),
            endDate: (selectedDateRange.endDate || new Date()).toISOString(),
            assetName: selectedWell?.assetName
        }));
    }, [dispatch, storedUser?.id, selectedWell?.assetName, selectedDateRange.startDate, selectedDateRange.endDate]);

    /* const handleToggleChange = (event: any) => {
        event.stopPropagation
        setIsToggleActive(!isToggleActive)
    } */

    const openModal = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setModalIsOpen(true);
        hElement && hElement?.classList.add('modal-open');
    };

    const handleClose = () => {
        setModalIsOpen(false);
        hElement && hElement?.classList.remove('modal-open');
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);




    //   function setToStartOfDay(date: any) {
    //     return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    //   }
    //   function setToEndOfDay(date: any) {
    //     return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
    //   }
    // Ensure selectedDateRange.startDate is at the start of the day
    //   const startDate = setToStartOfDay(selectedDateRange.startDate);
    //   // Ensure selectedDateRange.endDate is at the end of the day
    //   const endDate = setToEndOfDay(selectedDateRange.endDate);

    const fetchData = async () => {
        const transformedData: WellProductionData = {
            gasData: productionTrends.gasData.map((item) => ({
                date: new Date(item.date).getTime(),
                value: item.value,
            })),
            oilData: productionTrends.oilData.map((item) => ({
                date: new Date(item.date).getTime(),
                value: item.value,
            })),
            waterData: productionTrends.waterData.map((item) => ({
                date: new Date(item.date).getTime(),
                value: item.value,
            })),
        };
        setData(transformedData);
    }

    useEffect(() => {
        fetchData();
    }, [selectedDateRange.startDate, selectedDateRange.endDate, productionTrends]);

    const printWidgetAsPDF = () => {
        if (root.current) {
          const exporting = am5plugins_exporting.Exporting.new(root.current, {
            menu: am5plugins_exporting.ExportingMenu.new(root.current, {}),
          });
          Promise.all([exporting.getPdfmake(),
          exporting.export('png')]).then((res) => {
            const pdfMake = res[0];
            const str = moment().format('MMM-DD-YYYY hh:mm:ss A');
            const doc = {
              pageSize: 'A4',
              pageOrientation: 'portrait',
              pageMargins: [30, 30, 30, 30],
              footer: {
                columns: [
                  {
                    text: `Generated By: ${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`,
                    alignment: 'left',
                    width: '50%',
                    bold: true,
                    margin: [10, 10],
                    fontSize: 9,
                    color: 'black',
                    decoration: 'underline',
                  },
                  {
                    text: 'Generated on : ' + str,
                    alignment: 'right',
                    width: '50%',
                    bold: true,
                    margin: [10, 10],
                    fontSize: 9,
                    color: 'black',
                    decoration: 'underline',
                  },
                ],
              },
              content: [] as any[],
              styles: {
                tblheader: {
                  fontSize: 9.5,
                  color: 'white',
                  fillColor: '#01485e',
                  alignment: 'center',
                },
              },
            };
    
            const rect = {
              type: 'rect',
              x: -32,
              y: -20, //could be negative to account for padding
              w: 450,
              h: 25,
              linearGradient: ['#01485e', '#01485e'],
            };
            const rectHead = {
              type: 'rect',
              x: -32,
              y: -20, //could be negative to account for padding
              w: 150,
              h: 25,
              linearGradient: ['#484848', '#484848'],
            };
            doc.content.push({
              columns: [
                {
                  stack: [
                    { canvas: [rect] },
                    {
                      columns: [
                        {
                          width: rect.w / 2,
                          noWrap: false, //clip at with by wrapping to next line and...
                          maxHeight: rect.h, //don't show the wrapped text
                          image: championx,
                          fit: [55, 55],
                          margin: [5.5, 7.5, 3],
                          background: 'white',
                        },
                        {
                          text: 'Landing Page',
                          width: rect.w / 2,
                          alignment: 'left',
                          bold: true,
                          margin: [35, 5],
                          fontSize: 13,
                          color: 'white',
                        },
                      ],
                      relativePosition: {
                        x: rect.x,
                        y: -rect.h,
                      },
                    },
                  ],
                },
                {
                  stack: [
                    { canvas: [rectHead] },
                    {
                      columns: [
                        {
                          width: rectHead.w,
                          noWrap: false, //clip at with by wrapping to next line and...
                          maxHeight: rect.h, //don't show the wrapped text
                          text: 'LOOKOUT',
                          color: 'white',
                          bold: true,
                          alignment: 'right',
                          'font-size': 10,
                        },
                      ],
                      relativePosition: {
                        x: -80,
                        y: -20,
                      },
                    },
                  ],
                },
              ],
            });
            doc.content.push({
              text: ' ',
              width: '100%',
            });
    
            doc.content.push(
              {
                text: 'Production',
                width: '*',
                alignment: 'center',
                fontSize: 18,
                margin: [0, 0, 0, 5],
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 175,
                    y1: 0,
                    x2: 362,
                    y2: 0,
                    lineWidth: 1,
                  },
                ],
                margin: [0, 0, 0, 15],
              },
            );
    
            doc.content.push({
              image: res[1],
              alignment: 'center',
              width: 500,
              height: 150,
              margin: [0, 0, 0, 20],
            });
    
            const fileName = 'Lookout-production_' + str + '.pdf';
    
            pdfMake.createPdf(doc).download(fileName);
          }).finally(() => {
            setIsDropdownOpen(false);
    
          });
        }
      };


    return (
        <>
            <ToastContainer position='top-right' autoClose={3000} />
            <div className='grid-item-text h-full graph-card well-graph-card'>
                <div className='table-header-section pie-section'>
                    <div className='title'>{heading}</div>
                    {userPrefrence?.expandable && <div className='header-icon'>
                        <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
                            <img src={annotation_plus} alt='Expand' className='img-border' />
                        </button>
                    </div>}
                    {userPrefrence?.actions.show && <div className='mr-4 dropdown-container' ref={dropdownRef}>
                        <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                            <span className='dot'></span>
                            <span className='dot'></span>
                            <span className='dot'></span>
                        </button>
                        {isDropdownOpen && (
                            <div className='dropdown-options'>
                                <button>Actions</button>
                                <button className={`btn btn-default cancelSelectorName`} onClick={printWidgetAsPDF}>
                                    <img src={download} alt='icon' className='btn-icon' />
                                    Download PDF
                                </button>
                                <button className={`btn btn-default cancelSelectorName`}>
                                    <img src={download} alt='icon' className='btn-icon' />
                                    Download CSV
                                </button>
                            </div>
                        )}
                    </div>}
                </div>
                <hr />
                <div id="wellProductionTrend-container" className='wellChartSection wellProductionTrendsChartSection'>
                    {loading ? (
                        // <div className='well-production-loader'>
                        <Loader />
                        // </div> 
                    ) : data?.gasData?.length > 0 || data?.oilData?.length > 0 || data?.waterData?.length > 0 ? (
                        <>
                            {/*  <div className='switch-block'>
                                <ToggleSwitch checked={isToggleActive} onChange={handleToggleChange} label={'Virtual flow meter'} />
                            </div> */}
                            <ProdChart chartID={'wellProductionTrendsChartdiv'} chartRef={cardChartRef} data={data} root={root} />
                        </>
                    ) : (
                        <NoData heading='No data found' />
                    )}
                </div>
            </div>
            <ProdModal
                isOpen={modalIsOpen}
                onRequestClose={handleClose}
                heading={'Production'}
                loading={loading}
                modelWidth={'75rem'}
                modelClass={'wellChartModal'}
                selectedDateRange={selectedDateRange}
                setSelectedDateRange={setSelectedDateRange}
                innerComponent={
                    <>
                        {
                            loading ? (
                                <Loader />
                            ) : data?.gasData?.length > 0 || data?.oilData?.length > 0 || data?.waterData?.length > 0 ?



                                <ProdChart chartID={'wellProductionTrendsChartdiv'} chartRef={modalChartRef} data={data} root={root2}
                                /> : (
                                    <NoData heading='No data found' />
                                )}
                    </>
                }
            />
        </>
    );
};

export default ProductionDetails;