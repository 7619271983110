import React, { useEffect, useRef } from 'react'
import ProgressBar from './ProgressBar'
import InfoCircle from '../../../images/info-circle.svg'
import ClickEventToolTip from '../../common/tooltip/ClickEventToolTip'
import { HealthScorebandsData } from '../constants/healthScore.constants'
import HealthChart from './HealthChart'
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks'
import { fetchHealthScore, fetchHealthScoreTarget } from '../HealthScoreSlice'
import Loader from '../../common/page-loader/ComponentLoader';


const HealthScoreChart = () => {
    const dispatch = useAppDispatch()
    const { healthScoreLoading, healthScoreTargetLoading, healthScore, healthScoreTarget } = useAppSelector((state) => state.healthScore)
    const healthScoreChartdiv = useRef<HTMLDivElement | null>(null);
    const selectedAsset = useAppSelector((state) => state.assetGroups);
    const selectedWellName = sessionStorage.getItem('selectedWellName');
    const assetName = selectedAsset.selectedAssetName ?? (selectedWellName && JSON.parse(selectedWellName || ''))

    useEffect(() => {
        if (assetName) {
            dispatch(fetchHealthScore(assetName))
            dispatch(fetchHealthScoreTarget(assetName))
        }
    }, [])


    return (
        <div className='health-score-chart-container card m-0 flex flex-row'>
            {
                (healthScoreTargetLoading || healthScoreLoading) ?
                    < div className={`flex items-center justify-content w-full`}>
                        <Loader />
                    </ div>
                    :
                    <>
                        <div className='health-score-chart flex justify-center items-center'>


                            <HealthChart chartID={'healthScoreChartdiv'}
                                chartRef={healthScoreChartdiv} classValue={'w-[70%]'}
                                chartName={'RPM'}
                                minValue={0} maxValue={100} startAngle={-230} endAngle={50}
                                innerCircleEndvalue={100} bandsData={HealthScorebandsData} spacingValue={4}
                                value={healthScore?.healthScore || 0}
                            />
                        </div>

                        <div className='progress-bar-container'>

                            <div className='header1'>Health targets</div>
                            <>
                                {
                                    healthScoreTarget?.healthTargets?.map((healthTarget, index) => {
                                        return (
                                            <div className='progress-div' key={index}>
                                                <div className='flex justify-between items-center flex-row'>
                                                    <span className='variable'>{healthTarget.name}</span>
                                                    <span className='percentage'>{`${healthTarget.value}%`}</span>
                                                </div>
                                                <ProgressBar progress={healthTarget.value} />

                                            </div>
                                        )
                                    })
                                }
                            </>
                        </div>

                        <div className='info-button flex items-start flex-column'>
                            <ClickEventToolTip content='The health score is comprised of the most impactful positive variable, most impactful negative variable and a baseline variable' direction='bottom'>
                                <button className='button flex justify-center items-center'>
                                    <img src={InfoCircle} />
                                </button>
                            </ClickEventToolTip>
                        </div>
                    </>
            }

        </div>
    )
}

export default HealthScoreChart