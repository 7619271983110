import { useEffect } from "react";
import { ChangedBackupValue, fetchCurrentFreqValue, fetchDeviceValue, handleReadCurrentFreqReturnError, handleReadCurrentFreqReturnSuccess, handleReadDeviceReturnError, handleReadDeviceReturnSuccess, updateSetpointValues } from "../features/asset-details/components/setpoints/SetPointsSlice";
import { executeWellControlAction, setExecuteControlActionOnReturnError, setExecuteControlActionOnReturnSuccessful, updateActiveAssetControlAssetId, updateAssetControlScanStatus, updateAssetStatus } from "../features/asset/AssetControlSlice";
import { getWebSocketURI } from "../utilities/BaseURLUtility";
import { useAppDispatch, useAppSelector } from "./storeHooks";
import { WebSocketActionType } from "../features/asset-details/components/asset-details-header/AssetControlActions";
import { WellControlAction } from "../features/navigation/components/ControlAction";





export enum Action {
    Unknown = 0,
    ProcessDeviceScan = 1,
    StartWell = 2,
    StopWell = 3,
    IdleWell = 4,
    ClearAlarms = 5,
    CommunicationLog = 18,
    ConstantRunMode = 6,
    PocMode = 7,
    PercentTimerMode = 8,
    SetClock = 9,
    UpdatePocData = 10,
    GetCard = 11,
    DownloadEquipment = 12,
    UploadEquipment = 13,
    EnablePid = 14,
    DisablePid = 15,
    GetData = 16,
    GetCardDirect = 17,
    CaptureRegisterLogs = 19,
    OnOffCycles = 20,
    StartPortLogging = 21,
    StopPortLogging = 22,
}

export const useWebSocket = ({ webSocketActionType, type, label, selectedSetPointAddress, requestId }: { webSocketActionType: WebSocketActionType | undefined; type?: string; label?: string; selectedSetPointAddress?: string, requestId: string }) => {
    const dispatch = useAppDispatch()
    const selectedAssetName = useAppSelector((state) => state?.assetGroups?.selectedAssetName)
    const selectedAssetId = useAppSelector((state) => state?.assetGroups?.selectedAssetId)
    const setPointData = useAppSelector((state) => state.setPoint)
    const scheduleSpeedChangeData = useAppSelector((state) => state.scheduleSpeedChange)
    const assetControlData = useAppSelector((state) => state?.assetControl)
    const timeOutLimit = assetControlData.webSocketTimoutValue ? Number(assetControlData.webSocketTimoutValue) * 1000 : 60000  //60 sec in milli seconds

    useEffect(() => {
        if (webSocketActionType === WebSocketActionType.AssetControl) {
            if (type && label)
                establishWebSocketConnection({ webSocketActionType: webSocketActionType, type: type, label: label })
        } else if (webSocketActionType === WebSocketActionType.ReadCurrentFrequency && scheduleSpeedChangeData.currentFreqAddress) {
            establishWebSocketConnection({ webSocketActionType: webSocketActionType, selectedSetPointAddress: scheduleSpeedChangeData.currentFreqAddress })
        } else if (webSocketActionType === WebSocketActionType.ReadDevice && selectedSetPointAddress) {
            establishWebSocketConnection({ webSocketActionType: webSocketActionType, selectedSetPointAddress: selectedSetPointAddress })
        } else if (webSocketActionType === WebSocketActionType.UpdateDevice && setPointData.changedBackupValues) {
            establishWebSocketConnection({ webSocketActionType: webSocketActionType, changedBackupValues: setPointData.changedBackupValues })
        }

    }, [webSocketActionType, type, label, selectedSetPointAddress, requestId])

    const establishWebSocketConnection = ({ webSocketActionType, type, label, selectedSetPointAddress, changedBackupValues }: { webSocketActionType: WebSocketActionType; type?: string; label?: string; selectedSetPointAddress?: string; changedBackupValues?: ChangedBackupValue | null }) => {
        const wellNameAtTheTimeOfConnection = selectedAssetName

        dispatch(updateActiveAssetControlAssetId(selectedAssetId))
        const webSocketURI = getWebSocketURI('wellcontrolws');
        const socket = new WebSocket(webSocketURI);
        // Connection opened
        socket.addEventListener('open', () => {
            console.log("Connection established")

        });
        socket.addEventListener('close', (event) => {
            console.log(`well control WebSocket closed with code: ${event.code}, reason: ${event.reason}, state: ${socket.readyState}`);
        });
        // Listen for messages
        let timer: ReturnType<typeof setTimeout>;
        socket.addEventListener('message', async (event) => {
            const { store } = await import('../store');

            const latestAssetName = store.getState().assetGroups?.selectedAssetName;
            let isConnectionForCurrentWell = latestAssetName === wellNameAtTheTimeOfConnection
            if (timer) {
                clearTimeout(timer);
            }

            timer = setTimeout(() => {
                if (socket.readyState === WebSocket.OPEN) {
                    const latestAsset = store.getState().assetGroups?.selectedAssetName;
                    const setPointSliceData = store.getState().setPoint;
                    const assetControlSliceData = store.getState().assetControl;

                    socket.send(JSON.stringify({ action: 'close' }));  // Notify the server
                    socket.close()
                    isConnectionForCurrentWell = latestAsset === wellNameAtTheTimeOfConnection
                    if (webSocketActionType === WebSocketActionType.ReadCurrentFrequency) {
                        if (isConnectionForCurrentWell && setPointSliceData.readCurrentFreqLoading)
                            dispatch(handleReadCurrentFreqReturnError())
                    } else if (webSocketActionType === WebSocketActionType.ReadDevice || webSocketActionType === WebSocketActionType.UpdateDevice) {
                        if (isConnectionForCurrentWell && setPointSliceData.readDeviceLoading)
                            dispatch(handleReadDeviceReturnError())
                    } else if (webSocketActionType === WebSocketActionType.AssetControl) {

                        if (assetControlSliceData.executeControlScanActionLoading || assetControlSliceData.startActionProgressStatus || assetControlSliceData.stopActionProgressStatus || assetControlSliceData.clearAlarmActionProgressStatus)
                            dispatch(
                                setExecuteControlActionOnReturnError({
                                    message: getTimeoutErrorToastMessage(Number(type), wellNameAtTheTimeOfConnection),
                                    data: type,
                                    isConnectionForCurrentWell: isConnectionForCurrentWell
                                }),
                            )
                    }
                }
            }, timeOutLimit)

            if (event.data.startsWith('socketId:')) {
                const socketId = event.data.split(':')[1]
                let errorResponse = null
                if (webSocketActionType === WebSocketActionType.AssetControl) {
                    dispatch(executeWellControlAction({
                        assetId: selectedAssetId,
                        socketId: socketId,
                        controlType: type || '',
                        controlActionName: label || '',
                        wellName: selectedAssetName,
                    }))
                        .catch((error) => {
                            errorResponse = error
                        });
                } else if (webSocketActionType === WebSocketActionType.ReadCurrentFrequency && selectedSetPointAddress) {
                    dispatch(fetchCurrentFreqValue({ wellId: selectedAssetId, setPointAddresses: selectedSetPointAddress, socketId: socketId }))
                        .catch((error) => {
                            errorResponse = error
                        });
                } else if (webSocketActionType === WebSocketActionType.ReadDevice && selectedSetPointAddress) {
                    dispatch(fetchDeviceValue({ wellId: selectedAssetId, setPointAddresses: selectedSetPointAddress, socketId: socketId }))
                        .catch((error) => {
                            errorResponse = error
                        });
                } else if (webSocketActionType === WebSocketActionType.UpdateDevice && changedBackupValues) {
                    dispatch(updateSetpointValues({ wellId: selectedAssetId, setpointValues: changedBackupValues, socketId: socketId }))
                        .catch((error) => {
                            errorResponse = error
                        });
                }
                console.log("error", errorResponse)
            } else if (event.data === 'Close') {
                socket.close();
            } else {
                isConnectionForCurrentWell = latestAssetName === wellNameAtTheTimeOfConnection
                const parsedData = JSON.parse(event.data);
                if (parsedData.Status == 'Success') {
                    const payloadData = JSON.parse(parsedData.Payload);
                    if (payloadData && payloadData?.Value) {
                        const payloadDetails = JSON.parse(payloadData?.Value?.Payload);
                        const nodeId = payloadDetails?.NodeId || payloadDetails?.DeviceScanData?.NodeId;
                        dispatch(
                            setExecuteControlActionOnReturnSuccessful({
                                message: getUpdatedToastMessage(Number(payloadData.Value?.ActionType), nodeId),
                                data: parsedData.Payload,
                                isConnectionForCurrentWell: isConnectionForCurrentWell,
                            }),
                        );
                        if (isConnectionForCurrentWell) {
                            if (Number(payloadData.Value?.ActionType) === Action.ProcessDeviceScan) {
                                dispatch(updateAssetControlScanStatus(true))
                            } else if (Number(payloadData.Value?.ActionType) === Action.StartWell) {
                                dispatch(updateAssetStatus('Running'))
                            } else if (Number(payloadData.Value?.ActionType) === Action.StopWell) {
                                dispatch(updateAssetStatus('Shutdown'))
                            }
                        }
                    } else {
                        //for read device and read current freq value
                        //if the socket connection is for current selected well
                        if (isConnectionForCurrentWell) {

                            const payload = JSON.parse(parsedData.Payload)
                            // if (payload) {
                            //     const valuesArray: number[] = payload["3"];
                            //     dispatch(handleReadCurrentFreqReturnSuccess(valuesArray[0]))
                            // }
                            if (payload) {
                                const addressArray: number[] = payload["1"];
                                const valuesArray: number[] = payload["3"];
                                let newArray: { address: number, value: number }[] = []
                                // Check if both arrays have the same length
                                if (addressArray.length === valuesArray.length) {
                                    // Create a new array with key-value pairs
                                    newArray = addressArray.map((key, index) => ({
                                        address: key,
                                        value: valuesArray[index]
                                    }));
                                }

                                if (webSocketActionType === WebSocketActionType.ReadCurrentFrequency) {
                                    dispatch(handleReadCurrentFreqReturnSuccess(valuesArray[0]))
                                } else {
                                    dispatch(handleReadDeviceReturnSuccess(newArray))
                                }
                            }
                        }
                    }
                } else {
                    if (parsedData.StatusMessage) {
                        if (webSocketActionType === WebSocketActionType.ReadCurrentFrequency) {
                            if (isConnectionForCurrentWell)
                                dispatch(handleReadCurrentFreqReturnError())
                        } else if (webSocketActionType === WebSocketActionType.ReadDevice || webSocketActionType === WebSocketActionType.UpdateDevice) {
                            if (isConnectionForCurrentWell)
                                dispatch(handleReadDeviceReturnError())
                        } else if (webSocketActionType === WebSocketActionType.AssetControl) {
                            dispatch(
                                setExecuteControlActionOnReturnError({
                                    message: getErrorToastMessage(Number(type), wellNameAtTheTimeOfConnection),
                                    data: type,
                                    isConnectionForCurrentWell: isConnectionForCurrentWell,
                                }),
                            )
                        }
                    }
                }
                if (socket.readyState === WebSocket.OPEN) {
                    socket.send(JSON.stringify({ action: 'close' }));  // Notify the server
                    socket.close()
                }
                clearTimeout(timer)
            }
        });
    };

    const getUpdatedToastMessage = (controlActionType: number, wellName = 'well') => {
        let returnMessage = '';
        switch (controlActionType) {
            case Action.StartWell:
                returnMessage = `${wellName} successfully started`;
                break;
            case Action.StopWell:
                returnMessage = `${wellName} successfully shutdown`;
                break;
            case Action.ClearAlarms:
                returnMessage = `Alarms successfully cleared for ${wellName}`;
                break;
            case Action.ProcessDeviceScan:
                returnMessage = `Scan is completed for ${wellName}`;
                break;

            default:
                returnMessage = `Transaction updated successfully for ${wellName ?? 'well'}`;
        }
        return returnMessage;
    };

    const getErrorToastMessage = (controlActionType: number, wellName = 'well') => {
        let returnMessage = '';
        switch (controlActionType) {
            case WellControlAction.Start:
                returnMessage = `The transaction failed to start ${wellName}`;
                break;
            case WellControlAction.Stop:
                returnMessage = `The transaction failed to stop ${wellName}`;
                break;
            case WellControlAction.ClearAlarms:
                returnMessage = `The transaction failed to clear alarms for ${wellName}`;
                break;
            case WellControlAction.Scan:
                returnMessage = `The transaction failed to scan ${wellName}`;
                break;

            default:
                returnMessage = `Transaction failed for ${wellName ?? 'well'}`;
        }
        return returnMessage;
    };

    const getTimeoutErrorToastMessage = (controlActionType: number, wellName = 'well') => {
        let returnMessage = '';
        switch (controlActionType) {
            case WellControlAction.Start:
                returnMessage = `Comms timeout to start ${wellName}`;
                break;
            case WellControlAction.Stop:
                returnMessage = `Comms timeout to stop ${wellName}`;
                break;
            case WellControlAction.ClearAlarms:
                returnMessage = `Comms timeout to clear alarms for ${wellName}`;
                break;
            case WellControlAction.Scan:
                returnMessage = `Comms timeout to scan ${wellName}`;
                break;

            default:
                returnMessage = `Comms timeout for ${wellName ?? 'well'}`;
        }
        return returnMessage;
    };



}
