import React from 'react';
// import '../user-profile/User.scss';
import '../User.scss';
import { useAppSelector } from '../../../../hooks/storeHooks';
import Loader from '../../../common/page-loader/ComponentLoader';

interface SearchTerm {
  selectedLookupId: string;
  setSelectedLookupId: React.Dispatch<React.SetStateAction<string | null>>;
}

const LookupsList: React.FC<SearchTerm> = ({ selectedLookupId, setSelectedLookupId }) => {
  const lookups = useAppSelector((state) => state.SupportSlice.lookups);
  const lookupsLoading = useAppSelector((state) => state.SupportSlice.lookupsLoading);


  return (
    <>
      <div className='user-checklist'>
        <ul>
          {lookupsLoading ? (
            <div className='flex items-center h-full'>
              <Loader />
            </div>
          ) : lookups?.length ? (
            lookups.map((item: any, index: any) => (
              <li
                className={`${item.id === selectedLookupId ? 'active-list' : ''}`}
                key={index}
                onClick={() => setSelectedLookupId(item.id)}
              >
                <div className='info'>
                  <div className='namedata'>{item?.key}</div>
                  <div className='companyname'>{item?.description}</div>
                </div>
              </li>
            ))
          ) : (
            <li>
              <div className='notfound'>No data found.</div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};
export default LookupsList;
