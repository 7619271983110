
import signal from '../../../../images/signal-01.svg';
import playCircle from '../../../../images/play-circle.svg';
import stopCircle from '../../../../images/stop-circle.svg';
import checkCircle from '../../../../images/check-circle.svg';
import wifi from '../../../../images/wifi.svg';
import { useAppSelector } from '../../../../hooks/storeHooks';

// import { useAppSelector, useAppDispatch } from '../../../../hooks/storeHooks';
import { useRef, useState } from 'react';
import ConfirmationModal from './modal/ConfirmationModal';
// import { fetchPingDetails } from '../../../asset/AssetControlSlice';
import ScanLoaderModal from './modal/ScanLoaderModal';
import PingProcessingModal from './modal/PingProcessingModal';
import PingCommDataModal from './modal/PingCommDataModal';
import axios from 'axios';
import PingCommFailedModal from './modal/PingCommFailedModal';
import { Role } from '../../../../models/Role';
import { useWebSocket } from '../../../../hooks/webSocketHook';
import { v4 as uuidv4 } from 'uuid';


export enum WellControlAction {
    Start = 1,
    Stop = 2,
    Idle = 3,
    ClearAlarms = 4,
    ConstantRunMode = 5,
    PocMode = 6,
    PercentTimeMode = 7,
    Scan = 8,
    SetClock = 9,
    PowerShutdown = 10,
    UpdatePocData = 11,
    FastScan = 12,
    SurfaceMode = 13,
    VfdMode = 14,
    PipControl = 15,
    Download = 16,
    Upload = 17,
    PlungerLift5Min = 18,
    ScheduleMode = 19,
    ScanMasterAndSlaves = 20,
    EnablePid = 21,
    DisablePid = 22,
    FixedSpeedManual = 23,
    FixedSpeedTimer = 24,
    FixedSpeedPumpOff = 25,
    VariableSpeedPumpFillage = 26,
    VariableSpeedManual = 27,
    VariableSpeedPumpOff = 28,
    FixedSpeedPumpFillage = 29,
}

export enum NonWellControlAction {
    Ping = 1
}
export enum ItemType {
    WellControlAction,
    NonWellControlAction
}
export enum WebSocketActionType {
    AssetControl,
    ReadCurrentFrequency,
    ReadDevice,
    UpdateDevice
}

export interface ControlActionUIItem {
    itemType: ItemType;
    controlActionType: WellControlAction | NonWellControlAction;
    label: string;
    icon?: string;
}

export const allControlActions: ControlActionUIItem[] = [
    {
        itemType: ItemType.WellControlAction,
        controlActionType: WellControlAction.Scan,
        label: 'Scan',
        icon: signal,
    },

    {
        itemType: ItemType.WellControlAction,
        controlActionType: WellControlAction.Start,
        label: 'Start systems',
        icon: playCircle,
    },
    {
        itemType: ItemType.WellControlAction,
        controlActionType: WellControlAction.Stop,
        label: 'Stop systems',
        icon: stopCircle,
    },
    {
        itemType: ItemType.NonWellControlAction,
        controlActionType: NonWellControlAction.Ping,
        label: 'Ping comms',
        icon: wifi,
    },
    {
        itemType: ItemType.WellControlAction,
        controlActionType: WellControlAction.ClearAlarms,
        label: 'Clear alarms',
        icon: checkCircle,
    },
];

interface AssetControlActionsProps {
    supportedControlActionIds: number[];
    isWellEnabled?: boolean;
    userRoles: number[] | undefined

}

interface confirmationModalProps {
    action: string,
    heading: string,
    description: string,
    controlType: string,
}

const AssetControlActions: React.FC<AssetControlActionsProps> = (props: AssetControlActionsProps) => {
    // const dispatch = useAppDispatch();
    const selectedAssetName = useAppSelector((state) => state?.assetGroups?.selectedAssetName)
    const selectedAssetId = useAppSelector((state) => state?.assetGroups?.selectedAssetId)
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
    const [confirmationModalProps, setConfirmationModalProps] = useState<confirmationModalProps>({
        action: '',
        heading: '',
        description: '',
        controlType: ''
    })
    const assetControlData = useAppSelector((state) => state?.assetControl)
    const wellStatus = assetControlData?.wellStatus
    const [selectedWellControlType, setSelectedWellControlType] = useState<string>('')
    const [selectedWellControlLabel, setSelectedWellControlLabel] = useState<string>('')
    const [requestId, setRequestId] = useState<string>('')
    useWebSocket({ webSocketActionType: WebSocketActionType.AssetControl, type: selectedWellControlType, label: selectedWellControlLabel, requestId: requestId })

    const pocSupportedControlActions =
        allControlActions.filter((x) => {
            // If nested list option, check if one of the list's options are supported by current poc type before enabling it.
            // Else if not nested list option, check if the well control action is supported by the current poc type before enabling it.
            if (
                x.itemType === ItemType.WellControlAction &&
                props.supportedControlActionIds.includes(x.controlActionType)
            ) {
                return true;
            } else if (x.itemType === ItemType.NonWellControlAction) {
                return true;
            }
            return false;
        }) ?? [];

    const cancelTokenSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null);
    const closeConfirmationModal = () => {
        setIsConfirmationModalOpen(false)
    }
    const handleConfirm = () => {
        const type = confirmationModalProps?.controlType
        if (type) {
            const controlActionId = WellControlAction[type as keyof typeof WellControlAction];
            const label = allControlActions.find(item => item.controlActionType === controlActionId)?.label;
            setSelectedWellControlLabel(label ? label : type)
            setSelectedWellControlType(controlActionId.toString())
            setRequestId(uuidv4())
        }
        setIsConfirmationModalOpen(false)
    }

    const scanWell = (wellControlType: number, label: string) => {
        setSelectedWellControlType(wellControlType.toString())
        setSelectedWellControlLabel(label)
        setRequestId(uuidv4())
    }


    // const pingComms = () => {
    //     if (selectedAssetName) {
    //         cancelTokenSource.current = axios.CancelToken.source();

    //         dispatch(fetchPingDetails({ wellName: selectedAssetName, cancelToken: cancelTokenSource?.current?.token }))
    //     }
    // }

    const cancelPing = () => {
        if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel('Ping is canceled');
        }
    }
    const renderWellControlActionItems = (items: ControlActionUIItem[]): React.JSX.Element => {

        return (
            <>
                {
                    items?.map((option) => {
                        if (option.itemType === ItemType.WellControlAction) {
                            let btnClassName = 'enabled'
                            if (wellStatus?.enabled === 1 && (props?.userRoles?.includes(Role.Assetcontrol) || props?.userRoles?.includes(Role.Admin))) {
                                btnClassName = 'enabled'
                                if (option.controlActionType == WellControlAction.Start) {
                                    if ((assetControlData.startActionProgressStatus && assetControlData.activeAssetControlActionAssetId === selectedAssetId) || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('run'))
                                        btnClassName = 'disabled'
                                } else if (option.controlActionType === WellControlAction.Stop) {
                                    if ((assetControlData.stopActionProgressStatus && assetControlData.activeAssetControlActionAssetId === selectedAssetId) || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('shut') || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('idle'))
                                        btnClassName = 'disabled'
                                } else if (option.controlActionType === WellControlAction.ClearAlarms) {
                                    if ((assetControlData.clearAlarmActionProgressStatus && assetControlData.activeAssetControlActionAssetId === selectedAssetId) || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('shut') || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('idle'))
                                        btnClassName = 'disabled'
                                }
                                // else {
                                //     if ((wellStatus?.runStatus?.toLocaleLowerCase()?.includes('shut') || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('idle')))
                                //         btnClassName = 'disabled'
                                // }
                            } else {
                                btnClassName = 'disabled'
                            }

                            return (
                                <button className={`flex-btn ${btnClassName}`}
                                    onClick={() => {
                                        if (wellStatus?.enabled === 1 && (props?.userRoles?.includes(Role.Assetcontrol) || props?.userRoles?.includes(Role.Admin))) {
                                            switch (option.controlActionType) {
                                                case WellControlAction.Start:
                                                    if (!(assetControlData.startActionProgressStatus && assetControlData.activeAssetControlActionAssetId === selectedAssetId) && !wellStatus?.runStatus?.toLocaleLowerCase()?.includes('run')) {
                                                        setConfirmationModalProps({
                                                            action: 'Start',
                                                            heading: 'Start',
                                                            description: 'Are you sure you want to start this well?',
                                                            controlType: 'Start'
                                                        })
                                                        setIsConfirmationModalOpen(true)
                                                    }
                                                    break;
                                                case WellControlAction.Stop:
                                                    if (!(assetControlData.stopActionProgressStatus && assetControlData.activeAssetControlActionAssetId === selectedAssetId) && !(wellStatus?.runStatus?.toLocaleLowerCase()?.includes('shut') || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('idle'))) {
                                                        setConfirmationModalProps({
                                                            action: 'Shutdown',
                                                            heading: 'Shutdown',
                                                            description: 'Are you sure you want to shutdown this well?',
                                                            controlType: 'Stop'
                                                        })
                                                        setIsConfirmationModalOpen(true)
                                                    }
                                                    break;
                                                case WellControlAction.ClearAlarms:
                                                    if (!(assetControlData.clearAlarmActionProgressStatus && assetControlData.activeAssetControlActionAssetId === selectedAssetId) && !(wellStatus?.runStatus?.toLocaleLowerCase()?.includes('shut') || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('idle'))) {
                                                        setConfirmationModalProps({
                                                            action: 'Clear',
                                                            heading: 'Clear Alarms',
                                                            description: 'Are you sure you want to clear all alarms?',
                                                            controlType: 'ClearAlarms'
                                                        })
                                                        setIsConfirmationModalOpen(true)
                                                    }
                                                    break;
                                                case WellControlAction.Scan:
                                                    scanWell(WellControlAction.Scan, 'Scan')


                                            }
                                        }
                                    }}
                                >
                                    <img src={option.icon} alt='' />
                                    {option.label}
                                </button>
                            )
                        }
                        // else if (option.itemType === ItemType.NonWellControlAction) {
                        //     return (
                        //         <button className={`flex-btn ${(wellStatus?.enabled === 0 || (!props?.userRoles?.includes(Role.Assetcontrol) && !props?.userRoles?.includes(Role.Admin))) && 'disabled'}`}
                        //             onClick={() => {
                        //                 if (wellStatus?.enabled === 1 && (props?.userRoles?.includes(Role.Assetcontrol) || props?.userRoles?.includes(Role.Admin)))
                        //                     pingComms()
                        //             }}
                        //         >
                        //             <img src={option.icon} alt='' />
                        //             {option.label}
                        //         </button>
                        //     )
                        // }
                    })
                }

                {
                    isConfirmationModalOpen && (
                        <ConfirmationModal assetControlProps={confirmationModalProps} isModalClose={closeConfirmationModal} isModalOk={handleConfirm} />
                    )
                }
                {
                    assetControlData?.executeControlScanActionLoading && selectedAssetName &&
                    <ScanLoaderModal wellName={selectedAssetName} />
                }
                {
                    assetControlData?.assetPingLoading && (
                        <PingProcessingModal wellName={selectedAssetName} handleCancel={cancelPing} ipAddress={wellStatus?.ipAddress} />
                    )
                }
                {
                    assetControlData?.pingCommDataShowStatus && (
                        <PingCommDataModal wellName={selectedAssetName} ipAddress={wellStatus?.ipAddress} />
                    )
                }
                {
                    assetControlData?.pingCommFailedStatus && (
                        <PingCommFailedModal wellName={selectedAssetName} ipAddress={wellStatus?.ipAddress} />
                    )
                }
            </>
        )
    }

    return renderWellControlActionItems(pocSupportedControlActions)
}

export default AssetControlActions