import axios from "axios";
import { http } from "../../../../config/HttpCommon";
import { getAPIBaseURL } from "../../../../utilities/BaseURLUtility";

export const getDocuments = async (userId: string, wellId: string) => {
  const response = await http.get(`/getAssetDocument/${userId}/${encodeURIComponent(wellId)}`);
  return response.data;
};

export const downloadAssetDocument = async (id: string, cancelToken: any) => {
  try {
    const response = await http.post(`/downloadAssetDocument`, id, {
      responseType: 'blob', // Set the response type to blob
      cancelToken
    });
    return response.data;
  } catch (error) {
    console.error('Error downloading document:', error);
    throw error;
  }
};
export const downloadQbAssetDocumentByName = async (wellId: string, fileName: string, cancelToken: any) => {
  try {
    const response = await http.get(`/DownloadQbAssetDocumentByName/${wellId}/${encodeURIComponent(fileName)}`, {
      responseType: 'blob',
      cancelToken
    });
    return response.data;
  } catch (error) {
    console.error('Error downloading document:', error);
    throw error;
  }
};

export const documentDownloadLookupValues = async (groupName: string) => {
  const token = localStorage.getItem('jwtAccessToken');

  const response: any = await axios.get(getAPIBaseURL() + '/v1/lookups/lookups-by-group/DOCUMENT_DOWNLOAD?' + groupName, {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
      Authorization: 'Bearer ' + token,

    },
  });
  return response.data;
};

export const deleteAssetDocument = async (ids: string[]) => {
  const response = await http.post(`/deleteAssetDocument`, ids);
  return response.data;
};

export const createDocumentData = async (assetDocument: FormData) => {
  const config = {
    ...http,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  try {
    const response = await http.post('/createAssetDocument', assetDocument, config);
    return response.data;
  } catch (error) {
    console.error('Error creating Asset Document:', error);
    throw error;
  }
};


