import { useState } from 'react';
//import EquipmentItemDetails from "./EquipmentItemDetails";
import { EquipmentDetail } from '../Equipment';
import '../Equipment.scss';

interface EquipmentDetailsProps {
  selectedEquipment: string | null;
  equipmentDetails: EquipmentDetail[];
  volumeDetails: any;
  onSelectDetail: (detail: EquipmentDetail) => void;
}

const EquipmentDetails: React.FC<EquipmentDetailsProps> = ({
  selectedEquipment,
  //onSelectDetail,
  //volumeDetails,
  equipmentDetails,
}) => {
  const [selectedDetaildescription] = useState<string | null>(null);

  const selectedDetail = selectedDetaildescription
    ? equipmentDetails.find((d) => d.description === selectedDetaildescription)
    : null;
  // const handleDetailClick = (detail: EquipmentDetail) => {
  //   setSelectedDetaildescription(detail.description);
  //   onSelectDetail(detail);  // This triggers the function passed from the parent
  // }

  return (
    <div className='equipment-details-container'>
      <div className='equipment-details__header'>
        <p className='equipmwnt-details__text'>{selectedEquipment}</p>
      </div>
      <div className='equipment-details__card-contianer'>
        <ul className='equipment-details__detail-card'>
          {equipmentDetails.map((detail, index) => (
            <li
              key={index}
              className={`equipment-details__details ${selectedDetaildescription === detail.description ? 'active-detail' : ''}`}
              //onClick={() => handleDetailClick(detail)}
            >
              {detail.description}
            </li>
          ))}
        </ul>
      </div>
      {selectedDetail && (
        <div className='selected-equipment-details' style={{ width: 'auto' }}>
          {/* <EquipmentItemDetails item={selectedDetail} volumeDetails={volumeDetails}/> */}
        </div>
      )}
    </div>
  );
};

export default EquipmentDetails;
