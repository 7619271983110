import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getHealthScore, getHealthScoreHistoryData, getHealthScoreTargets } from "./services/HealthScoreService";
import { HealthScore, HealthScoreHistory, HealthScoreTarget } from "./model/HealthScoreHistory";


interface HealthScoreProps {
    healthScoreHistoryLoading: boolean,
    healthScoreLoading: boolean,
    healthScoreTargetLoading: boolean,
    healthScoreHistory: HealthScoreHistory[],
    healthScore: HealthScore | null,
    healthScoreTarget: HealthScoreTarget | null,
    totalHealthScoreHistoryCount: number
}

const initialState: HealthScoreProps = {
    healthScoreHistoryLoading: false,
    healthScoreLoading: false,
    healthScoreTargetLoading: false,
    healthScoreHistory: [],
    healthScore: null,
    healthScoreTarget: null,
    totalHealthScoreHistoryCount: 0

}

export const fetchHealthScoreHistory = createAsyncThunk('getHealthScoreHistory', async ({ assetName, StartDate, EndDate, PageNumber, PageSize }: { assetName: string, StartDate: string, EndDate: string, PageNumber: number, PageSize: number }) => {
    return await getHealthScoreHistoryData(assetName, StartDate, EndDate, PageNumber, PageSize)
})

export const fetchHealthScore = createAsyncThunk('getHealthScore', async (assetName: string) => {
    return await getHealthScore(assetName)
})

export const fetchHealthScoreTarget = createAsyncThunk('getHealthScoreTarget', async (assetName: string) => {
    return await getHealthScoreTargets(assetName)
})

const healthScoreSlice = createSlice({
    name: 'healthScore',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // fetch health score history thunk cases
        builder.addCase(fetchHealthScoreHistory.pending, (state) => {
            state.healthScoreHistoryLoading = true;
            state.totalHealthScoreHistoryCount = 0

        })
        builder.addCase(fetchHealthScoreHistory.fulfilled, (state, action) => {
            const { PageNumber } = action.meta.arg
            const healthScoreData = action.payload.data.results
            state.healthScoreHistoryLoading = false;
            if (PageNumber === 0) state.healthScoreHistory = healthScoreData
            else state.healthScoreHistory = state.healthScoreHistory ? [...state.healthScoreHistory, ...(Array.isArray(healthScoreData) ? healthScoreData : [])] : Array.isArray(healthScoreData) ? [...healthScoreData] : []
            state.totalHealthScoreHistoryCount = action.payload.data.totalResultsCount
        })
        builder.addCase(fetchHealthScoreHistory.rejected, (state) => {
            state.healthScoreHistoryLoading = false;
            state.healthScoreHistory = []
        })

        // fetch health score
        builder.addCase(fetchHealthScore.pending, (state) => {
            state.healthScoreLoading = true;
        })
        builder.addCase(fetchHealthScore.fulfilled, (state, action) => {
            state.healthScore = action.payload.data
            state.healthScoreLoading = false;
        })
        builder.addCase(fetchHealthScore.rejected, (state) => {
            state.healthScore = null
            state.healthScoreLoading = false;
        })


        // fetch health score targets
        builder.addCase(fetchHealthScoreTarget.pending, (state) => {
            state.healthScoreTargetLoading = true;
        })
        builder.addCase(fetchHealthScoreTarget.fulfilled, (state, action) => {
            state.healthScoreTarget = action.payload.data
            state.healthScoreTargetLoading = false;
        })
        builder.addCase(fetchHealthScoreTarget.rejected, (state) => {
            state.healthScoreTarget = null
            state.healthScoreTargetLoading = false;
        })

    }
});

// export const { updateAssetControlRoomDrawerProps } = assetControlRoomDrawerSlice.actions;
export default healthScoreSlice.reducer;