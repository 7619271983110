import React, { useEffect, useState, useRef, useCallback, memo } from 'react';
import { Column, ColumnInstance, useTable } from 'react-table';
import { AssetDocument } from '../model/AssetDocument';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';
import { deleteDocument, deleteDocumentSuccess, downloadDocument, downloadQbAssetDocument } from '../AssetDocumentsSlice';
import SearchIcon from '../../../../../images/search-icon.svg';
import './DocumentTable.scss';
import { ToastContainer, toast } from 'react-toastify';
import { } from '../../../../../utilities/CommonFunctions';
import AlertModel from '../documentationError/AlertModel';
import { ReactComponent as SortIconDefault } from '../../../../../images/Chevron-default-icon.svg';
import { ReactComponent as SortIconUp } from '../../../../../images/Chevron-up-icon.svg';
import { ReactComponent as SortIconDown } from '../../../../../images/Chevron-down-icon.svg';
import axios from 'axios';
import Loader from '../../../../common/page-loader/ComponentLoader';


const columns: Column<AssetDocument>[] = [
  { Header: '#', accessor: 'selected' },
  { Header: 'File name', accessor: 'fileName' },
  { Header: 'Category', accessor: 'category' },
  { Header: 'Source', accessor: 'source' },
  { Header: 'Expires', accessor: 'expires' },
  { Header: 'Event date', accessor: 'eventDate' },
  { Header: 'Date modified', accessor: 'updatedAt' },
  { Header: 'Modified by', accessor: 'updatedBy' },
];

interface DocumentTableProps {
  selectedCategories: string[] | null;
  searchTerm: string;
  onGetSelectedDocuments: (count: number) => void;
  showFileColumn: boolean;
  onSelectDocument: (selectedDocument: AssetDocument[]) => void
  showDownloadErrorForSingleDocument: (fileName: string) => void
}

const DocumentTable: React.FC<DocumentTableProps> = ({
  selectedCategories,
  searchTerm,
  onGetSelectedDocuments,
  showFileColumn,
  onSelectDocument,
  showDownloadErrorForSingleDocument
}) => {
  const dispatch = useAppDispatch();
  const storeAssetDocument = useAppSelector((state) => state?.assetDocument.documents);
  const docLoading = useAppSelector((state) => state?.assetDocument.loading);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<number | null>(null);
  const [selectAll, setSelectAll] = useState(false);
  const [displayCount, setDisplayCount] = useState(15);
  const [loading, setLoading] = useState(false);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<Set<string>>(new Set());
  const [selectedDocument, setSelectedDocument] = useState<AssetDocument[]>([])
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [sortConfig, setSortConfig] = useState<{ key: keyof AssetDocument; direction: 'asc' | 'desc' } | null>({
    key: 'category',
    direction: 'asc',
  });
  const [filteredColumns, setFilteredColumns] = useState<Column<AssetDocument>[]>([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [documentToBeDeleted, setDocumentToBeDeleted] = useState<AssetDocument | null>(null);
  const hElement = document.getElementById('main-root');
  const timeOutValue = 60000 // 1 minute

  useEffect(() => {
    onSelectDocument(selectedDocument)
  }, [selectedDocument])
  useEffect(() => {
    if (selectedDocument?.length) {
      setSelectedDocument([])
      setSelectedDocumentIds(new Set())
    }
    if (selectAll)
      setSelectAll(false)
  }, [selectedCategories, searchTerm])

  
  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedDocumentIds(new Set());
      setSelectedDocument([])
    } else {
      const newSelectedIds = new Set<string>();
      displayedRecords.forEach((doc) => newSelectedIds.add(doc.id!));
      setSelectedDocumentIds(newSelectedIds);
      setSelectedDocument(displayedRecords)
    }
    setSelectAll(!selectAll);
  };

  const toggleDocumentSelection = (document: AssetDocument) => {
    const newSelectedIds = new Set(selectedDocumentIds);
    if (newSelectedIds.has(document.id!)) {
      newSelectedIds.delete(document.id!);
      setSelectedDocument((prev) => prev.filter(item => item.id != document.id))
    } else {
      newSelectedIds.add(document.id!);
      setSelectedDocument((prev) => [...prev, document])
    }
    setSelectedDocumentIds(newSelectedIds);
    setSelectAll(newSelectedIds.size === displayedRecords?.length);
  };

  const openContextMenuOpen = (index: number) => {
    setIsContextMenuOpen((prevIndex) => (prevIndex === index ? null : index));
  };

  const closeDropdown = () => {
    setIsContextMenuOpen(null);
  };

  const handleDownloadDocument = async (assetDocument: AssetDocument) => {
    closeDropdown()
    const fileName = assetDocument.fileName;
    const cancelTokenSource = axios.CancelToken.source();
    const timeoutId = setTimeout(() => {
      showDownloadErrorForSingleDocument(fileName);
      cancelTokenSource.cancel('timeout');
    }, timeOutValue);

    try {
      let fileData = null;
      if (assetDocument.source === 'User') {
        const actionResult = await dispatch(
          downloadDocument({ id: String(assetDocument.id), cancelToken: cancelTokenSource.token })
        );
        fileData = actionResult?.payload;
      } else {
        const response = await dispatch(
          downloadQbAssetDocument({ wellId: assetDocument.wellId, fileName: assetDocument.fileName, cancelToken: cancelTokenSource.token })
        );
        if (response?.payload) {
          fileData = response.payload;
        } else {
          const response1: any = response
          if (response1.error.message !== 'timeout')
            toast.error("Unable to download the document");
        }
      }

      // If fileData is valid, proceed with the download
      if (fileData) {
        const url = window.URL.createObjectURL(fileData);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', assetDocument.fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up after download

      }
      if (timeoutId) {
        clearTimeout(timeoutId); // Clear timeout when downloads are complete
      }

    } catch (error) {
      console.log('Error downloading the document:', error);
    }
  };

  const handleShareViaEmail = async (assetDocument: AssetDocument) => {
    closeDropdown();
    try {
      let fileData;
      if (assetDocument.source === 'User') {
        const actionResult = await dispatch(downloadDocument({ id: String(assetDocument.id) }));
        fileData = actionResult.payload;
      } else {
        const response = await dispatch(downloadQbAssetDocument({ wellId: assetDocument.wellId, fileName: assetDocument.fileName }))
        if (response.payload) {
          fileData = response.payload;
        }
      }
      const blob = new Blob([fileData], { type: 'application/octet-stream' });
      const fileName = assetDocument.fileName;
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      const emailAddress = 'recipient@championx.com';
      const subject = 'Asset Attachment';
      const body = 'Please find the attachment.';
      const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        body,
      )}`;
      window.location.href = mailtoLink;
      closeDropdown();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onGetSelectedDocuments(selectedDocumentIds.size);
  }, [selectedDocumentIds]);

  const filteredByCategory = storeAssetDocument.filter(
    (doc) => selectedCategories?.length === 0 || selectedCategories?.includes(doc.category),
  );

  const filteredData = filteredByCategory.filter(
    (doc) =>
      doc.fileName?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      doc.expires?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      doc.eventDate?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      doc.updatedBy?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      doc.updatedByName?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      doc.updatedAt?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      doc.category?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      doc.source?.toString().toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleConfirmationClose = () => {
    setIsConfirmationModalOpen(false);
    setDocumentToBeDeleted(null);
    hElement && hElement?.classList.remove('modal-open');
  };

  const handleDelete = (assetDocument: AssetDocument) => {
    setDocumentToBeDeleted(assetDocument);
    setIsConfirmationModalOpen(true);
    hElement && hElement?.classList.add('modal-open');
  };

  const handleConfirmationOk = async () => {
    setIsConfirmationModalOpen(false);
    closeDropdown();

    if (documentToBeDeleted?.id !== undefined) {
      dispatch(deleteDocument({ id: [documentToBeDeleted.id] }))
        .then(() => {
          documentToBeDeleted?.id ? dispatch(deleteDocumentSuccess(documentToBeDeleted.id)) : null;
          toast.success('Removed successfully.!');
        })
        .catch((error) => {
          toast.success('server error', error);
        });
    }
    setSelectedDocumentIds(new Set());
    setSelectAll(false);
    hElement && hElement?.classList.remove('modal-open');
  };

  useEffect(() => {
    setNoResultsFound(filteredData?.length === 0);
  }, [filteredData]);

  const displayedRecords = filteredData.slice(0, displayCount);

  useEffect(() => {
    if (selectAll && displayedRecords?.length !== selectedDocumentIds?.size)
      setSelectAll(false)
  }, [displayedRecords])

  const observer = useRef<IntersectionObserver>();
  const lastDocumentElementRef = useCallback(
    (node: HTMLTableRowElement | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && displayCount < filteredData?.length) {
          setLoading(true);
          setTimeout(() => {
            setDisplayCount((prevDisplayCount) => prevDisplayCount + 15);
            setLoading(false);
          });
        }
      });
      if (node instanceof HTMLTableRowElement) observer.current.observe(node);
    },
    [loading, displayCount, filteredData?.length],
  );

  const sortedData = React.useMemo(() => {
    if (sortConfig === null) return displayedRecords;
    return [...displayedRecords].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue == null || bValue == null) return 0;
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue) * (sortConfig.direction === 'asc' ? 1 : -1);
      }
      return aValue < bValue
        ? sortConfig.direction === 'asc'
          ? -1
          : 1
        : aValue > bValue
          ? sortConfig.direction === 'asc'
            ? 1
            : -1
          : 0;
    });
  }, [displayedRecords, sortConfig]);

  useEffect(() => {
    const filteredColumns = columns.filter((column) => (column.Header === 'File name' ? showFileColumn : true));
    setFilteredColumns(filteredColumns);
    showFileColumn ? setSortConfig({ key: 'fileName', direction: 'asc' }) : setSortConfig({ key: 'category', direction: 'asc' });
  }, [showFileColumn]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: filteredColumns,
    data: sortedData,
  });

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (event.target instanceof HTMLElement && event.target.closest('.dropdown-container')) return;
      closeDropdown();
    };

    document.body.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.body.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const handleSort = (column: ColumnInstance<AssetDocument>) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === column.id && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: column.id as keyof AssetDocument, direction });
  };

  return (
    <>
      <ToastContainer position='top-right' autoClose={3000} />
      <div className='doc-wrapper'>
        <div className='table-container w-full'>
          <table className='document-table' {...getTableProps()}>
            <thead className='document-head'>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps()}
                      onClick={() => column.id != "selected" && handleSort(column as ColumnInstance<AssetDocument>)}
                      key={column.id}
                    >
                      <div>
                        {index === 0 ? (
                          <label className='checkbox-container'>
                            <input
                              type='checkbox'
                              id='showCheckbox'
                              checked={selectAll}
                              onChange={toggleSelectAll}
                            />
                            <span className='checkbox-checkmark'></span>
                          </label>
                        ) : (
                          column.render('Header')
                        )}

                        {sortConfig?.key === column.id ? (
                          sortConfig.direction === 'asc' ? (
                            <SortIconUp alt='sort-asc' className='sort-img' />
                          ) : (
                            <SortIconDown alt='sort-dsc' className='sort-img' />
                          )
                        ) : (
                          <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                        )}
                      </div>
                    </th>
                  ))}
                  <th></th>
                </tr>
              ))}
            </thead>
            <tbody className='document-body' {...getTableBodyProps()}>
              {docLoading ? (<div className='asset-documnet-loader flex items-center justify-center w-100'>
                <Loader />
              </div>) : 
              noResultsFound && displayedRecords?.length === 0 ? (
                <tr>
                  <td colSpan={columns.length + 1} style={{ textAlign: 'center' }}>
                    <img className='search-icon-lg' src={SearchIcon} alt='' />
                    No results found
                    <p>We couldn't find your search results. Try searching another keyword</p>
                  </td>
                </tr>
              ) : (
                rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    ref={rows.length === rowIndex + 1 ? lastDocumentElementRef : null}
                    key={row.id}
                  >
                    {row.cells.map((cell, cellIndex) => (
                      <td {...cell.getCellProps()} key={cell.column.id}>
                        {cellIndex === 0 ? (
                          <label className='checkbox-container'>
                            <input
                              type='checkbox'
                              checked={selectedDocumentIds.has(row.original.id!)}
                              onChange={() => toggleDocumentSelection(row.original)}
                            />
                            <span className='checkbox-checkmark'></span>
                          </label>

                        ) :
                          cellIndex === (showFileColumn ? 7 : 6) ?
                            <>{row.original.updatedByName}</>
                            :
                            (
                              cell.render('Cell')
                            )}
                      </td>
                    ))}
                    <td>
                      <div className='dropdown-container'>
                        <button className='dropdown-btn' onClick={() => openContextMenuOpen(rowIndex)}>
                          <span className='dot'></span>
                          <span className='dot'></span>
                          <span className='dot'></span>
                        </button>
                        {isContextMenuOpen === rowIndex && (
                          <div className='dropdown-options'>
                            <button onClick={() => handleDownloadDocument(row.original)}>Download</button>
                            <button onClick={() => handleShareViaEmail(row.original)}>Share Via Email</button>
                            {row?.original.id && row?.original?.id?.length > 10 && (
                              <button onClick={() => handleDelete(row.original)}>Delete</button>
                            )}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
              )}
              {loading && (
                <tr>
                  <td colSpan={columns?.length + 1}>
                    <div className='loading-spinner'>Loading...</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {documentToBeDeleted && (
        <AlertModel
          isValidAction={isConfirmationModalOpen}
          isModalClose={handleConfirmationClose}
          isModalOk={handleConfirmationOk}
        />
      )}
    </>
  );
};

export default memo(DocumentTable);
