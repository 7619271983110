import React from 'react';
import WIP from '../../../../images/wip1.svg';

const AssetAlarms: React.FC = () => {
  return (
    <>
      <div className='flex flex-row flex-grow profile-block asset-alarms px-5 assetList-alarms'>
        <div className='card profile-card'>
          <div className='header'>
            <p className='title'>Asset Alarms</p>
            <p className='normal-text'>Create an asset alarms details here.</p>
          </div>
          <div className='body'>
            <center>
              <img src={WIP} alt='work in progress' />
              <p className='mt-6'>Under construction / Maintanance</p>
            </center>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssetAlarms;
