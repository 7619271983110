import React, { memo, useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { SpeedChartProps } from '../interfaces/speed.interface';

const SpeedChart: React.FC<SpeedChartProps> = ({
  chartID,
  chartRef,
  classValue,
  data,
  chartName,
  minValue,
  maxValue,
  startAngle,
  endAngle,
  bandsData,
  spacingValue,
  root
}) => {
  const speedVal = chartName === "RPM" && data?.speed?.value ? (Number(data?.speed?.value) * 60) : Number(data?.speed?.value) || 0;
  const minVal = chartName === "RPM" && data?.min?.value ? (Number(data?.min?.value) * 60) : Number(data?.min?.value) || 0;
  const maxVal = chartName === "RPM" && data?.max?.value ? (Number(data?.max?.value) * 60) : Number(data?.max?.value) || 0;

  useEffect(() => {
    setTimeout(() => {
      if (chartRef.current) {

        // Dispose of previous root if it exists
        if (root.current) {
          root.current.dispose();
          root.current = null;
        }

        root.current = am5.Root.new(chartRef.current);

        root.current.setThemes([am5themes_Animated.new(root.current)]);

        const chart = root.current.container.children.push(
          am5radar.RadarChart.new(root.current, {
            panX: false,
            panY: false,
            startAngle: startAngle,
            endAngle: endAngle,
            paddingBottom: 65, // Add padding at the bottom
            //innerRadius: -10
          })
        );

        const axisRenderer = am5radar.AxisRendererCircular.new(root.current, {
          strokeOpacity: 0.1,
          minGridDistance: 30
        });

        axisRenderer.ticks.template.setAll({
          visible: false,
          strokeOpacity: 0.5
        });

        axisRenderer.grid.template.setAll({
          visible: false
        });

        const axis = chart.xAxes.push(
          am5xy.ValueAxis.new(root.current, {
            maxDeviation: 0,
            //min: data?.min,
            //max: data?.max,
            min: minValue,
            max: maxValue,
            strictMinMax: true,
            renderer: axisRenderer,
            //fill: am5.color(0x384252),
          })
        );

        // axis.get('renderer').labels.template.set(
        //   'fill', am5.color('#fff')
        // );


        // Set label properties to display horizontally and avoid wrapping
        axis.get('renderer').labels.template.setAll({
          fill: am5.color('#fff'),
          rotation: 0, // Horizontal labels
          textAlign: 'center', // Centered horizontally
          verticalCenter: 'middle', // Centered vertically
          maxWidth: 100, // Adjust as needed
          fontSize: "12px", // Smaller font size if necessary
          fontWeight: "400", // Adjust weight if needed
          visible: false
        });

        // band creation
        am5.array.each(bandsData, function (data, index) {
          const axisRange: any = axis.createAxisRange(axis.makeDataItem({}));

          axisRange.setAll({
            value: data.lowScore + (index * spacingValue),
            endValue: data.highScore + (index * spacingValue)
          });

          axisRange.get("axisFill").setAll({
            visible: true,
            fill: am5.color(data.color),
            fillOpacity: 1,
            innerRadius: -10,
            gap: 10,
            cornerRadius: 25,
          });

          axisRange.get("label").setAll({
            textType: "regular",
            location: index === bandsData?.length - 1 ? 0.5 : 1,
            text: data.highScore,
            visible: true,
            centerX: am5.p50,
            centerY: am5.p50,
            radius: 20
          });

          if (index === 0) {
            const axisRange1 = axis.createAxisRange(axis.makeDataItem({}));

            axisRange1.setAll({
              value: data.lowScore + (index * spacingValue),
              endValue: data.highScore + (index * spacingValue)
            });

            axisRange1.get("axisFill").setAll({
              visible: true,
              fill: am5.color(data.color),
              fillOpacity: 1,
              innerRadius: -10,
              gap: 10,
              cornerRadius: 25,
            });

            // Set the high label (on the right side of the range)
            axisRange1.get("label").setAll({
              textType: "regular",
              location: 0,
              text: data.lowScore,
              visible: true,
              centerX: am5.p50,
              centerY: am5.p50,
              radius: 20
            });
          }

        });


        // Add clock hand
        const createHand = (value: number | undefined, topWidth: number, bottomWidth: number, pinRadius: number, radius: number, color: am5.Color) => {
          const handDataItem: any = axis.makeDataItem({
            value: value
          });

          const hand = handDataItem.set("bullet", am5xy.AxisBullet.new(root.current, {
            sprite: am5radar.ClockHand.new(root.current, {
              topWidth: topWidth,
              pinRadius: am5.percent(pinRadius),
              radius: am5.percent(radius),
              bottomWidth: bottomWidth,
              //bottomWidth: 200,
              //innerRadius: am5percent(50),

            })
          }));

          hand.get("sprite").pin.setAll({
            forceHidden: false,
            fill: color,
            fillOpacity: 1,
          });

          hand.get("sprite").hand.setAll({
            fill: color,
            fillOpacity: 1,
          });


          axis.createAxisRange(handDataItem);

          return hand;
        }
        createHand(50, 0, 0, 72, 78, am5.color(0x5D6675));
        createHand(50, 0, 0, 70, 78, am5.color(0x001023));
        const hand2 = createHand(speedVal, 0, 45, 61, 86, am5.color(0x4A5463));
        const hand1 = createHand(speedVal, 0, 40, 60, 85, am5.color(0x001023));


        hand1.get("sprite").dataItem.animate({
          key: "value",
          to: speedVal,
          duration: 800,
          easing: am5.ease.out(am5.ease.cubic)
        });
        hand2.get("sprite").dataItem.animate({
          key: "value",
          to: speedVal,
          duration: 800,
          easing: am5.ease.out(am5.ease.cubic)
        });

        const label = chart.radarContainer.children.push(am5.Label.new(root.current, {
          fill: am5.color(0xffffff),
          centerX: am5.percent(50),
          textAlign: "center",
          centerY: am5.percent(65),
          fontSize: "28px",
          fontWeight: "500"
        }));
        label.set("text", Math.round(speedVal).toString());

        const label2 = chart.radarContainer.children.push(am5.Label.new(root.current, {
          fill: am5.color(0xffffff),
          centerX: am5.percent(50),
          textAlign: "center",
          centerY: am5.percent(0),
          fontSize: "12px"
        }));
        label2.set("text", `${chartName}`);

        // Create a legend
        const legend = chart.children.push(
          am5.Legend.new(root.current, {
            contentAlign: "center", // Center the content of the legend itself
            centerX: am5.p50,  // Center horizontally
            x: am5.p50,        // Align to the center
            y: am5.percent(137), // Move it to the bottom
            // y: am5.percent(110), // Move it to the bottom for modal window
            centerY: am5.p100, // Anchor the legend at the bottom
            layout: root.current.gridLayout, // Layout for horizontal alignment    layout: root.current.horizontalLayout, // Arrange items in a horizontal layout
          })
        );

        // Set the legend data (name and value)
        legend.data.setAll([
          {
            name: "Min:",
            value: `${minVal} ${chartName}`,
          },
          {
            name: "Max:",
            value: `${maxVal} ${chartName}`,
          },
          {
            name: "PID:",
            value: `${data?.pID?.value || ""} - ${chartName || ""}`,
          },
        ]);

        // Set the legend labels
        legend.labels.template.setAll({
          text: "{name}",
        });
        // Set the legend value labels
        legend.valueLabels.template.setAll({
          text: "{value}",
        });
        legend.labels.template.set('fill', am5.color(0xffffff));
        legend.valueLabels.template.set('fill', am5.color(0xffffff));

        // Apply grid layout configuration: two items on the first row, one on the second row
        legend.set("useDefaultMarker", true); // Show marker next to names
        legend.set("contentAlign", "center"); // Center align the content
        legend.itemContainers.template.setAll({
          width: am5.percent(33), // Each item takes one-third of the container width
          // centerX: am5.p50,
          // x: am5.p50,
          // y: am5.percent(137), // Move it to the bottom
          // layout: root.current.gridLayout, // Layout for horizontal alignment    layout: root.current.horizontalLayout, // Arrange items in a horizontal layout
          layout: root.current.horizontalLayout
        });

        /* setInterval(() => {
          hand1.get("sprite").dataItem.animate({
            key: "value",
            to: Math.random() * hand1.axis.get("max"),
            duration: 800,
            easing: am5.ease.out(am5.ease.cubic)
          });
          hand2.get("sprite").dataItem.animate({
            key: "value",
            to: Math.random() * hand1.axis.get("max"),
            duration: 800,
            easing: am5.ease.out(am5.ease.cubic)
          });
        }, 2000);
 */
        // Clean up chart when the component unmounts
        return () => {
          root.current.dispose();
        };
      }
    }, 500);
  }, [minVal, maxVal, speedVal, JSON.stringify(data)]);


  return (
    <>
      <div id={`${chartID}`} ref={chartRef} className={`${classValue} chart-wrapper`}></div>
    </>
  );
};

export default memo(SpeedChart);