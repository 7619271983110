import { useEffect, useState } from "react";
import { HistoryDetailsProps } from "../HistoryDetails";
import LineChart from "../LineChart";




interface CardsDesignProps {
  trend: HistoryDetailsProps[] | any;
  index: number;
  selectedDate: {
    startDate: Date | null,
    endDate: Date | null
  };
  currentAggregateValue: string;
  filteredValues: string[],

}
const CardsDesign: React.FC<CardsDesignProps> = ({ trend, index, selectedDate, currentAggregateValue, filteredValues }) => {
  const unitOfMeasure = trend?.unitOfMeasure;
  const [activeLabels, setActiveLabels] = useState<{ [key: string]: boolean }[]>([])
  const colorCombination: any = {
    'System frequency': '#FEC84B',
    'Vibration Y': '#FFD072',
    'DC bus voltage': '#006DFA',
    'Drive voltage': '#12B76A',
    'Vibration X': '#3A7E73',
    'Intake pressure': '#004EB3',
    'Motor temperature': '#F97066',
    'Intake temperature': '#F04438',
    'Casing pressure': '#6BBBAE',
    'Tubing pressure': '#0094BD',
    'Motor current': '#FB743C',
    'Downhole current': '#F97066',
    'System RPM': '#B8C5CC',
    'Drive current': '#6CE9A6',
  };

  useEffect(() => {
    const array: { [key: string]: boolean }[] = []
    trend?.map((item: any) => {
      array.push({
        [item?.trendName]: true
      })
    })
    setActiveLabels(array)

  }, [trend])


  const handleLabelClick = (trendName: string) => {
    const updatedLabels = activeLabels?.map((item) =>
      Object.keys(item)[0] === trendName
        ? { [trendName]: !item[trendName] }
        : item
    );
    setActiveLabels(updatedLabels); // Update state
  }

  const getValueByKey = (key: string): boolean | undefined => {
    const foundLabel = activeLabels.find((label) => Object.keys(label)[0] === key);
    return foundLabel ? foundLabel[key] : undefined;
  };

  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <div className='title'>{trend[0]?.unitOfMeasure}</div>
        </div>

        <div className='card-body'>
          <div className='left-container-history'>
            {trend?.map((item: any) => {
              return (
                <div style={{ margin: '8px 0' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: '12px',
                        height: '12px',
                        borderRadius: '50%',
                        marginRight: '4px',
                        background: getValueByKey(item.trendName) ? (colorCombination[item?.trendName] ? colorCombination[item?.trendName] : 'red') : '#D3D3D3',
                      }}
                    ></div>
                    <div onClick={() => handleLabelClick(item?.trendName)} style={!getValueByKey(item.trendName) ? { color: '#D3D3D3', cursor: 'pointer' } : { cursor: 'pointer' }}>{item?.trendName}</div>
                  </div>
                  <div
                    style={{ marginLeft: '20px' }}
                  >{`${item?.medean ?? '0'} ${item?.short_UnitOfMeasure ? item?.short_UnitOfMeasure?.toUpperCase() : item?.shortUnitOfMeasure?.toUpperCase()}`}</div>
                </div>
              );
            })}
          </div>
          <div className='rigth-container' >
            <LineChart chartName={trend?.[0]?.trendName} unitOfMeasure={unitOfMeasure} chartData={trend} colorCombination={colorCombination} index={index} short_UnitOfMeasure={trend[0]?.short_UnitOfMeasure ? trend[0]?.short_UnitOfMeasure : trend[0]?.shortUnitOfMeasure} selectedDate={selectedDate} currentAggregateValue={currentAggregateValue} filteredValues={filteredValues} activeLabels={activeLabels} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CardsDesign;