import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import './TicketDetails.scss';
import '../../Dashboard.scss';
import Ticket from '../../../../images/ticket.png';
import { AppUser } from '../../../user/model/AppUser';
import { setFilterTicketsCount } from '../../DashboardSlice'; // Import the action
import { updateAssetList, updateSelectedAsset } from '../../../navigation/AssetGroupsSlice';
import { useNavigate } from 'react-router-dom';

interface TicketDetails {
  wellName?: string | undefined;
  ticket?: string | undefined;
  operator?: string | undefined;
  assignedTo?: string | undefined;
  subject?: string | undefined;
  status?: string | undefined;
  assetID?: string | undefined;
}

const FilterTicketDetails: React.FC = () => {
  const navigate = useNavigate();
  const ticketDetails = useAppSelector((state) => state.dashboard.ticketDetails) as TicketDetails[] | null;
  const filterStatus = useAppSelector((state) => state.dashboard.filterMyTicketDetails) ?? [];
  const activeTab = sessionStorage.getItem('activeTabIndex');
  const [sortConfig, setSortConfig] = useState<{ key: keyof TicketDetails; direction: 'asc' | 'desc' } | null>(null);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const dispatch = useAppDispatch();

  // Function to request sorting
  const requestSort = (key: keyof TicketDetails) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig?.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Function to get value by key
  const getValueByKey = (item: TicketDetails, key: keyof TicketDetails): string => {
    const value = item[key];
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    return value ?? '';
  };

  // Function to perform sorting
  const sortedTicketDetails = React.useMemo(() => {
    const sortableItems = ticketDetails ? [...ticketDetails] : [];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = getValueByKey(a, sortConfig.key);
        const bValue = getValueByKey(b, sortConfig.key);

        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [ticketDetails, sortConfig]);

  // Filter ticketDetails based on the filterStatus and assignedTo (last name)
  const filteredTicketDetails = sortedTicketDetails?.filter((ticket) => {
    const isStatusMatched = filterStatus?.length === 0 || filterStatus.includes(ticket.status ?? '');
    const storedUserName = `${storedUser?.firstName} ${storedUser?.lastName}`
    const isAssignedToMatched = ticket.assignedTo?.replace(',', '').toLowerCase() === storedUserName.toLowerCase();
    return isStatusMatched && isAssignedToMatched;
  });

  useEffect(() => {
    dispatch(setFilterTicketsCount(filteredTicketDetails?.length || 0));
  }, [dispatch, filteredTicketDetails]);

  const filteredTicketsCount = filteredTicketDetails?.length || 0;

  const handleWellName = (wellName: string, assetID: string) => {
    window.scrollTo(0, 0);
    if (activeTab !== '0') {
      sessionStorage.setItem('activeTabIndex', '0');
    }
    const groupList = filteredTicketDetails ? filteredTicketDetails?.map((well) => {
      return {
        assetId: well?.assetID,
        assetName: well?.wellName,
        industryApplicationId: 4,
      };
    }) : [];

    const selectedAsset = {
      assetName: wellName,
      assetGroupName: 'Well Groups',
      assetId: assetID,
      searchString: undefined,
    }

    if (selectedAsset) {
      sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
    }

    if (groupList) {
      sessionStorage.setItem('wellNameList', JSON.stringify(groupList));
    }

    dispatch(
      updateSelectedAsset(selectedAsset),
    );
    dispatch(updateAssetList(groupList));
    navigate('/layout/assets/?tab=details');
  };

  return (
    <>
      <div className='card new-ticket-details'>
        <div className='table-header-section'>
          <img src={Ticket} alt='' />
          <div className='title'>My tickets</div>
        </div>
        <p className='ticket-count'>
          You currently have {filteredTicketsCount} ticket{filteredTicketsCount > 1 ? 's' : 's'}.
        </p>

        {filteredTicketsCount > 0 && (
          <div
            id='ticketsDetailsWidgetDiv'
            className='table-container-dashboard'
            style={{ width: '100%', height: '100%', backgroundColor: '#001023', paddingTop: '8px' }}
          >
            <table className='w-100 filter-ticket'>
              <thead>
                <tr className='table-header-cell'>
                  <th className='table-header-item well-name' onClick={() => requestSort('wellName')}>
                    Well name {sortConfig?.key === 'wellName' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : '↑'}
                  </th>
                  <th className='table-header-item ticket' onClick={() => requestSort('ticket')}>
                    Ticket # {sortConfig?.key === 'ticket' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : '↑'}
                  </th>
                  {/* <th className='table-header-item'>Operator</th> */}
                  <th className='table-header-item status' onClick={() => requestSort('status')}>
                    Status {sortConfig?.key === 'status' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : '↑'}
                  </th>
                  {/* <th className='table-header-item'>Assigned To</th> */}
                  <th className='table-header-item subject' onClick={() => requestSort('subject')}>
                    Subject {sortConfig?.key === 'subject' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : '↑'}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredTicketDetails?.map((ticket, index) => (
                  <tr key={index} className='table-row-general'>
                    <td className='well-name' onClick={() => handleWellName(ticket.wellName ?? '', ticket?.assetID ?? '')}>{ticket.wellName ?? '-'}</td>
                    <td className='ticket'>{ticket.ticket ?? '-'}</td>
                    {/* <td>{ticket.operator}</td> */}
                    <td className='status'>{ticket.status ?? '-'}</td>
                    {/* <td>{ticket.assignedTo}</td> */}
                    <td data-title={ticket.subject} className='subject' id='subject'>
                      {ticket.subject && ticket.subject.length >= 30
                        ? `${ticket.subject.substring(0, 15)}...`
                        : ticket.subject}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default FilterTicketDetails;