import { SimpleSpeedChange } from './SimpleSpeedChange';
import { SteppedSpeedChange } from './SteppedSpeedChange';

export interface ScheduleSpeedChange {
  id?: string;
  userId?: string;
  wellId?: string;
  wellName?: string;
  groupName?: string;
  deviceId?: string;
  simpleSpeedChanges?: SimpleSpeedChange[] | null;
  steppedSpeedChanges?: SteppedSpeedChange[] | null;
  createdByName?: string;
  status: number;
  statusMessage: string;
}

export enum scheduleStatus {
  Pending = 0,
  InProgress = 1,
  Completed = 2,
  Failed = 3,
  Cancelled = 4,
  Shutdown = 5
}

export enum scheduleStatusMessage {
  Pending = 'Speed change scheduled',
  InProgress = 'Speed change in progress',
  Completed = 'Speed change completed',
  Failed = 'Speed change failed',
  Cancelled = 'Speed change canceled',
  Shutdown = 'Shutdown'
}