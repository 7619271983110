import React, { useState, useEffect, useCallback, useRef } from 'react';
import DocumentTable from './components/DocumentTable';
import { AssetDocument } from './model/AssetDocument';
import DocumentModal from './components/DocumentModal';
import { cleanDocumentList, createDocument, documentDownloadLookup, downloadDocument, downloadQbAssetDocument, fetchDocument } from './AssetDocumentsSlice';
import ErrorMessage from './documentationError/ErrorMessage';
import { ToastContainer, toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import './AssetDocuments.scss';
import { AppUser } from '../../../user/model/AppUser';
import filtericon from '../../../../images/filter-icon.svg';
import chevronDown from '../../../../images/chevron-down.svg';
import closeIcon from '../../../../images/x-close-2.svg'
import Loader from '../../../common/page-loader/ComponentLoader';
import { updateAssetControlScanStatus } from '../../../asset/AssetControlSlice';
import axios from 'axios';
import DownloadError from './documentationError/DownloadError';
import DuplicateErrorModal from './documentationError/DuplicateErrorModal';

interface TabsProps {
  value?: string | undefined;
}

const AssetDocuments: React.FC<TabsProps> = ({ value }) => {
  const wellId = value || '';
  const dispatch = useAppDispatch();
  const uploadHandledRef = useRef(false);
  const [isDashboardDropdownOpen, setIsDashboardDropdownOpen] = useState(false);
  const [uploadDocument, setUploadDocument] = useState<AssetDocument | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [operator, setOperator] = useState<string>('');
  const [assignedCategory, setAssignedCategory] = useState<string>('');
  const [eventDate, setEventDate] = useState<Date | null>(null);
  const [tempSelectedCategories, setTempSelectedCategories] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [modalOpenSource, setModalOpenSource] = useState<'upload' | 'dragAndDrop' | null>(null);
  const [selectedDocumentCount, setSelectedDocumentCount] = useState(0);
  const [showFileColumn, setShowFileColumn] = useState(false);
  
  const [multiDownloadLoading, setMultiDownloadLoading] = useState(false)
  const [isDownloadError, setIsDownloadError] = useState(false)
  const [downloadSuccessCount, setDownloadSuccessCount] = useState(0)
  const [totalDownloadCount, setTotalDownloadCount] = useState(0)
  const [totalUploadCount, setTotalUploadCount] = useState(0)
  const [successUploadCount, setSuccessUploadCount] = useState(0)
  const [selectedFileName, setSelectedFileName] = useState('')
  const [invalidFiles, setInvalidFiles] = useState<{ size: number, name: string }[]>([]);
  const [isDuplicateErrorModal, setIsDuplicateErrorModal] = useState(false);
  const toggleDashboardDropdown = () => {
    setIsDashboardDropdownOpen(!isDashboardDropdownOpen);
  };

  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const { downloadLoading, message } = useAppSelector((state) => state?.assetDocument);
  const [errorType, setErrorType] = useState<string | null>(null);
  const assetControlData = useAppSelector((state) => state?.assetControl)
  const MAX_DOCUMENT_DOWNLOAD_COUNT = useAppSelector((state) => state?.assetDocument?.MAX_DOCUMENT_DOWNLOAD_COUNT)
  const DOCUMENT_DOWNLOAD_TIMEOUT_SECONDS = useAppSelector((state) => state?.assetDocument?.DOCUMENT_DOWNLOAD_TIMEOUT_SECONDS)
  const MAX_DOCUMENT_UPLOAD_COUNT = useAppSelector((state) => state?.assetDocument?.MAX_DOCUMENT_UPLOAD_COUNT)
  const MAX_DOCUMENT_UPLOAD_SIZE = useAppSelector((state) => state?.assetDocument?.MAX_DOCUMENT_UPLOAD_SIZE) * 1024 * 1024
  const allowedTypes = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
  ];

  useEffect(() => {
    dispatch(documentDownloadLookup("Well Groups"))
  }, [])

  const closeModal = () => {
    setIsDocumentModalOpen(false);
  };

  const ErrorModal = () => {
    setIsErrorModal(false);
  };

  const handleDuplicateErrorModal = () => {
    setIsDuplicateErrorModal(false);
    resetDownloadUploadStates()
  }

  let errorMessage = '';
  switch (errorType) {
    case 'fileType':
      errorMessage =
        `${invalidFiles.length > 0 ? "These file types are not" : "This file type is not"} supported. Please try again with one of the following accepted file types: PDF, Excel, and Word. ${successUploadCount} of ${totalUploadCount} files successful.`;
      break;
    case 'fileSize':
      errorMessage =
        `The following files exceed our file size limit of 10MB. Please reduce the file size and try again. ${successUploadCount} of ${totalUploadCount} files successful.`;
      break;
    case 'fileMaxNum':
      errorMessage = `Max files to upload exceeded ${MAX_DOCUMENT_UPLOAD_COUNT} files. ${successUploadCount} of ${totalUploadCount} files successful.`;
      break;
    case 'duplicateFile':
      errorMessage = `The following file ${invalidFiles.length > 1 ? "names" : "name"} already exists. Please rename and try again. ${successUploadCount} of ${totalUploadCount} files successful.`;
      break;
    default:
      errorMessage = message ? message.toString() : '';
  }

  useEffect(() => {
    if (wellId) {
      dispatch(fetchDocument({ userId: storedUser.id, wellId: wellId }));
    }
    if (assetControlData?.assetControlScanStatus)
      dispatch(updateAssetControlScanStatus(false))
  }, [wellId, assetControlData?.assetControlScanStatus == true]);

  const dashboardOptions = ['Install', 'Pull', 'Design', 'DIFA', 'Service', 'Survey', 'Other'];

  const closeDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleAddDocument = useCallback(() => {
    const newUploadDocument: AssetDocument = {
      userId: storedUser?.id,
      wellId: wellId,
      expires: new Date().toISOString(),
      operator: operator,
      category: assignedCategory,
      eventDate: eventDate,
      files: uploadedFiles,
      updatedAt: new Date().toString(),
      updatedBy: '',
      updatedByName: '',
      fileName: '',
      fileUrl: '',
      selected: false,
      source: '',
      size: undefined
    };

    setUploadDocument(newUploadDocument);
    setIsDocumentModalOpen(true);
    closeDropdown();
  }, [operator, assignedCategory, eventDate]);

  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>, category: string) => {
    if (e.target.checked) {
      setTempSelectedCategories([...tempSelectedCategories, category]);
    } else {
      setTempSelectedCategories(tempSelectedCategories.filter((c) => c !== category));
    }
  };

  useEffect(() => {
    const handleDrop = async (e: DragEvent) => {
      setInvalidFiles([]);
      e.preventDefault();
      e.stopPropagation();
      setModalOpenSource('dragAndDrop');
      uploadHandledRef.current = false;
      if (!e.dataTransfer) {
        return;
      }
      let files = e.dataTransfer.files;
      if (files?.length === 0) {
        return;
      }
      setTotalUploadCount(files.length)

      const invalidTypeFiles = Array.from(files).filter((file) => !allowedTypes.includes(file.type)).map((file) => ({ size: file.size, name: file.name }));
      setInvalidFiles(invalidTypeFiles);
      if (invalidTypeFiles.length > 0) {
        setErrorType('fileType');
        setIsErrorModal(true);
        toast.error('upload failed');
        return;
      }
      const oversizedFilesArray = Array.from(files).filter((file) => file.size > MAX_DOCUMENT_UPLOAD_SIZE).map((file) => ({ size: file.size, name: file.name }));
      setInvalidFiles(oversizedFilesArray);
      if (oversizedFilesArray.length > 0) {
        setErrorType('fileSize');
        setIsErrorModal(true);
        toast.error('upload failed');
        return;
      }

      const invalidFilesArr = Array.from(files).slice(MAX_DOCUMENT_UPLOAD_COUNT).map((file) => ({ size: file.size, name: file.name }));
      setInvalidFiles(invalidFilesArr);
      files = Array.from(files).slice(0, MAX_DOCUMENT_UPLOAD_COUNT) as unknown as FileList;




      // Update the uploadDocument state with the dropped files
      setUploadedFiles(Array.from(files));
      handleAddDocument();
    };

    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    document.addEventListener('drop', handleDrop);
    document.addEventListener('dragover', handleDragOver);

    return () => {
      document.removeEventListener('drop', handleDrop);
      document.removeEventListener('dragover', handleDragOver);
    };
  }, [handleAddDocument, uploadedFiles]);

  useEffect(() => {
    if (!uploadHandledRef.current && uploadDocument) {
      handleAddDocument();
      uploadHandledRef.current = true;
    }
  }, [uploadDocument]);

  const [selectedDocuments, setSelectedDocument] = useState<AssetDocument[]>([]);

  const onFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    let files = input.files;
    if (!files?.length) {
      return;
    }
    setTotalUploadCount(files.length);

    const invalidTypeFiles = Array.from(files).filter((file) => !allowedTypes.includes(file.type)).map((file) => ({ size: file.size, name: file.name }));
    setInvalidFiles(invalidTypeFiles);
    if (invalidTypeFiles.length > 0) {
      setErrorType('fileType');
      setIsErrorModal(true);
      e.target.value = '';
      toast.error('upload failed');
      return;
    }

    const oversizedFilesArr = Array.from(files).filter((file) => file.size > MAX_DOCUMENT_UPLOAD_SIZE).map((file) => ({ size: file.size, name: file.name }));
    setInvalidFiles(oversizedFilesArr);
    if (oversizedFilesArr.length > 0) {
      setErrorType('fileSize');
      setIsErrorModal(true);
      e.target.value = '';
      toast.error('upload failed');
      return
    }

    const invalidFilesArr = Array.from(files).slice(MAX_DOCUMENT_UPLOAD_COUNT).map((file) => ({ size: file.size, name: file.name }));
    setInvalidFiles(invalidFilesArr);

    files = Array.from(files).slice(0, MAX_DOCUMENT_UPLOAD_COUNT) as unknown as FileList;


    // Update the uploadDocument state with the dropped files
    setUploadedFiles(Array.from(files));
    handleAddDocument();
    e.target.value = '';

  };

  const handleUpload = async () => {
    if (uploadedFiles?.length > 0) {
      const newUploadDocument: AssetDocument = {
        userId: storedUser?.id,
        wellId: wellId,
        expires: new Date().toISOString(),
        operator: operator,
        category: assignedCategory,
        eventDate: eventDate,
        files: uploadedFiles,
        updatedAt: new Date().toString(),
        updatedBy: '',
        updatedByName: '',
        fileName: '',
        fileUrl: '',
        selected: false,
        source: '',
        size: undefined
      };

      const formData = new FormData();
      formData.append('userId', newUploadDocument.userId);
      formData.append('wellId', newUploadDocument.wellId);
      formData.append('expires', newUploadDocument.expires);
      formData.append('operator', operator);
      formData.append('category', assignedCategory);
      formData.append('eventDate', eventDate ? eventDate?.toISOString() : '');
      Array.from(newUploadDocument.files).forEach((file) => {
        formData.append('files', file);
      });

      const result: any = await dispatch(createDocument(formData));
      if (result?.error) {
        const errorMessage = result?.payload;

        if (errorMessage.includes('Files name already exists')) {
          const filesString = errorMessage.split(':')[1].trim();
          const fileNames = filesString.split(',');

          const invalidFiles = Array.from(uploadedFiles).filter((file) => fileNames.includes(file.name));

          setInvalidFiles(invalidFiles);
          setErrorType('duplicateFile');
          setIsDuplicateErrorModal(true);
          toast.error('upload failed');
          return;
        }

      } else {
        setSuccessUploadCount(uploadedFiles.length)
        setOperator('');
        setAssignedCategory('');
        setEventDate(null);
        setIsDocumentModalOpen(false);
        setUploadedFiles([]);
        toast.success('upload successful');

        if (invalidFiles.length > 0) {
          setErrorType('fileMaxNum');
          setIsErrorModal(true);

        }
        dispatch(fetchDocument({ userId: storedUser.id, wellId: wellId }));

      }
    }
  };
  const handleDownloadSelectedDocuments = async () => {
    if (selectedDocuments?.length === 0) return;
    const totalCount = selectedDocuments?.length;
    let selectedDocumentsArr = selectedDocuments;
    if (selectedDocuments?.length > MAX_DOCUMENT_DOWNLOAD_COUNT) {
      setSelectedDocument(selectedDocuments.slice(0, MAX_DOCUMENT_DOWNLOAD_COUNT));
      selectedDocumentsArr = selectedDocuments.slice(0, MAX_DOCUMENT_DOWNLOAD_COUNT);
    }


    let successCount = 0;
    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    const invalidFilesArr: any = []; // Local array to store invalid files
    setTotalDownloadCount(totalCount);

    try {
      setMultiDownloadLoading(true);
      const cancelTokenSource = axios.CancelToken.source();

      const timeoutPromise = new Promise((_, reject) => {
        timeoutId = setTimeout(() => {
          reject(setIsDownloadError(true));
          cancelTokenSource.cancel('Asset document download is canceled due to timeout');
          setMultiDownloadLoading(false);
          setSelectedFileName('');
        }, DOCUMENT_DOWNLOAD_TIMEOUT_SECONDS * 1000);
      });

      const downloadPromises = selectedDocumentsArr.map(async (doc) => {
        try {
          let fileData;
          if (doc.source === 'User') {
            const actionResult = await dispatch(downloadDocument({ id: String(doc.id), cancelToken: cancelTokenSource.token }));
            fileData = actionResult.payload;
          } else {
            const response = await dispatch(downloadQbAssetDocument({ wellId: doc.wellId, fileName: doc.fileName, cancelToken: cancelTokenSource.token }));
            if (response.payload) {
              fileData = response.payload;
            }
          }

          if (!fileData) {
            throw new Error(`Failed to download document: ${doc.fileName}`);
          }

          // Create URL for file download
          const url = window.URL.createObjectURL(fileData);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', doc.fileName); // Use doc.fileName
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // Clean up after download
          successCount++; // Increment success count
        } catch (error) {
          console.error(`Error downloading ${doc.fileName}:`, error);
          // Push the failed download to the local invalidFilesArr array
          invalidFilesArr.push({ size: doc.size, name: doc.fileName });
        }
      });

      await Promise.race([
        Promise.all(downloadPromises), // Resolves if all downloads complete successfully
        timeoutPromise, // Rejects if timeout exceeds before downloads finish
      ]);

      if (timeoutId) {
        clearTimeout(timeoutId); // Clear timeout when downloads are complete
      }
    } catch (error) {
      console.log('Error downloading documents:', error);
    } finally {
      setDownloadSuccessCount(successCount);

      if (successCount === totalCount) {
        toast.success('Download successful');
      } else {
        const invalidFilesArr = selectedDocuments.slice(successCount).map((doc) => ({ size: doc.size, name: doc.fileName }));
        setInvalidFiles(invalidFilesArr); // Update state with failed downloads
        setIsDownloadError(true); // Set download error flag if any files failed
      }
      setMultiDownloadLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!e.target) return;
      const target = e.target as HTMLElement;
      if (!target.closest('.custom-dropdown')) {
        setIsDashboardDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleFileColumnToggle = () => {
    setShowFileColumn(!showFileColumn); // Step 2: Toggle checkbox value
  };

  const handleCancelFilter = () => {
    setTempSelectedCategories([])
    setSelectedCategories([])
    setIsDashboardDropdownOpen(!isDashboardDropdownOpen);
  }

  const handleApplyFilter = () => {
    setSelectedCategories(tempSelectedCategories)
    setIsDashboardDropdownOpen(!isDashboardDropdownOpen);
  }


  const handleCloseDownloadError = () => {
    setIsDownloadError(false)
    resetDownloadUploadStates()
  }

  const showDownloadErrorForSingleDocument = (fileName: string) => {
    setIsDownloadError(true)
    setSelectedFileName(fileName)
    setTotalDownloadCount(0)
    setDownloadSuccessCount(0)
  }

  const resetDownloadUploadStates = () => {
    setTotalDownloadCount(0)
    setDownloadSuccessCount(0)
    setSuccessUploadCount(0)
    setTotalUploadCount(0)
    setSuccessUploadCount(0)
    setSelectedFileName('')
    setInvalidFiles([])
  }

  useEffect(()=>{
    dispatch(cleanDocumentList());
  },[]);

  return (
    <>
      <div className='profile-container w-100 py-6 px-8'>
          <div className='asset-documents profile-status-header'>
            <div className='tab-content set-point-wrap'>
              <div className='card profile-card m-0'>
                <div className='header'>
                  <div className='flex flex-row w-100 doc-title'>
                    <p className='title'>Documentation </p>
                    {/* <p className=''>
                      <button className='dropdown-btn'>
                        <span className='dot'></span>
                        <span className='dot'></span>
                        <span className='dot'></span>
                      </button>
                    </p> */}
                   {downloadLoading || multiDownloadLoading ? (<div className='asset-documnet-loader flex items-center justify-center w-100'>
                      <Loader />
                    </div>
                   ): null}
                  </div>
                  <div className='divider'></div>
                  <div className='flex flex-row w-100'>
                    <div className='basis-2/5 document-table-filter-container'>
                      <span className='selected-filters-text'>{selectedDocumentCount} selected</span>
                      <span className='selected-category-document-parent'>
                        {
                          selectedCategories.length > 0 && selectedCategories.map((category, index) => (
                            <div key={index} className='selected-category-document'>
                              <span className='filter-test-category'>{category}</span>
                              <button className='close-icon' onClick={() => {
                                setSelectedCategories(selectedCategories.filter((c) => c !== category))
                                setTempSelectedCategories(tempSelectedCategories.filter((c) => c !== category))
                              }}>
                                <img src={closeIcon} alt='' />
                              </button>
                            </div>
                          ))
                        }
                      </span>
                    </div>
                    <div className='basis-3/5 flex justify-end'>
                      <div className='flex place-content-end'>
                        <div className='filename-container'>
                          <label className='checkbox-container'>
                            <input
                              type='checkbox'
                              id='showCheckbox'
                              checked={showFileColumn}
                              onChange={handleFileColumnToggle}
                            />
                            <span className='checkbox-checkmark'></span>
                          </label>
                          <label htmlFor='showCheckbox' className='ml-5'>&nbsp;File name &nbsp;</label>
                        </div>
                        <button className='ico-btn dwnld' onClick={handleDownloadSelectedDocuments}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='w-6 h-6'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3'
                            />
                          </svg>
                        </button>
                        <div className='w-80'>
                          <label className='relative block'>
                            <span className='sr-only'>Search</span>
                            <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
                              <svg className='h-5 w-5 fill-slate-300' viewBox='0 0 20 20'>
                                <path
                                  fill-rule='evenodd'
                                  d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                                  clip-rule='evenodd'
                                ></path>
                              </svg>
                            </span>
                            <input
                              className='custom-text-input'
                              placeholder='Search...'
                              type='text'
                              name='search'
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                            />
                          </label>
                        </div>
                        <div className='w-64'>
                          <div className='cust-drop'>
                            <div
                              className={`custom-dropdown ${isDashboardDropdownOpen ? 'open' : ''}`}
                            >
                              <div className='selected-option' onClick={toggleDashboardDropdown}>
                                <img src={filtericon} alt='filter-icon' className='pr-3' />
                                Filter by category <span>{`(${tempSelectedCategories?.length})`}</span> <img src={chevronDown} alt='chevron-down' className='pl-2' />
                              </div>
                              {isDashboardDropdownOpen && (
                                <div className='category-checkboxes'>
                                  <p className='category-header'>Filter by category</p>
                                  {dashboardOptions.map((option) => (
                                    <div key={option} className='category-option'>
                                      <label className='checkbox-container'>
                                        <input
                                          type='checkbox'
                                          id={option}
                                          value={option}
                                          checked={tempSelectedCategories.includes(option)}
                                          onChange={(e) => handleCategoryChange(e, option)}
                                        />
                                        <span className='checkbox-checkmark'></span>
                                      </label>
                                      <label htmlFor={option} className='ml-6'>{option}</label>
                                    </div>
                                  ))}
                                  <div className='flex flex-row justify-end gap-3'>
                                    <button className='btn btn-secondary' onClick={handleCancelFilter}>Cancel</button>
                                    <button className='btn btn-primary' onClick={handleApplyFilter}>Apply</button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className='ml-4 upload-containter'>
                            <input type='file' className='upload-field' onChange={onFileSelect} multiple />
                            <button className='btn btn-primary border border-danger'>
                              <span className='btn-icon'>+</span> Upload
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='body user-details-body'>
                  <div className='adminuser-block'>
                    <div className='flex flex-row'>
                      <DocumentTable
                        selectedCategories={selectedCategories}
                        searchTerm={searchTerm}
                        onGetSelectedDocuments={(count) => setSelectedDocumentCount(count)}
                        showFileColumn={showFileColumn}
                        onSelectDocument={setSelectedDocument}
                        showDownloadErrorForSingleDocument={showDownloadErrorForSingleDocument}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <ToastContainer position='top-right' autoClose={3000} />
      <DocumentModal
        isOpen={isDocumentModalOpen}
        onClose={() => {
          closeModal();
          setModalOpenSource(null); // Reset the modal open source
        }}
        onSave={handleUpload}
        operator={operator}
        setOperator={setOperator}
        assignedCategory={assignedCategory}
        setAssignedCategory={setAssignedCategory}
        eventDate={eventDate !== null ? new Date(eventDate) : null}
        setEventDate={setEventDate}
        showFileInput={modalOpenSource === 'upload'} // Only pass the file selection handler when the modal is opened by the "Upload" button
      />
      {
        isErrorModal &&
        <ErrorMessage invalidFiles={invalidFiles} isErrorModal={isErrorModal} isErrorModalClose={ErrorModal} errorMessage={errorMessage} />
      }
      {
        isDuplicateErrorModal &&
        <DuplicateErrorModal invalidFiles={invalidFiles} isErrorModal={isDuplicateErrorModal} isErrorModalClose={handleDuplicateErrorModal} errorMessage={errorMessage} />
      }

      {
        isDownloadError &&
        <DownloadError invalidFiles={invalidFiles} isErrorModal={isDownloadError} onClose={handleCloseDownloadError} totalCount={totalDownloadCount} successCount={downloadSuccessCount} fileName={selectedFileName} maxDownloadFileCount={MAX_DOCUMENT_DOWNLOAD_COUNT} />
      }
    </>
  );
};

export default AssetDocuments;
