import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserPrefrenceDataItem } from "./components/interfaces/userPrefrence.interface";
import { userPreferences } from "./controlroomService";

interface ControlRoomState {
    userPrefrence: UserPrefrenceDataItem[];
    loading: boolean;
    error: string | null;
}

const initialState: ControlRoomState = {
    userPrefrence: [],
    loading: false,
    error: null
}

export const fetchUserPrefrence = createAsyncThunk(
    'controlRoom/fetchUserPrefrence',
    async (dashboard: string) => {
        return await userPreferences(dashboard);
    }
);

const controlroomSlice = createSlice({
    name: 'controlRoom',
    initialState,
    reducers: {
        toggleVisibility: (state, action: PayloadAction<string>) => {
            const item = state.userPrefrence.find(i => i.key === action.payload);
            if (item) {
              item.show = !item.show;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserPrefrence.fulfilled, (state, action) => {
            state.userPrefrence = action.payload.data.data;
            state.loading = false;
        });
        builder.addCase(fetchUserPrefrence.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUserPrefrence.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        });
    }
});

export const { toggleVisibility } = controlroomSlice.actions;
export default controlroomSlice.reducer;
//export const controlroomActions = controlroomSlice.actions;