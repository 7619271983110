import { apiInstance, http } from "../../../config/HttpCommon";

export const getWellProductionTrendsByAssetName = async (userId: string, startDate: string, endDate: string, assetName: string) => {
    const response = await http.get(`/getWellProductionTrends/${userId}/${startDate}/${endDate}/All Wells`,
        {
            params: {
                assetName: assetName
            }
        }
    );
    return response.data;
};

export const getMasterVariablesData = async (wellName: string) => {
    const response = await http.get(`/Mastervariables/${wellName}?channelType=standardparameters`);
    return response.data;
}

export const getTimeseriesData = async (assetName: string, startDate: string, endDate: string, variables: string) => {
    const response = await apiInstance.get(`/v1/timeseries`, {
        params: {
            assetName: assetName,
            startDate: startDate,
            endDate: endDate,
            variables: variables
        }
    });
    return response.data;
}

export const getAssetTimeseriesChannelsData = async (AssetName: string, StartDate: string, EndDate: string, ChannelIds: string) => {
    const response = await http.get(`/GetAssetTimeseriesChannelsData/`, {
        params: {
            AssetName,
            StartDate,
            EndDate,
            ChannelIds
        }
    }
    );
    return response.data;
}