import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import annotation_plus from './../../../../images/expand-icon.svg';
import download from '../../../../../src/images/download-icon.svg';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoData from '../../../dashboard/components/no-data/NoData';
import BackPressureModal from './modals/BackPressureModal';
import { BackPressureDetailsProps } from './interfaces/backPressure.interface';
import { bandsData } from './constants/backPressure.constants';
import BackPressureChart from './charts/BackPressureChart';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { getChannelDataByKey, getTimeSeriesDataByKey } from '../../wellControlroomSlice';
import { TimeseriesData } from '../../interfaces/timeSeries.interface';
import { AssetTimeseriesData } from '../../interfaces/timeSeriesChannelIdsData.interface';
import championx from '../../../../../src/images/championxLogo.png';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import moment from 'moment-timezone';

const CHART_NAME = 'back-pressure';

const BackPressureDetails: React.FC<BackPressureDetailsProps> = ({ heading }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const backPressureChartdiv1 = useRef<HTMLDivElement | null>(null);
    const backPressureChartdiv2 = useRef<HTMLDivElement | null>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const hElement = document.getElementById('main-root');
    const [data, setData] = useState<{
        valveRating: TimeseriesData | AssetTimeseriesData;
    }>({
        valveRating: {} as TimeseriesData | AssetTimeseriesData,
    });
    const loading = useAppSelector((state) => state.wellControlroomCharts.loading);
    const userPrefrence = useAppSelector((state) => state.controlroom.userPrefrence)?.find(item => item.key === CHART_NAME);
    const assetInfo = useAppSelector((state) => state.assetInfo.assetDetails);
    const channelIDsObj = useAppSelector((state) => state.wellControlroomCharts.channelIDsObj);
    const currentUser = useAppSelector((state) => state?.user?.currentUser);

    const root = useRef<any>(null);
    const root2 = useRef<any>(null);

    const backPressureChannelData = {
        valveRating: useAppSelector(getChannelDataByKey(channelIDsObj.ambient || '')),
    }

    const backPressureTimeSeriesData = {
        valveRating: useAppSelector(getTimeSeriesDataByKey(userPrefrence?.mappings?.sle?.ambient || '')),
    }

    useEffect(() => {
        if (assetInfo?.isSleAsset) {
            setData(backPressureTimeSeriesData);
        } else {
            setData(backPressureChannelData);
        }

    }, [])


    const openModal = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setModalIsOpen(true);
        hElement && hElement?.classList.add('modal-open');
    };
    const handleClose = () => {
        setModalIsOpen(false);
        hElement && hElement?.classList.remove('modal-open');
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const printWidgetAsPDF = () => {
        if (root.current) {
          const exporting = am5plugins_exporting.Exporting.new(root.current, {
            menu: am5plugins_exporting.ExportingMenu.new(root.current, {}),
          });
          Promise.all([exporting.getPdfmake(),
          exporting.export('png')]).then((res) => {
            const pdfMake = res[0];
            const str = moment().format('MMM-DD-YYYY hh:mm:ss A');
            const doc = {
              pageSize: 'A4',
              pageOrientation: 'portrait',
              pageMargins: [30, 30, 30, 30],
              footer: {
                columns: [
                  {
                    text: `Generated By: ${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`,
                    alignment: 'left',
                    width: '50%',
                    bold: true,
                    margin: [10, 10],
                    fontSize: 9,
                    color: 'black',
                    decoration: 'underline',
                  },
                  {
                    text: 'Generated on : ' + str,
                    alignment: 'right',
                    width: '50%',
                    bold: true,
                    margin: [10, 10],
                    fontSize: 9,
                    color: 'black',
                    decoration: 'underline',
                  },
                ],
              },
              content: [] as any[],
              styles: {
                tblheader: {
                  fontSize: 9.5,
                  color: 'white',
                  fillColor: '#01485e',
                  alignment: 'center',
                },
              },
            };
    
            const rect = {
              type: 'rect',
              x: -32,
              y: -20, //could be negative to account for padding
              w: 450,
              h: 25,
              linearGradient: ['#01485e', '#01485e'],
            };
            const rectHead = {
              type: 'rect',
              x: -32,
              y: -20, //could be negative to account for padding
              w: 150,
              h: 25,
              linearGradient: ['#484848', '#484848'],
            };
            doc.content.push({
              columns: [
                {
                  stack: [
                    { canvas: [rect] },
                    {
                      columns: [
                        {
                          width: rect.w / 2,
                          noWrap: false, //clip at with by wrapping to next line and...
                          maxHeight: rect.h, //don't show the wrapped text
                          image: championx,
                          fit: [55, 55],
                          margin: [5.5, 7.5, 3],
                          background: 'white',
                        },
                        {
                          text: 'Landing Page',
                          width: rect.w / 2,
                          alignment: 'left',
                          bold: true,
                          margin: [35, 5],
                          fontSize: 13,
                          color: 'white',
                        },
                      ],
                      relativePosition: {
                        x: rect.x,
                        y: -rect.h,
                      },
                    },
                  ],
                },
                {
                  stack: [
                    { canvas: [rectHead] },
                    {
                      columns: [
                        {
                          width: rectHead.w,
                          noWrap: false, //clip at with by wrapping to next line and...
                          maxHeight: rect.h, //don't show the wrapped text
                          text: 'LOOKOUT',
                          color: 'white',
                          bold: true,
                          alignment: 'right',
                          'font-size': 10,
                        },
                      ],
                      relativePosition: {
                        x: -80,
                        y: -20,
                      },
                    },
                  ],
                },
              ],
            });
            doc.content.push({
              text: ' ',
              width: '100%',
            });
    
            doc.content.push(
              {
                text: 'Back Pressure',
                width: '*',
                alignment: 'center',
                fontSize: 18,
                margin: [0, 0, 0, 5],
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 175,
                    y1: 0,
                    x2: 362,
                    y2: 0,
                    lineWidth: 1,
                  },
                ],
                margin: [0, 0, 0, 15],
              },
            );
    
            doc.content.push({
              image: res[1],
              alignment: 'center',
              width: 400,
              height: 180,
              margin: [0, 0, 0, 20],
            });
  
            const fileName = 'Lookout-back-pressure_' + str + '.pdf';
    
            pdfMake.createPdf(doc).download(fileName);
          }).finally(() => {
            setIsDropdownOpen(false);
    
          });
        }
      };

    return (
        <>
            <ToastContainer position='top-right' autoClose={3000} />
            <div className='grid-item-text h-full graph-card well-graph-card'>
                <div className='table-header-section pie-section'>
                    <div className='title'>{heading}</div>
                    {userPrefrence?.expandable && <div className='header-icon'>
                        <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
                            <img src={annotation_plus} alt='Expand' className='img-border' />
                        </button>
                    </div>}
                    {userPrefrence?.actions.show && <div className='mr-4 dropdown-container' ref={dropdownRef}>
                        <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                            <span className='dot'></span>
                            <span className='dot'></span>
                            <span className='dot'></span>
                        </button>
                        {isDropdownOpen && (
                            <div className='dropdown-options'>
                                <button>Actions</button>
                                <button className={`btn btn-default cancelSelectorName`} onClick={printWidgetAsPDF}>
                                    <img src={download} alt='icon' className='btn-icon' />
                                    Download PDF
                                </button>
                                <button className={`btn btn-default cancelSelectorName`}>
                                    <img src={download} alt='icon' className='btn-icon' />
                                    Download CSV
                                </button>
                            </div>
                        )}
                    </div>}
                </div>
                <hr />
                <div id="backPressure-container" className='flex flex-wrap items-center justify-center wellChartSection backPressureChart-container-dashboard nodata'>

                    {loading ? (
                        <div className='flex items-center justify-center w-100'>
                            <Loader />
                        </div>
                    ) : data?.valveRating ? (
                        <>
                            <BackPressureChart value={+data?.valveRating?.value || 0} chartID={'backPressureChartdiv1'} chartRef={backPressureChartdiv1} classValue={'w-[100%]'} minValue={0} maxValue={1500} clockHandValue={600} clockHandName={'55 %'} startAngle={-220} endAngle={40} innerCircleEndvalue={1500} bandsData={bandsData} spacingValue={25} root={root} />
                        </>
                    ) : (<NoData heading='No data found' />)}
                    {!modalIsOpen && <NoData heading='No data found' />}

                </div>
            </div>
            <BackPressureModal
                isOpen={modalIsOpen}
                onRequestClose={handleClose}
                heading={'Back pressure'}
                loading={loading}
                modelWidth={'60rem'}
                modelClass={'wellChartModal'}
                innerComponent={
                    <>


                        {loading ? (
                            <div className='flex items-center justify-center w-100'>
                                <Loader />
                            </div>
                        ) : data?.valveRating ? (
                            <>
                                <BackPressureChart value={+data?.valveRating?.value || 0} chartID={'backPressureChartdiv2'} chartRef={backPressureChartdiv2} classValue={'w-[100%]'} minValue={0} maxValue={1500} clockHandValue={600} clockHandName={'55 %'} startAngle={-220} endAngle={40} innerCircleEndvalue={1500} bandsData={bandsData} spacingValue={25} root={root2} />
                            </>
                        ) : (<NoData heading='No data found' />)}
                        {!modalIsOpen && <NoData heading='No data found' />}
                    </>
                }
            />
        </>
    );
};

export default BackPressureDetails;