
import { Role } from "../models/Role";
import { AggregateProps } from "../features/asset-details/components/details-table/AssetDetailSlice";
// import moment from "moment-timezone";

export const MapRolesToEnum = (roles: string[]): Role[] => {
    const roleEnumValues: Role[] = roles.map((role) => Role[role as keyof typeof Role]);
    return roleEnumValues;
};

// export const ConvertUTCToLocal = (utcDate: Date, isEventDate: boolean): string => {
//     return (isEventDate) ? moment.utc(utcDate).tz(moment.tz.guess()).format("MM/DD/YYYY") : moment.utc(utcDate).tz(moment.tz.guess()).format("MM/DD/YYYY HH:mm:ss");
// };

export const ConvertUTCToLocal = (utcDate: Date, isEventDate: boolean): string => {
    const newDate = new Date(utcDate);

    if (isEventDate) {
        return newDate.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' });
    } else {
        const formattedDate = newDate.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' });
        const formattedTime = newDate.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', second: '2-digit' });
        return `${formattedDate} ${formattedTime}`;
    }
};

export const getUrlsArray = (urls: []) => {
    const urlsArray: any = [];
    urls.forEach((url: any) => {
        urlsArray.push({
            value: url?.value,
            key: url?.key,
        });
    });
    return urlsArray;
}

export const capitalizeFirstInWord = (s: string) => {
    if (typeof s !== 'string') {
        return '';
    }
    const arr = s.split(' ');
    for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].toLowerCase().slice(1);
    }
    return arr.join(' ');
}

export const GetLocalTimeFormat = () => {
    const date = new Date();
    const formattedDate = `${date.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' })} ${date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', second: '2-digit' })}`;
    return formattedDate;
}

export const ConvertTimeToLocal = (date: any) => {

    if(date.includes("z") || date.includes("Z")){
        const newDate = new Date(date);
        const formattedDate = `${newDate.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' })} ${newDate.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', second: '2-digit' })}`;
        return formattedDate;
    } else{
        const newDate = new Date(date + 'Z');
        const formattedDate = `${newDate.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' })} ${newDate.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', second: '2-digit' })}`;
        return formattedDate;
    }
}


export const GetAggregation = (from: Date, to: Date, aggregateValues: AggregateProps[]) => {
    // console.log("zoomDirection", zoomDirection, aggregateValues)
    if (aggregateValues?.length === 0) return ''
    const fromTime = new Date(from).getTime()
    const toTime = new Date(to).getTime()
    const differenceMinute = (toTime - fromTime) / (1000 * 60)
    const sortedAggregations = [...aggregateValues]?.sort((a, b) => a.minutes - b.minutes);
    // console.log("differenceMinute", differenceMinute)
    // Iterate over the sorted aggregations
    for (const aggregate of sortedAggregations) {
        if (differenceMinute <= aggregate.minutes) {
            return aggregate.aggregate; // Return the aggregate if the condition matches
        }
    }
    // If no match is found, return the aggregate from the last item
    return sortedAggregations[sortedAggregations.length - 1].aggregate;

}

export const CalculateRunDays = (date: any): string => {
    if (!date || typeof date !== 'string' || isNaN(new Date(date).getTime())) {
        return "0 days"; // Handle null, empty, or invalid date
    }

    // Parse the date correctly considering possible timezone formatting
    const installationDate = date.includes("z") || date.includes("Z")
        ? new Date(date)
        : new Date(date + 'Z');
    
    const currentDate = new Date();

    // Calculate the difference in days
    const runDays = Math.floor((currentDate.getTime() - installationDate.getTime()) / (1000 * 60 * 60 * 24));

    // Return the result with appropriate singular/plural form
    return runDays === 1 ? "1 day" : `${runDays} days`;
};

