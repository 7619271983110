import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SpeedServices from "../services/speed.services";


const initialState = {
    xspocSpeedData: [],
    sleSpeedData: [],
    loading: false,
    error: '',
}

export const fetchXspocSpeedData = createAsyncThunk('getXspocSpeedData', async ({ assetName, channelIds }: { assetName: string, channelIds: string }) => {
   return await SpeedServices.getXspocSpeedData(assetName,channelIds);
})

export const fetchSleSpeedData = createAsyncThunk('fetchSleSpeedData', async ({ assetName, variables }: { assetName: string, variables: string }) => {
    return await SpeedServices.getSleSpeedData(assetName,variables);
})
 

const SpeedChartSlice = createSlice({
    name: 'SpeedChartData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // fetch xspoc SpeedChart thunk cases
        builder.addCase(fetchXspocSpeedData.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchXspocSpeedData.fulfilled, (state, action) => {
            state.xspocSpeedData = action.payload.data;
            state.loading = false;
        })
        builder.addCase(fetchXspocSpeedData.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        })
        builder.addCase(fetchSleSpeedData.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchSleSpeedData.fulfilled, (state, action) => {
            state.sleSpeedData = action.payload.data;
            state.loading = false;
        })
        builder.addCase(fetchSleSpeedData.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        })
    }
});

export default SpeedChartSlice.reducer;