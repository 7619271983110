import { apiInstance } from "../../../config/HttpCommon";


export const getHealthScoreHistoryData = async (assetName: string, StartDate: string, EndDate: string, PageNumber: number, PageSize: number) => {
    const response = await apiInstance.get(`v1/health-score/${encodeURIComponent(assetName)}/history?StartDate=${StartDate}&EndDate=${EndDate}&PageNumber=${PageNumber}&PageSize=${PageSize}`);
    return response.data;
}

export const getHealthScore = async (assetName: string) => {
    const response = await apiInstance.get(`v1/health-score/${encodeURIComponent(assetName)}`);
    return response.data;
}

export const getHealthScoreTargets = async (assetName: string) => {
    const response = await apiInstance.get(`v1/health-score/${encodeURIComponent(assetName)}/health-targets`);
    return response.data;
}

