import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.scss';
import XPSOCImage from '../../../images/Logomark.svg';
import AssetListImage from '../../../images/asset-list.svg';
import WellControlIcon from '../../../images/well-control-icon.svg';
import NotificationImage from '../../../images/notification.svg';
import LogoutImage from '../../../images/logout.svg';
import NotificationBottomIcon from '../../../images/notification-bottom.svg';
import GearIcon from '../../../images/settings-icon.svg';
import admin from '../../../images/admin.svg';
import Tooltip from '../../common/tooltip/ToolTip';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import NavigationDrawerItem from './NavigationDrawerItem';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { setDrawerState } from './NavigationSlice';
import HomeIcon from '../../../images/homeIcon.svg';
import { AppUser } from '../../user/model/AppUser';
// import { logoutSuccess } from '../../user/UserSlice';
import { fetchUserById } from '../../user/UserSlice';
import { Role } from '../../../models/Role';
import { updateSelectedGroupName } from '../AssetGroupsSlice';
import infocircleIcon from '../../../images/info-circle.svg';


const NavigationBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const [expandDrawer, setExpandDrawer] = useState(false);
  const [selectedTooltip, setSelectedTooltip] = useState<string | null>('home'); // Set 'home' as default
  let currentUser = useAppSelector((state) => state.user.currentUser);  
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser | null = storedUserData ? JSON.parse(storedUserData) : null;
  const storedUserGroup = localStorage.getItem('jwtAccessGroups');
  const accessGroups: string[] | null = storedUserGroup ? JSON.parse(storedUserGroup) : null;
  
  if (currentUser?.id != storedUser?.id)
    currentUser = storedUser

  useEffect(() => {
    const fetchUser = async () => {
      if (storedUser) {
        if (currentUser?.id !== storedUser?.id) {
          await dispatch(fetchUserById(storedUser?.id));
        }
      }
    };
    fetchUser();
  }, [currentUser?.id]);


  const handleLogout = () => {
    localStorage.clear();
    const logoutUrl = process.env.REACT_APP_LOGOUT_URL || '';
    window.location.href = logoutUrl;
    // dispatch(logoutSuccess());
    //navigate('/login');
  };

  const handleAssetListClick = () => {
    const shouldDisplayDrawer = !expandDrawer;
    dispatch(setDrawerState(shouldDisplayDrawer));
    setExpandDrawer(shouldDisplayDrawer);
  };

  const handleTooltipClick = (tooltipName: string) => {
    window.scrollTo(0, 0);
    setSelectedTooltip(tooltipName);
  };

  const handleSlideOutClick = (e: any) => {
    if (e.target.id === 'asset-list-image') return;
    if (expandDrawer) {
      dispatch(setDrawerState(!expandDrawer));
      setExpandDrawer(!expandDrawer);
    }
  };

  const ref = useDetectClickOutside({ onTriggered: handleSlideOutClick });

  const handleHomeClick = () => {
    if (accessGroups && accessGroups.length > 0) {
      // const selectedAsset = { assetGroupName: assetGroupName[], assetId: undefined, assetName: undefined, searchString: undefined }
      // dispatch(updateSelectedAsset(selectedAsset));
      dispatch(updateSelectedGroupName(accessGroups[0]));
      localStorage.setItem("selectedGroupName", accessGroups[0]);
      sessionStorage.setItem("selectedGroupName", accessGroups[0]);
    }
  }

  return (
    <>
      <div className='navigation-bar-container'>
        <div className='navigation-bar-top-container'>
          <img id='xspoc-logo-image' src={XPSOCImage} alt='XSPOC Image' className='navigation-bar-logo-image' />

          <Tooltip content='Landing page' direction='right'>
            <Link to='/'>
              <div
                data-testid='asset-dashboard-image'
                className={`navigation-bar-navigation-image-container ${selectedTooltip === 'home' ? 'selected' : ''}`}
                onClick={() => {
                  handleHomeClick();
                  setSelectedTooltip('home');
                }}
              >
                <img id='asset-dashboard-image' src={HomeIcon} alt='Asset Dashboard Image' />
              </div>
            </Link>
          </Tooltip>

          <Tooltip content='Groups and assets' direction='right'>
            <div
              data-testid='group-and-assets-image'
              className={`navigation-bar-navigation-image-container ${selectedTooltip === 'groupsAndAssets' ? 'selected' : ''
                }`}
              onClick={() => {
                handleAssetListClick();
                handleTooltipClick('groupsAndAssets');
              }}
            >
              <img id='asset-list-image' src={AssetListImage} alt='Asset List Image' />
            </div>
          </Tooltip>
          <Tooltip content='Well control' direction='right'>
            <Link to='/layout/control-room' style={{ display: 'none' }}>
              <div
                data-testid='asset-dashboard-image'
                className={`navigation-bar-navigation-image-container ${selectedTooltip === 'wellControl' ? 'selected' : ''}`}
                onClick={() => {
                  setSelectedTooltip('wellControl');
                }}
              >
                <img id='asset-dashboard-image' src={WellControlIcon} alt='Asset Dashboard Image' />
              </div>
            </Link>
          </Tooltip>

          <div className='navigation-bar-navigation-section-container' style={{ display: 'none' }}>
            <Tooltip content='Notification' direction='right'>
              <Link to='/layout/notification'>
                <div
                  className={`navigation-bar-navigation-image-container ${selectedTooltip === 'notification' ? 'selected' : ''
                    }`}
                  onClick={() => handleTooltipClick('notification')}
                >
                  <img id='notification-image' src={NotificationImage} alt='Notification Image' />
                </div>
              </Link>
            </Tooltip>
          </div>

          <div className='navigation-bar-slide-out-section-container'>
            <Tooltip content='Notifications' direction='right'>
              <div
                className={`navigation-bar-navigation-image-container ${selectedTooltip === 'notifications' ? 'selected' : ''
                  }`}
                onClick={() => handleTooltipClick('notifications')}
                style={{ display: 'none' }}
              >
                <img src={NotificationBottomIcon} alt='Notifications' />
              </div>
            </Tooltip>

            {storedUser?.hasSupportAccess && (
              <Tooltip content='Support' direction='right'>
                <Link to='/layout/support'>
                  <div
                    className={`navigation-bar-navigation-image-container ${selectedTooltip === 'support' ? 'selected' : ''
                      }`}
                    onClick={() => handleTooltipClick('support')}
                  >
                    <img src={infocircleIcon} alt='Admin Profiles' />
                  </div>
                </Link>
              </Tooltip>
            )}
            {currentUser?.roles?.includes(Role.Admin) && (
              <Tooltip content='Admin tools' direction='right'>
                <Link to='/layout/admin'>
                  <div
                    className={`navigation-bar-navigation-image-container ${selectedTooltip === 'adminProfiles' ? 'selected' : ''
                      }`}
                    onClick={() => handleTooltipClick('adminProfiles')}
                  >
                    <img src={admin} alt='Admin Profiles' />
                  </div>
                </Link>
              </Tooltip>
            )}
            <Tooltip content='Settings' direction='right'>
              <Link to='/layout/user'>
                <div
                  className={`navigation-bar-navigation-image-container ${selectedTooltip === 'userSettings' ? 'selected' : ''
                    }`}
                  onClick={() => handleTooltipClick('userSettings')}
                >
                  <img src={GearIcon} alt='Settings' />
                </div>
              </Link>
            </Tooltip>

            <Tooltip content='Log out' direction='right'>
              <Link to='/login' onClick={handleLogout}>
                <div
                  className={`navigation-bar-navigation-image-container ${selectedTooltip === 'logout' ? 'selected' : ''
                    }`}
                  onClick={() => handleTooltipClick('logout')}
                >
                  <img id='logout-image' src={LogoutImage} alt='Logout Image' />
                </div>
              </Link>
            </Tooltip>
          </div>
        </div>
        <div ref={ref}>{expandDrawer && <NavigationDrawerItem />}</div>
      </div>
    </>
  );
};

export default NavigationBar;
