import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../../schduledSpeedChangeFrequency.scss';
import { SteppedSpeedChange } from '../../model/SteppedSpeedChange';
import Edit from '../../../../../../images/edit-05.svg';
import InfoCircle from '../../../../../../images/error-info-icon.svg';
import Loader from '../../../../../../../src/images/preloading.gif';
import ScheduledSteppedSpeedChange from './SteppedSpeedChange';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppUser } from '../../../../../user/model/AppUser';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';
import { fetchUser } from '../../../../../user/UserSlice';
import {
  deleteScheduleById,
  fetchSteppedSpeedChangeData,
} from '../../ScheduleSpeedChangeSlice';
import CancelModal from '../../../../../common/Cancel/CancelModal';
import userIcon from '../../../../../../../src/images/user-03.svg';
import calendar from '../../../../../../../src/images/calendar.svg';
import Frequency from '../../../../../../../src/images/frequency.svg';
import Step_Frequency from '../../../../../../../src/images/switch-vertical-01.svg';
import Step_Interval from '../../../../../../../src/images/clock-stopwatch.svg';
import Refresh from '../../../../../../../src/images/refresh-ccw-01.svg';
import { convertFromISO } from '../../model/SimpleSpeedChange';
import Scheduled from '../../../../../../../src/images/calendar-date.svg';
import InProgress from '../../../../../../../src/images/contrast-02.svg';
import Completed from '../../../../../../../src/images/check-green.svg';
import Failed from '../../../../../../../src/images/alert-circle.svg';
import Canceled from '../../../../../../../src/images/x-close.svg';
import { ScheduleSpeedChange, scheduleStatus, scheduleStatusMessage } from '../../model/ScheduleSpeedChange';

const SteppedSpeedChangeFrequency: React.FC = () => {
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state?.user?.currentUser);
  const [user, setUser] = useState<AppUser | null>(currentUser);
  const [steppedSpeedChangeInstance, setSteppedSpeedChangeInstance] = useState<SteppedSpeedChange | null>(null);
  const [isSteppedChangeOpen, setIsSteppedChangeOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<number | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const selectedAsset = useAppSelector((state) => state.assetGroups) ?? '';
  const hElement = document.getElementById('main-root');
  const scheduleSpeedChangeData = useAppSelector((state) => state.scheduleSpeedChange)
  const steppedSpeedChangeDataArray = scheduleSpeedChangeData.steppedSpeedChangeDataArray
  const selectedAssetRef = useRef<string>('')
  const loading = scheduleSpeedChangeData.loading || scheduleSpeedChangeData.setPointAddressLoading || scheduleSpeedChangeData.getSteppedSpeedChangeDataLoading
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(25)
  const [activeScheduleIndex, setActiveScheduleIndex] = useState<number | null>(null);
  const dropdownRef = useRef<(HTMLDivElement | null)[]>([]); // Allow null values in the ref array



  useEffect(() => {
    if (!currentUser?.id) {
      dispatch(fetchUser(storedUser?.id));
    }
  }, [currentUser?.id, dispatch]);

  useEffect(() => {
    if (currentUser) {
      setUser(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (selectedAssetRef.current !== selectedAsset.selectedAssetName) {
      closeModal()
      selectedAssetRef.current = selectedAsset.selectedAssetName ?? ''
    }
  }, [selectedAsset.selectedAssetName]);

  const hadleAddStepped = () => {
    setIsSteppedChangeOpen(true);
    setSteppedSpeedChangeInstance({
      currentFrequency: '',
      newFrequency: '',
      restartFrequency: '',
      scheduleDateTime: '',
      stepFrequency: '',
      stepInterval: '',
      description: '',
    });
    setEditMode(false);
  };

  const closeModal = () => {
    setIsSteppedChangeOpen(false);
    setEditMode(false);
    dispatch(
      fetchSteppedSpeedChangeData({
        wellId: selectedAsset?.selectedAssetId ?? '',
        deviceId: storedUser?.id,
        pageNumber: 1,
        pageSize: pageSize
      }),
    );
    setPageNumber(1)
  };

  const handleEditSchedule = (steppedData: ScheduleSpeedChange) => {
    if (steppedData.userId === user?.id) {
      setIsDropdownOpen(null);
      setIsSteppedChangeOpen(true);
      setSteppedSpeedChangeInstance(steppedData.steppedSpeedChanges?.[0] || null);
      setEditMode(true);
    } else {
      toast.error('Only the creator can edit');
    }
  };

  const handleCancelEdit = () => {
    setIsDropdownOpen(null);
    setIsCancelModalOpen(true);
    closeModal();
    hElement && hElement?.classList.add('modal-open');

  };


  const openDropdown = (index: number) => {
    if (isDropdownOpen === index) {
      setIsDropdownOpen(null)
      setActiveScheduleIndex(null)
    }
    else {
      setIsDropdownOpen(index);
      setActiveScheduleIndex(index)
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isDropdownOpen !== null && dropdownRef.current[isDropdownOpen] && !dropdownRef.current[isDropdownOpen]?.contains(event.target as Node)) {
        setIsDropdownOpen(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const handleConfirmCancel = async () => {
    if (activeScheduleIndex === null) return
    const activeScheduleData = steppedSpeedChangeDataArray[activeScheduleIndex]

    try {
      const response = await dispatch(deleteScheduleById(activeScheduleData?.id || ''));
      if (response) {

        toast.success('Scheduled speed change successfully canceled', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch(
          fetchSteppedSpeedChangeData({
            wellId: selectedAsset?.selectedAssetId ?? '',
            deviceId: storedUser?.id,
            pageNumber: 1,
            pageSize: pageSize,
          }),
        );
        setIsCancelModalOpen(false);
        setPageNumber(1)
        hElement && hElement?.classList.remove('modal-open');
      }
    } catch (error) {
      toast.error('Failed to cancel schedule speed change', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error('Error creating or updating schedule:', error);
    }

  };

  function getStatusMessage(statusCode: number) {
    // Get the key from the scheduleStatus enum
    const statusKey = Object.keys(scheduleStatus).find(
      (key) => scheduleStatus[key as keyof typeof scheduleStatus] === statusCode
    );

    // Use the key to fetch the message from scheduleStatusMessage
    if (statusKey) {
      return scheduleStatusMessage[statusKey as keyof typeof scheduleStatusMessage];
    }

    return ''; // Return undefined if the status code is invalid
  }

  const observer = useRef<IntersectionObserver>();
  const lastSpeedChangeDataRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (scheduleSpeedChangeData.getSimpleSpeedChangeDataLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && scheduleSpeedChangeData.steppedSpeedChangeTotalCount > steppedSpeedChangeDataArray?.length) {

          const updatedPageNumber = pageNumber + 1
          setPageNumber(updatedPageNumber)
          dispatch(
            fetchSteppedSpeedChangeData({
              wellId: selectedAsset?.selectedAssetId ?? '',
              deviceId: storedUser?.id,
              pageNumber: updatedPageNumber,
              pageSize: pageSize,
            }),
          );

        }
      });
      if (node instanceof HTMLDivElement) observer.current.observe(node);
    },
    [steppedSpeedChangeDataArray?.length],
  );



  return (
    <>
      {isSteppedChangeOpen && (
        <ScheduledSteppedSpeedChange
          isOpen={isSteppedChangeOpen}
          steppedSpeedChange={steppedSpeedChangeInstance}
          onClose={closeModal}
          editMode={editMode}
          scheduleId={activeScheduleIndex !== null ? steppedSpeedChangeDataArray[activeScheduleIndex]?.id || '' : ''}

        />
      )}
      {isCancelModalOpen && (
        <CancelModal
          isCancelModal={isCancelModalOpen}
          onClose={() => setIsCancelModalOpen(false)}
          onCancel={handleConfirmCancel}
        />
      )}
      <div className='tab-content text-left'>
        <div className='flex flex-row pb-4'>
          {isSteppedChangeOpen ? null : (
            <div className='card'>
              <div className='header'>
                <div className='flex w-full flex-row'>
                  <div className='basis-3/5'>
                    <span className='scheduleSpeed__title'>Stepped Speed Change</span>
                    <div className='pt-3'>
                      Stepped speed change allows a user to schedule a one time speed change to an asset either
                      immediately, or at a later date.
                    </div>
                  </div>
                  <div className='basis-2/5 text-right'>
                    {!editMode && (
                      <button
                        className='scheduleSpeed btn btn-primary add-btn'
                        onClick={hadleAddStepped}
                        disabled={loading || (steppedSpeedChangeDataArray?.length > 0 && steppedSpeedChangeDataArray[0]?.status === scheduleStatus.Pending) || (steppedSpeedChangeDataArray?.length > 0 && steppedSpeedChangeDataArray[0]?.status === scheduleStatus.InProgress)}
                      >
                        <span className='btn-icon'>+</span> Add speed change
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {loading ? (
                <div className='body d-flex align-items-center'>
                  <div className='card m-0'>
                    <div className='body'>
                      <p className='loader-scan'>
                        <img src={Loader} alt='Loading ...' className='loader-icon' />
                        Scanning asset ...
                      </p>
                    </div>
                  </div>
                </div>
              ) :
                <div style={{ overflowY: 'auto', height: 'calc(100vh - 300px)' }}>
                  {
                    steppedSpeedChangeDataArray?.length ? (
                      steppedSpeedChangeDataArray.map((steppedData, steppedIndex) => {
                        const steppedSpeedChangeData = steppedData?.steppedSpeedChanges?.[0];
                        if (steppedSpeedChangeData)
                          return (
                            <div className='body scheduleSpeed' key={steppedIndex}
                              ref={steppedSpeedChangeDataArray.length === steppedIndex + 1 ? lastSpeedChangeDataRef : null}
                            >
                              <div className='scheduleSpeed__card m-0'>
                                <div className='header'>
                                  <div className='flex w-full flex-row'>
                                    <div className='basic-four'>
                                      <div className='flex w-full flex-row'>
                                        <span className='scheduleSpeed__title two'>Stepped Speed Change</span>
                                        <span className={`sm-highlt ${steppedData?.status ? scheduleStatus[steppedData?.status] : ''}`}>
                                          {
                                            steppedData?.status === scheduleStatus.InProgress ?
                                              <img src={InProgress} alt='speed-change' />
                                              : steppedData?.status === scheduleStatus.Pending ?
                                                <img src={Scheduled} alt='speed-change' />
                                                : steppedData?.status === scheduleStatus.Completed ?
                                                  <img src={Completed} alt='speed-change' />
                                                  : steppedData?.status === scheduleStatus.Failed ?
                                                    <img src={Failed} alt='speed-change' />
                                                    : steppedData?.status === scheduleStatus.Cancelled ?
                                                      <img src={Canceled} alt='speed-change' />
                                                      : steppedData?.status === scheduleStatus.Shutdown ?
                                                        <img src={Failed} alt='speed-change' />
                                                        : ''

                                          }
                                          {getStatusMessage(steppedData?.status || 0)}
                                        </span>
                                      </div>
                                      <div className='list-inline-items'>
                                        <span>
                                          <img src={userIcon} alt='userIcon' />
                                          {steppedData?.createdByName}
                                        </span>
                                        <span>
                                          <img src={calendar} alt='calendar' />
                                          {(() => {
                                            const convertedValue = convertFromISO(steppedSpeedChangeData.scheduleDateTime);
                                            if (convertedValue)
                                              return `${convertedValue.date} - ${convertedValue.time} ${convertedValue.ampm}`;
                                          })()}
                                        </span>
                                        <span>
                                          <img src={Frequency} alt='frequency' />
                                          Initial: {steppedSpeedChangeData.currentFrequency} Hz Target: {steppedSpeedChangeData.newFrequency} Hz
                                        </span>
                                        <span>
                                          <img src={Step_Frequency} alt='step-frequency' />
                                          Step: {steppedSpeedChangeData.stepFrequency} Hz
                                        </span>
                                        <span>
                                          <img src={Step_Interval} alt='step-interval' />
                                          Interval: {steppedSpeedChangeData.stepInterval} minutes
                                        </span>
                                        <span>
                                          <img src={Refresh} alt='refresh' />
                                          Restart Frequency: {steppedSpeedChangeData.restartFrequency} Hz
                                        </span>
                                      </div>
                                    </div>
                                    <div className='basis-one'>
                                      <div className='scheduleSpeed__dropdown-container' ref={(el) => (dropdownRef.current[steppedIndex] = el)}>
                                        <button
                                          className='scheduleSpeed__dropdown-btn'
                                          onClick={() => openDropdown(steppedIndex)}
                                        >
                                          <span className='dot'></span>
                                          <span className='dot'></span>
                                          <span className='dot'></span>
                                        </button>
                                        {isDropdownOpen === steppedIndex && (
                                          <div className='dropdown-options'>
                                            <button>Actions</button>
                                            <button className='btn btn-default' onClick={() => handleEditSchedule(steppedData)}>
                                              <img src={Edit} alt='icon' className='mr-3' />
                                              Edit
                                            </button>
                                            <button className='btn btn-default' onClick={handleCancelEdit}>
                                              <img src={InfoCircle} alt='icon' className='mr-3' />
                                              Cancel
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='scheduleSpeed__description'>
                                  <div>{steppedSpeedChangeData.description}</div>
                                </div>
                              </div>
                            </div>
                          )
                      })
                    ) : (
                      <div className='body'>
                        <div className='card m-0'>
                          <div className='scheduleSpeed__body'>
                            <p>No schedule data available.</p>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
              }
            </div>
          )}
        </div>
        <ToastContainer position='top-right' autoClose={3000} />
      </div>
    </>
  );
};

export default SteppedSpeedChangeFrequency;
