import { HealthScore } from "../model/HealthScoreHistory";

export interface BandsData {
    title?: string,
    color: string,
    lowScore: number,
    highScore: number,
}

export interface HealthScoreChartProps {
    root?: any;
    chartID?: string;
    chartRef: any;
    classValue?: string;
    minValue?: number;
    maxValue?: number;
    clockHandValue?: number;
    clockHandName?: string;
    startAngle?: number;
    endAngle?: number;
    chartName?: string;
    innerCircleStartvalue?: number;
    innerCircleEndvalue?: number;
    bandsData: BandsData[];
    spacingValue: number;
    value: number;
    data?: HealthScore | null
}



export const HealthScorebandsData = [{
    title: "Volatile",
    color: "#F97066",
    lowScore: 0,
    highScore: 15
}, {
    title: "Foundational",
    color: "#FDB022",
    lowScore: 15,
    highScore: 85
}, {
    title: "Developing",
    color: "#12B76A",
    lowScore: 85,
    highScore: 100
}];