import { useEffect } from "react";
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import {  useAppSelector } from "../../../../../hooks/storeHooks";
import { graph3Data } from "./graphs";
import Loader from "../../../../common/page-loader/ComponentLoader";

const CapacityCurveGraph2 = () => {
    const mainReportData = useAppSelector((state) => state.analysisTab.mainReportData);
    const F45Data = graph3Data(mainReportData?.graph3Data || []);
    const loading = useAppSelector((state) => state.analysisTab.mainReportDataLoading);

    useEffect(() => {
       if(!loading) {
        const root = am5.Root.new('CapacityGraph2Chartdiv');

        root.setThemes([am5themes_Animated.new(root)]);

        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: 'none',
                wheelY: 'none',
                pinchZoomX: false,
                pinchZoomY: false,
            }),
        );

        const xRenderer = am5xy.AxisRendererX.new(root, {
            minorGridEnabled: true,
            minGridDistance: 100,
        });
        xRenderer.labels.template.set('fill', am5.color('#fff'));
        const xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                autoZoom: false,
                min: 0,
                renderer: xRenderer,
                tooltip: am5.Tooltip.new(root, {}),
            }),
        );
        const xAxisLabel = am5.Label.new(root, {
            text: "Capacity (bpd)",
            fill: am5.color("#fff"),
            // rotation: -90,
            centerX: am5.p100,
            centerY: am5.p100,
            x: 350,
            y: 70,
        });

        xAxis.children.push(xAxisLabel);
        
        xAxis.get('renderer').labels.template.set('paddingLeft', 20);
        xAxis.get('renderer').labels.template.set('paddingTop', 20);

        const yRenderer = am5xy.AxisRendererY.new(root, {
            minorGridEnabled: true,
            minGridDistance: 50,
        });
        yRenderer.labels.template.set('fill', am5.color('#fff'));
        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                // min: 1500,
                renderer: yRenderer,
            }),
        );
        const yAxisLabel = am5.Label.new(root, {
            text: 'Head (ft)',
            fill: am5.color("#fff"),
            rotation: -90,
            centerX: am5.p100,
            centerY: am5.p100,
            x: -15,
            y: am5.p50,
        });

        yAxis.children.push(yAxisLabel);

        const seriesHead = am5xy.SmoothedXYLineSeries.new(root, {
            name: '45 Hz',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#4294FF"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#4294FF"),
        });
        seriesHead.data.setAll(F45Data.f45);
        seriesHead.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        const seriesHead2 = am5xy.SmoothedXYLineSeries.new(root, {
            name: '50 Hz',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#4294FF"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#4294FF"),
        });
        seriesHead2.data.setAll(F45Data.f50);
        seriesHead2.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        const seriesHead3 = am5xy.SmoothedXYLineSeries.new(root, {
            name: '55 Hz',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#4294FF"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#4294FF"),
        });
        seriesHead3.data.setAll(F45Data.f55);
        seriesHead3.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        const seriesHead4 = am5xy.SmoothedXYLineSeries.new(root, {
            name: '60 Hz',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#4294FF"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#4294FF"),
        });
        seriesHead4.data.setAll(F45Data.f60);
        seriesHead4.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        const seriesHead5 = am5xy.SmoothedXYLineSeries.new(root, {
            name: '65 Hz',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#4294FF"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#4294FF"),
        });
        seriesHead5.data.setAll(F45Data.f65);
        seriesHead5.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        const seriesHead6 = am5xy.SmoothedXYLineSeries.new(root, {
            name: '70 Hz',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#4294FF"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#4294FF"),
        });
        const deepCopy = JSON.parse(JSON.stringify(F45Data.f70));
        seriesHead6.data.setAll(deepCopy);
        seriesHead6.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        const seriesHead7 = am5xy.SmoothedXYLineSeries.new(root, {
            name: '75 Hz',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#4294FF"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#4294FF"),
        });
        seriesHead7.data.setAll(F45Data.f75);
        seriesHead7.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        const seriesMin = am5xy.SmoothedXYLineSeries.new(root, {
            name: 'Min',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#91A4AA"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#91A4AA"),
        });
        seriesMin.data.setAll(F45Data.min);
        seriesMin.strokes.template.setAll({
            strokeDasharray: [4, 4],
        });
        seriesMin.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        const seriesBep = am5xy.SmoothedXYLineSeries.new(root, {
            name: 'Min',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#91A4AA"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#91A4AA"),
        });
        seriesBep.data.setAll(F45Data.bep);
        seriesBep.strokes.template.setAll({
            strokeDasharray: [4, 4],
          });
        seriesBep.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        const seriesMax = am5xy.SmoothedXYLineSeries.new(root, {
            name: 'Min',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#91A4AA"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#91A4AA"),
        });
        const deepCopyMax = JSON.parse(JSON.stringify(F45Data.max));
        seriesMax.data.setAll(deepCopyMax);
        seriesMax.strokes.template.setAll({
            strokeDasharray: [4, 4],
          });
        seriesMax.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        chart.series.push(seriesHead);
        chart.series.push(seriesHead2);
        chart.series.push(seriesHead3);
        chart.series.push(seriesHead4);
        chart.series.push(seriesHead5);
        chart.series.push(seriesHead6);
        chart.series.push(seriesHead7);
        chart.series.push(seriesMin);
        chart.series.push(seriesBep);
        chart.series.push(seriesMax);

        const legend = am5.Legend.new(root, {
            layout: am5.GridLayout.new(root, {
                maxColumns: 7,
                fixedWidthGrid: false,
            }),
            marginTop: 30,
            useDefaultMarker: true,
        });
        
      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
        width: 12,
        height: 12,
      });

        // Set legend labels color
        legend.labels.template.setAll({
            fill: am5.color(0xffffff), // Adjust color as needed
        });
        
        // Customize or simplify the legend markers
        // legend.markers.template.setAll({
        //     width: 20, // Adjust width as needed
        //     height: 20, // Adjust height as needed
        // });
        
        // Add the legend to the chart
        chart.bottomAxesContainer.children.push(legend);
        
        // Link the legend to the series
        legend.data.setAll([seriesHead, seriesHead2, seriesHead3, seriesHead4, seriesHead5, seriesHead6, seriesHead7]);

        return () => {
            root.dispose();
        };
       }
    }, [F45Data]);

    return (
        <>
        {loading ? (
          <div className='flex items-center justify-content loader-margin'>
            <Loader />
          </div>
          ) : <div id="CapacityGraph2Chartdiv" style={{ width: '100%', height: '500px' }}></div>
         }  
        </>
    )
};

export default CapacityCurveGraph2;