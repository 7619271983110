import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TimeseriesData } from "../../interfaces/timeSeries.interface";
import { getAssetTimeseriesChannelsData, getTimeseriesData } from "../../services/wellControlroom.service";
import { AssetTimeseriesData } from "../../interfaces/timeSeriesChannelIdsData.interface";

interface wellControlroomCharts {
    loading: boolean;
    error: string | null;
    intakeDischargeTimeSeriesData: TimeseriesData[];
    intakeDischargeChannelData: AssetTimeseriesData[];
}

const initialState: wellControlroomCharts = {
    loading: false,
    error: null,
    intakeDischargeTimeSeriesData: [],
    intakeDischargeChannelData: []
}

export const fetchTimeSeriesData = createAsyncThunk(
    'events/fetchTimeSeriesDataID',
    async ({ assetName, startDate, endDate, variables }: {
        assetName: string;
        startDate: string;
        endDate: string;
        variables: string;
    }) => {

        const response = await getTimeseriesData(assetName, startDate, endDate, variables);
        return response;
    }
);

export const fetchTimeSeriesChannelData = createAsyncThunk(
    'events/fetchTimeSeriesChannelDataID',
    async ({ assetName, startDate, endDate, variables }: {
        assetName: string;
        startDate: string;
        endDate: string;
        variables: string;
    }) => {
        const response = await getAssetTimeseriesChannelsData(assetName, startDate, endDate, variables);
        return response;
    }
);


const intakeDischargeSlice = createSlice({
    name: 'intakeDischarge',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTimeSeriesData.fulfilled, (state, action) => {
            state.intakeDischargeTimeSeriesData = action.payload.data;
            state.loading = false;
        });
        builder.addCase(fetchTimeSeriesData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchTimeSeriesData.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        });
        builder.addCase(fetchTimeSeriesChannelData.fulfilled, (state, action) => {
            state.intakeDischargeChannelData = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchTimeSeriesChannelData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchTimeSeriesChannelData.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        });
    }
});

export default intakeDischargeSlice.reducer;
export const intakeDischargeActions = intakeDischargeSlice.actions;
