import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LayoutItem } from "../interfaces/customizeControlroom.interface";
import CustomizeServices from "../services/customizeControlroom.service";

interface LayoutState {
  items: LayoutItem[];
  loading: boolean;
  error: string | null;
}


const initialState: LayoutState = {
  items: [],
  loading: false,
  error: null,
};

export const updateWidgetLayout = createAsyncThunk(
  'updateControlroom/Data',
  async ({ dashboard, data}: { dashboard: string, data: LayoutItem[]}) => {
    const response = await CustomizeServices.updateControlroom(dashboard, data);
    return response;
  },
);

  
const CustomizeControlroomSlice = createSlice({
  name: 'CustomizeControlroomSlice',
  initialState,
  reducers: {  
    setLayoutItems: (state, action: PayloadAction<LayoutItem[]>) => {
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(updateWidgetLayout.pending, (state) => {
      state.loading = true;
    })
    .addCase(updateWidgetLayout.fulfilled, (state, action) => {
      state.items = action.payload; 
      state.loading = false;
    })
    .addCase(updateWidgetLayout.rejected, (state, action) => {
      state.error = action.payload as string;
      state.loading = false;
    });
  },
});

export const { setLayoutItems } = CustomizeControlroomSlice.actions;

export default CustomizeControlroomSlice.reducer;










