import { apiInstance } from "../../../../../config/HttpCommon";
import { LayoutItem } from "../interfaces/customizeControlroom.interface";

const updateControlroom = async (
    dashboard: string,
    data: LayoutItem[],
    ) => {
      try {
        const response = await apiInstance.put(`/v1/user-preferences/widgets/${dashboard}`,data);
        return response.data;
      } catch (error) {
          console.error("Error while fetching data:", error);
          throw error;
      }
  };
  
  const CustomizeServices = {
    updateControlroom,
  };
    
  export default CustomizeServices;


