import React, { useEffect, useState } from 'react';
import TabWithUrl from '../../components/TabWithUrl';
import Analysis from './components/analysis/Analysis';
import Details from './components/details/Details';
import AssetDetailsHeader from './components/asset-details-header/AssetDetailsHeader';
import SetPoints from './components/setpoints/SetPoints';
import AssetDocuments from './components/asset-documents/AssetDocuments';
import { Options } from './data/Options';
import History from './components/history/History';
import Equipment from './components/equipment/Equipment';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { setAssetGroupState } from '../asset-hierarchy/AssetGroupSlice';
import { getAssetGroup } from '../asset-hierarchy/AssetHierarchyService';
import { updateAssetList, updateSelectedAsset } from '../navigation/AssetGroupsSlice';
import { addWellName } from './components/schduled-speed-change/ScheduleSpeedChangeSlice';
import AssetControlRoomHeader from '../asset-control-room-header/components/AssetControlRoomHeader';
import { AppUser } from '../user/model/AppUser';
import { Role } from '../../models/Role';

interface AssetGroupList {
  assetId: string;
  assetName: string;
  industryApplicationId: number;
}
interface selectedWellName {
  assetGroupName: string;
  assetId: string;
  assetName: string;
}

const AssetDetails: React.FC = () => {
  const selectedWellNameLS: any = sessionStorage.getItem('selectedWellName');
  const selectedWellName: selectedWellName | null = selectedWellNameLS ? JSON.parse(selectedWellNameLS) : null;
  const selectedWellData: Options = {
    wellId: selectedWellName?.assetId ?? '',
    wellName: selectedWellName?.assetName ?? '',
    assetGroupName: selectedWellName?.assetGroupName ?? '',
  }
  let currentUser = useAppSelector((state) => state.user.currentUser);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser | null = storedUserData ? JSON.parse(storedUserData) : null;
  const { assetDetail, assetDetailLoading } = useAppSelector((state) => state?.assetDetail);
  const isSleStatus = assetDetail?.assetDetails.isSleAsset

  const [dataSource, setDataSource] = useState<string>('-');


  useEffect(() => {
    if (!assetDetailLoading) {
      if (assetDetail) setDataSource(isSleStatus ? 'IOT' : 'Polling')
      else setDataSource('-')
    } else {
      setDataSource('-')
    }
  }, [assetDetail, assetDetailLoading])

  if (currentUser?.id != storedUser?.id)
    currentUser = storedUser

  const dispatch = useAppDispatch();
  const [selectedOption, setSelectedOption] = useState<Options>({
    wellId: selectedWellName?.assetId ?? '',
    wellName: selectedWellName?.assetName ?? '',
    assetGroupName: selectedWellName?.assetGroupName ?? '',
  });
  const selectedAsset = useAppSelector((state) => state.assetGroups);

  useEffect(() => {
    dispatch(addWellName(selectedOption.wellName))
  })

  const dispatchAssetGrouptData = (
    assetId: string | undefined,
    assetName: string,
    assetGroupName: string,
    groupList?: AssetGroupList[],
  ) => {
    dispatch(
      updateSelectedAsset({
        assetGroupName: assetGroupName,
        assetId: assetId,
        assetName: assetName,
        searchString: undefined,
      }),
    );
    dispatch(updateAssetList(groupList ?? []));
  };

  const getAssetGroupData = async () => {
    const selectedWellNameString = sessionStorage.getItem('selectedWellName');
    const wellNameListString = sessionStorage.getItem('wellNameList');

    if (selectedWellNameString && wellNameListString) {
      const selectedWellName = JSON.parse(selectedWellNameString ?? '');
      const wellNameList = JSON.parse(wellNameListString ?? '');

      dispatch(updateSelectedAsset(selectedWellName));
      dispatch(updateAssetList(wellNameList));
    } else {
      const data = await getAssetGroup('AssetGroupName');
      dispatch(setAssetGroupState(data));
      const initialData = data.values[0].childGroups[0];
      dispatchAssetGrouptData(
        initialData?.assets[0]?.assetId,
        initialData?.assets[0]?.assetName,
        initialData?.groupName,
        initialData?.assets,
      );
    }
  };

  useEffect(() => {
    if (!selectedAsset?.selectedAssetName) {
      getAssetGroupData();
    }
  }, []);

  useEffect(() => {
    ///const selectedWellName: any = sessionStorage.getItem('selectedWellName');
    selectedWellName ? setSelectedOption(selectedWellData) : null;
    if (selectedAsset?.selectedAssetName) {
      setSelectedOption({
        wellId: selectedAsset?.selectedAssetId ?? '',
        wellName: selectedAsset?.selectedAssetName ?? '',
        assetGroupName: selectedAsset?.selectedGroup ?? (selectedWellName?.assetGroupName ? selectedWellName?.assetGroupName : ''),
      });
    }
  }, [selectedAsset]);

  const tabs = [
    { label: 'Details', component: Details },
    { label: 'Analysis', component: Analysis },
    { label: 'History', component: History },
    { label: 'Equipment', component: Equipment },
    { label: 'Setpoints', component: SetPoints },
    { label: 'Documentation', component: AssetDocuments },
  ];


  return (
    <>
      <div className={`group-status-container profile-container asset-document-details`}>
        <div className='profile-status-header'>
          <AssetDetailsHeader
            groupName={selectedAsset.selectedGroup}
            assetList={selectedAsset.assetList}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
          <div className="nav-margin-top"></div>
          <div className='divider'></div>
          {currentUser?.roles?.includes(Role.Admin) && (<AssetControlRoomHeader />)}
          <TabWithUrl initialTab={{ tab: 'Status' }} tabs={tabs} value={selectedOption?.wellName} dataSource={dataSource} />
        </div>
      </div>
    </>
  );
};

export default AssetDetails;
