import { createAsyncThunk, createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { TimeseriesData } from "./interfaces/timeSeries.interface";
import { AssetTimeseriesData } from "./interfaces/timeSeriesChannelIdsData.interface";
import { getAssetTimeseriesChannelsData, getMasterVariablesData, getTimeseriesData, getWellProductionTrendsByAssetName } from "./services/wellControlroom.service";
import { ProductionTrendsApiResponse } from "./components/production-chart/interfaces/prod.interfaces";
import { ParameterType } from "./interfaces/masterVariables.interface";

interface WellControlroomCharts {
    channelIDs: string[];
    variables: string[];
    channelIDsObj: { [key: string]: string };
    productionTrends: ProductionTrendsApiResponse;
    masterVariables: ParameterType[];
    timeSeriesData: { [key: string]: TimeseriesData };
    channelData: { [key: string]: AssetTimeseriesData };
    loading: boolean;
    error: { [key: string]: string | null };
    chartsLoading: { [key: string]: boolean };
}

const initialState: WellControlroomCharts = {
    channelIDs: [],
    variables: [],
    channelIDsObj: {},
    productionTrends: {
        wellProductionTrend: {
            gasData: [],
            oilData: [],
            waterData: []
        },
        wellProductionTrendDetails: []
    },
    masterVariables: [],
    timeSeriesData: {},
    channelData: {},
    loading: true,
    error: {},
    chartsLoading: {}
};

export const fetchTimeSeriesDataVariables = createAsyncThunk(
    "wellControlroomCharts/fetchTimeSeriesDataVariables",
    async (
        { assetName, startDate, endDate, variables, chartName }: { assetName: string; startDate: string; endDate: string; variables: string[], chartName: string }
    ) => {
        const response = await getTimeseriesData(assetName, startDate, endDate, variables.join(","));
        return { data: response, chartName: chartName };
    }
);

export const fetchTimeSeriesChannelData = createAsyncThunk(
    "wellControlroomCharts/fetchTimeSeriesChannelData",
    async (
        { assetName, startDate, endDate, channelIds, chartName }: { assetName: string; startDate: string; endDate: string; channelIds: string[], chartName: string }
    ) => {
        const response = await getAssetTimeseriesChannelsData(assetName, startDate, endDate, channelIds.join(","));
        return { data: response, chartName: chartName };
    }
);


export const fetchProductionTrendsByWell = createAsyncThunk(
    'wellControlroomCharts/fetchProductionTrendsByWell',
    async ({ userId, startDate, endDate, assetName }: { userId: string, startDate: string, endDate: string, assetName: string }) => {
        return await getWellProductionTrendsByAssetName(
            userId,
            startDate,
            endDate,
            assetName
        );
    }
);

export const fetchMasterVariablesByWellname = createAsyncThunk(
    'events/fetchMasterVariablesByWellname',
    async ({ wellName }: { wellName: string; }) => {
        const response = await getMasterVariablesData(wellName);
        return response;
    },
);

const wellControlroomSlice = createSlice({
    name: "wellControlroomCharts",
    initialState,
    reducers: {
        setChannelIDs: (state, action: PayloadAction<string[]>) => {
            state.channelIDs = [...new Set([...state.channelIDs, ...action.payload])];
        },
        setVariables: (state, action: PayloadAction<string[]>) => {
            state.variables = [...new Set([...state.variables, ...action.payload])];
        },
        setChannelIDsObj: (state, action: PayloadAction<{ [key: string]: string }>) => {
            state.channelIDsObj = { ...state.channelIDsObj, ...action.payload };
        },
        // clear all the data
        clearData: (state) => {
            state.channelIDs = [];
            state.variables = [];
            state.channelIDsObj = {};
            state.productionTrends = {
                wellProductionTrend: {
                    gasData: [],
                    oilData: [],
                    waterData: []
                },
                wellProductionTrendDetails: []
            };
            state.masterVariables = [];
            state.timeSeriesData = {};
            state.channelData = {};
            state.loading = true;
            state.error = {};
        }
    },
    extraReducers: (builder) => {

        builder.addCase(fetchTimeSeriesDataVariables.pending, (state, action) => {
            const chartName = action.meta.arg.chartName;
            if (chartName === 'well-control-room') {
                state.loading = true; // Set initial loading true
            } else {
                state.chartsLoading = { ...state.chartsLoading, [chartName]: true }; // Set chart-specific loading true
                state.loading = false
            }
        });
        builder.addCase(fetchTimeSeriesDataVariables.fulfilled, (state, action) => {
            const chartName = action.meta.arg.chartName;
            if (chartName === 'well-control-room') {
                state.loading = false; // Set initial loading true
            } else {
                state.chartsLoading = { ...state.chartsLoading, [chartName]: false };
                state.loading = false
            }
            state.error = { ...state.error, timeSeries: null };

            const fetchedVariables = action.meta.arg.variables;
            const receivedData = action.payload?.data?.data || [];

            const updatedTimeSeriesData = receivedData?.reduce(
                (acc: { [key: string]: TimeseriesData }, item: TimeseriesData) => {
                    acc[item.variable] = item;
                    return acc;
                },
                {}
            );

            state.timeSeriesData = { ...state.timeSeriesData, ...updatedTimeSeriesData };
            state.timeSeriesData = Object.keys(state.timeSeriesData)?.reduce(
                (acc: { [key: string]: TimeseriesData }, key: string) => {
                    if (fetchedVariables?.includes(key) && updatedTimeSeriesData[key]) {
                        acc[key] = updatedTimeSeriesData[key];
                    } else if (!fetchedVariables.includes(key)) {
                        acc[key] = state.timeSeriesData[key];
                    }
                    return acc;
                },
                {}
            );
        });

        builder.addCase(fetchTimeSeriesDataVariables.rejected, (state, action) => {
            const chartName = action.meta.arg.chartName;
            if (chartName === 'well-control-room') {
                state.loading = false;
            } else {
                state.chartsLoading = { ...state.chartsLoading, [chartName]: false };
                state.loading = false
            }
            state.error = { ...state.error, timeSeries: action.error.message || "Failed to fetch time series data" };
            state.timeSeriesData = Object.keys(state.timeSeriesData)?.reduce((acc: { [key: string]: TimeseriesData }, key: string) => {
                if (!action.meta.arg.variables.includes(key)) {
                    acc[key] = state.timeSeriesData[key];
                }
                return acc;
            }, {});
            console.error("Time Series Data Fetch Error:", action.error);
        });

        builder.addCase(fetchTimeSeriesChannelData.pending, (state, action) => {
            const chartName = action.meta.arg.chartName;
            if (chartName === 'well-control-room') {
                state.loading = true;
            } else {
                state.chartsLoading = { ...state.chartsLoading, [chartName]: true };
                state.loading = false
            }
        });
        builder.addCase(fetchTimeSeriesChannelData.fulfilled, (state, action) => {
            const chartName = action.meta.arg.chartName;
            if (chartName === 'well-control-room') {
                state.loading = false;
            } else {
                state.chartsLoading = { ...state.chartsLoading, [chartName]: false };
                state.loading = false
            }
            state.error = { ...state.error, channelData: null };

            const fetchedChannelIds = action.meta.arg.channelIds;
            const receivedData = action.payload || [];

            const updatedChannelData = receivedData?.data?.reduce(
                (acc: { [key: string]: AssetTimeseriesData }, item: AssetTimeseriesData) => {
                    acc[item.trendName] = item;
                    return acc;
                },
                {}
            );
            state.channelData = { ...state.channelData, ...updatedChannelData };

            state.channelData = Object.keys(state.channelData).reduce(
                (acc: { [key: string]: AssetTimeseriesData }, key: string) => {
                    if (fetchedChannelIds.includes(key) && updatedChannelData[key]) {
                        acc[key] = updatedChannelData[key];
                    } else if (!fetchedChannelIds.includes(key)) {
                        acc[key] = state.channelData[key];
                    }
                    return acc;
                },
                {}
            );
        });

        builder.addCase(fetchTimeSeriesChannelData.rejected, (state, action: any) => {
            const chartName = action.meta.arg.chartName;
            if (chartName === 'well-control-room') {
                state.loading = false; // Set initial loading true
            } else {
                state.chartsLoading = { ...state.chartsLoading, [chartName]: false }; // Set chart-specific loading true
                state.loading = false
            }
            state.error = { ...state.error, channelData: action.error.message || "Failed to fetch channel data" };

            state.channelData = Object.keys(state.channelData).reduce((acc: { [key: string]: AssetTimeseriesData }, key: string) => {
                if (!action.meta.arg.channelIds.includes(key)) {
                    acc[key] = state.channelData[key];
                }
                return acc;
            }, {});
            console.error("Channel Data Fetch Error:", action.error);
        });

        builder.addCase(fetchProductionTrendsByWell.fulfilled, (state, action) => {
            state.productionTrends = action.payload;
            state.chartsLoading = { ...state.chartsLoading, production: false };
        });
        builder.addCase(fetchProductionTrendsByWell.pending, (state) => {
            state.chartsLoading = { ...state.chartsLoading, production: true };
        });
        builder.addCase(fetchProductionTrendsByWell.rejected, (state, action) => {
            state.error = { ...state.error, productionTrends: action.error.message || "Failed to fetch production trends" };
            state.chartsLoading = { ...state.chartsLoading, production: false };
        });
        builder.addCase(fetchMasterVariablesByWellname.fulfilled, (state, action) => {
            state.masterVariables = action.payload;
        });
    }
});

export const { setChannelIDs, setVariables, setChannelIDsObj, clearData } = wellControlroomSlice.actions;

export default wellControlroomSlice.reducer;

export const selectTimeSeriesData = (state: { wellControlroomCharts: WellControlroomCharts }) =>
    state.wellControlroomCharts.timeSeriesData;

export const selectChannelData = (state: { wellControlroomCharts: WellControlroomCharts }) =>
    state.wellControlroomCharts.channelData;

export const selectLoadingState = (state: { wellControlroomCharts: WellControlroomCharts }) =>
    state.wellControlroomCharts.loading;

export const selectErrors = (state: { wellControlroomCharts: WellControlroomCharts }) =>
    state.wellControlroomCharts.error;

export const getTimeSeriesDataByKey = (key: string) =>
    createSelector(selectTimeSeriesData, (timeSeriesData) => timeSeriesData[key]);

export const getChannelDataByKey = (key: string) => {
    return createSelector(selectChannelData, (channelData) => channelData[key]);
}

export const selectMasterVariables = (state: { wellControlroomCharts: WellControlroomCharts }) =>
    state.wellControlroomCharts.masterVariables;


export const getMasterVariableByChannelId = (channelId: string) =>
    createSelector(selectMasterVariables, (masterVariables) => masterVariables.find((item) => item.channelId === channelId));

export const selectChartsLoadingState = (state: { wellControlroomCharts: WellControlroomCharts }) =>
    state.wellControlroomCharts.chartsLoading;

export const getChartLoadingStateByName = (chartName: string) =>
    createSelector(selectChartsLoadingState, (chartsLoading) => chartsLoading[chartName]);
