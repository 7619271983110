export interface SimpleSpeedChange {
  currentFrequency: string | null;
  newFrequency: string;
  scheduleDateTime: string;
  description: string;
}
export interface CreateScheduleSpeedRequest {
  userId: string;
  wellId: string;
  wellName: string;
  groupName: string;
  deviceId: string;
  simpleSpeedChanges: SimpleSpeedChange[];
}

export const convertFromISO = (isoString: string) => {
  if (!isoString) return;
  // Parse the ISO 8601 string into a Date object
  const dateObj = new Date(isoString);
  const localDateString = dateObj.toLocaleDateString()// Local date and time as a string
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };
  const localTimeString = dateObj.toLocaleTimeString('en-US', options);
  // Extract the date in yyyy-mm-dd format
  const [date] = localDateString.split(',');
  const [time, ampm] = localTimeString.split(' ');
  return {
    date: date,
    time: time,
    ampm: ampm
  };
}
