import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { AssetImageOverlay } from '../image-overlay/AssetImageOverlay';
import RodLiftStatusBkgnd from '../../images/rod-lift-status-bkgnd-4.png';
import PropertyValueList from '../property-value-list/PropertyValueList';
import { useEffect } from 'react';
import { fetchInitialStateAsync } from './AssetStatusSlice';

const OverLayFields = {
  Unknown: 0,
  LastGoodScan: 1,
  ApiDesignation: 2,
  CurrentCommunicationStatus: 3,
  ControllerInformation: 4,
  StructuralLoading: 5,
  RunDays: 6,
  RunStatus: 7,
  TimeInState: 8,
  MotorType: 9,
  MotorLoading: 10,
  StrokesPerMinute: 11,
  StrokeLength: 12,
  CyclesToday: 13,
  CyclesYesterday: 14,
  TubingPressure: 15,
  RodLoading: 16,
  PumpFillage: 17,
  CasingPressure: 18,
} as const;

const AssetStatus = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchInitialStateAsync());
  }, [dispatch]);

  const wellStatusData: IRodLiftStatusWellModel | undefined = useAppSelector((state) => state.rodliftwellstatus.data);

  const currentStatusProperties = toPropertyList(wellStatusData?.wellStatusOverview);
  const exceptionProperties = toPropertyList(wellStatusData?.exceptions);
  const alarmProperties = toPropertyList(wellStatusData?.alarms);
  const wellTestProperties = toPropertyList(wellStatusData?.lastWellTest);
  const statusRegisterProperties = toPropertyList(wellStatusData?.statusRegisters);

  // Convert DataValueModel arrays to IPropertyLists for use with PropertyValueList components
  function toPropertyList(dataValues: IDataValueModel[] | null | undefined) {
    if (!dataValues) return [];

    return dataValues
      .filter((dvm) => dvm.isVisible)
      .map((dvm, index) => {
        return { id: index, description: dvm.label, value: dvm.value };
      });
  }

  // Extract the image overlay data from the wellStatusData, group it appropriately into overlays,
  // and augment it with the (x,y) coords to match the background image
  function getImageOverlays(data: IRodLiftStatusWellModel | null | undefined): IImageOverlayModel[] {
    const model: IImageOverlayModel[] = [];

     if (!data || !data.imageOverlayItems) return model;

    // Last Good Scan
    model.push({
      position: { x: 0, y: 0 },
      dataValues: data?.imageOverlayItems?.filter((x) => x.overlayField == OverLayFields.LastGoodScan),
    });

    // API Designation
    model.push({
      position: { x: 350, y: 0 },
      dataValues: data.imageOverlayItems.filter((x) => x.overlayField == OverLayFields.ApiDesignation),
    });

    // Structural Loading
    model.push({
      position: { x: 450, y: 70 },
      dataValues: data.imageOverlayItems.filter((x) => x.overlayField == OverLayFields.StructuralLoading),
    });

    // Comm Status
    model.push({
      position: { x: 150, y: 235 },
      dataValues: data.imageOverlayItems.filter((x) => x.overlayField == OverLayFields.CurrentCommunicationStatus),
    });

    // Controller info
    model.push({
      position: { x: 150, y: 275 },
      dataValues: data.imageOverlayItems.filter((x) => x.overlayField == OverLayFields.ControllerInformation),
    });
    
     // RunDays
     model.push({
      position: { x: 760, y: 450 },
      dataValues: data.imageOverlayItems.filter((x) => x.overlayField == OverLayFields.RunDays),
    });

    // Run Status
    model.push({
      position: { x: 150, y: 315 },
      dataValues: data.imageOverlayItems.filter((x) => x.overlayField == OverLayFields.RunStatus),
    });

    // TIS
    model.push({
      position: { x: 150, y: 355 },
      dataValues: data.imageOverlayItems.filter((x) => x.overlayField == OverLayFields.TimeInState),
    });

    // Motor Type
    model.push({
      position: { x: 250, y: 395 },
      dataValues: data.imageOverlayItems.filter((x) => x.overlayField == OverLayFields.MotorType),
    });

    // Motor Loading
    model.push({
      position: { x: 250, y: 435 },
      dataValues: data.imageOverlayItems.filter((x) => x.overlayField == OverLayFields.MotorLoading),
    });

    // SPM
    model.push({
      position: { x: 550, y: 200 },
      dataValues: data.imageOverlayItems.filter((x) => x.overlayField == OverLayFields.StrokesPerMinute),
    });

    // Stroke Length
    model.push({
      position: { x: 550, y: 240 },
      dataValues: data.imageOverlayItems.filter((x) => x.overlayField == OverLayFields.StrokeLength),
    });

    // Cycles Today
    model.push({
      position: { x: 550, y: 280 },
      dataValues: data.imageOverlayItems.filter((x) => x.overlayField == OverLayFields.CyclesToday),
    });

    // Cycles Yesterday
    model.push({
      position: { x: 550, y: 320 },
      dataValues: data.imageOverlayItems.filter((x) => x.overlayField == OverLayFields.CyclesYesterday),
    });

    // Rod strings
    model.push({ position: { x: 450, y: 550 }, dataValues: data.rodStrings });

    // Tubing
    model.push({
      position: { x: 800, y: 410 },
      dataValues: data.imageOverlayItems.filter((x) => x.overlayField == OverLayFields.TubingPressure),
    });

    // Casing
    model.push({
      position: { x: 760, y: 450 },
      dataValues: data.imageOverlayItems.filter((x) => x.overlayField == OverLayFields.CasingPressure),
    });

    // Rod Loading
    model.push({
      position: { x: 760, y: 550 },
      dataValues: data.imageOverlayItems.filter((x) => x.overlayField == OverLayFields.RodLoading),
    });

    // Pump Fillage
    model.push({
      position: { x: 760, y: 700 },
      dataValues: data.imageOverlayItems.filter((x) => x.overlayField == OverLayFields.PumpFillage),
    });

    return model;
  }

  return (
    <div className='flex flex-column flex-grow flex-shrink'>
      <div className='flex flex-row flex-grow flex-shrink' style={{ overflow: 'auto' }}>
        <div className='flex flex-column flex-grow flex-stretch'>
          <AssetImageOverlay bkgndImgUrl={RodLiftStatusBkgnd} data={getImageOverlays(wellStatusData)} />
        </div>

        <div className='flex flex-column'>
          <PropertyValueList description='[Well Name]' properties={currentStatusProperties} />
          <PropertyValueList description='Exceptions' properties={exceptionProperties} />
          <PropertyValueList description='Alarms' properties={alarmProperties} />
          <PropertyValueList description='Last Well Test' properties={wellTestProperties} />
        </div>
        <div className='flex flex-column'>
          <PropertyValueList description='Status Registers' properties={statusRegisterProperties} />
        </div>
      </div>
    </div>
  );
};

export default AssetStatus;
